import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchCompanySubscriptionSuccess,
  fetchCompanySubscriptionFailure,
  fetchMyCompanySubscriptionSuccess,
  fetchMyCompanySubscriptionFailure,
  companySubscriptionPaymentSuccess,
  companySubscriptionPaymentFailure,
} from "../actions/CompanySubscriptionAction";

import api from "../../Environment";
import {
  FETCH_COMPANY_SUBSCRIPTION_START,
  FETCH_MY_COMPANY_SUBSCRIPTION_START,
  COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";

function* getSubscriptionAPI(action) {
  try {
    const response = yield api.empPostMethod(
      "company_subscriptions_list", action.data
    );
    if (response.data.success) {
      yield put(fetchCompanySubscriptionSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchCompanySubscriptionFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchCompanySubscriptionFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getMySubscriptionAPI(action) {
  try {
    const response = yield api.empPostMethod("company_subscriptions_history",action.data);
    if (response.data.success) {
      yield put(fetchMyCompanySubscriptionSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchMyCompanySubscriptionFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchMyCompanySubscriptionFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* paymentByCardAPI(action) {
  try {
    const response = yield api.empPostMethod(
      "company_subscription_payment_by_card",
      action.data
    );
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(companySubscriptionPaymentSuccess(response.data.data));
      localStorage.setItem("is_landing_page_enabled",1);
      window.location.assign("/company-subscription-history");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(companySubscriptionPaymentFailure(response.data.error));
    }
  } catch (error) {
    yield put(companySubscriptionPaymentFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_COMPANY_SUBSCRIPTION_START, getSubscriptionAPI)]);
  yield all([
    yield takeLatest(FETCH_MY_COMPANY_SUBSCRIPTION_START, getMySubscriptionAPI),
  ]);
  yield all([
    yield takeLatest(COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_START, paymentByCardAPI),
  ]);
}
