import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { Container, Row, Col, Image, Nav, Tab, Form,Button } from "react-bootstrap";
import { connect } from "react-redux";

import {
    editUserDetails,
    fetchUserDetailsStart,
    updateUserDetailsStart,
} from "../../../store/actions/UserAction";

const UpdateProfile = (props) => {

    const [profileInputData, setProfileInputData] = useState({});

    useEffect(() => {
        props.dispatch(fetchUserDetailsStart());
    }, []);

    const [isRemoteJobChecked, setIsRemoteJobChecked] = useState(false);

    const [isProfileVisibleChecked, setIsProfileVisibleChecked] = useState(false);

    useEffect(() => {
        setIsRemoteJobChecked(props.profile.data.is_remote_job ? true : false);

        setIsProfileVisibleChecked(props.profile.data.is_profile_visible ? true : false);
        
    }, [props.profile.data]);

    const [previewImage, setPreviewImage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(updateUserDetailsStart(profileInputData));
    };

    const handleChangeImage = (event) => {
        if (event.currentTarget.type === "file") {
            setProfileInputData({
                ...profileInputData,
                [event.currentTarget.name]: event.currentTarget.files[0],
            });
            let reader = new FileReader();

            let file = event.currentTarget.files[0];
            
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    const handleChangeFile = (event) => {
        if (event.currentTarget.type === "file") {
            setProfileInputData({
                ...profileInputData,
                [event.currentTarget.name]: event.currentTarget.files[0],
            });
        }
    };

    return (
        <>
            <h3>My Profile</h3>
            <Form 
                className="profile-form-sec auth-form"
                onSubmit={handleSubmit}
                id="form"
                method="post"
                name="form"
                autoComplete="off"
            >
                <p className="separator">Personal information</p>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control 
                                placeholder="First name" 
                                type="text"
                                controlId="firstName"
                                required
                                name="first_name"
                                autoComplete="nope"
                                defaultValue={props.profile.data.first_name}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        first_name: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control 
                                placeholder="Last name" 
                                type="text"
                                controlId="lastName"
                                required
                                name="last_name"
                                autoComplete="nope"
                                defaultValue={props.profile.data.last_name}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        last_name: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>
                                LinkedIn profile
                                <span className="optional-badge">Optional</span>
                            </Form.Label>
                            <Form.Control 
                                placeholder="LinkedIn profile" 
                                type="text"
                                controlId="linkedIn"
                                required
                                name="linkedin"
                                autoComplete="nope"
                                defaultValue={props.profile.data.linkedin}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        linkedin: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>
                                Phone number
                                <span className="optional-badge">Optional</span>
                            </Form.Label>
                            <Form.Control 
                                placeholder="Phone number" 
                                type="text"
                                controlId="phoneNumber"
                                required
                                name="mobile"
                                autoComplete="nope"
                                defaultValue={props.profile.data.mobile}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        mobile: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <p className="separator">Address <span>Optional</span></p>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>
                                Address
                            </Form.Label>
                            <Form.Control 
                                placeholder="Address"
                                type="text"
                                controlId="Address"
                                required
                                name="address"
                                autoComplete="nope"
                                defaultValue={props.profile.data.address}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        address: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>
                                City
                            </Form.Label>
                            <Form.Control 
                                placeholder="City" 
                                type="text"
                                controlId="City"
                                required
                                name="city"
                                autoComplete="nope"
                                defaultValue={props.profile.data.city}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        city: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>
                                State
                            </Form.Label>
                            <Form.Control 
                                placeholder="State" 
                                type="text"
                                controlId="State"
                                required
                                name="state"
                                autoComplete="nope"
                                defaultValue={props.profile.data.state}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        state: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>
                                Zip
                            </Form.Label>
                            <Form.Control 
                                placeholder="Zip"
                                type="text"
                                controlId="Zip"
                                required
                                name="zip"
                                autoComplete="nope"
                                defaultValue={props.profile.data.zip}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        zip: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>
                                Country
                            </Form.Label>
                            <Form.Control 
                                placeholder="Country" 
                                type="text"
                                controlId="Country"
                                required
                                name="country"
                                autoComplete="nope"
                                defaultValue={props.profile.data.country}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        country: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <p className="separator">Profile</p>
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                Photo
                                <span className="optional-badge">Optional</span>
                            </Form.Label>
                        </Form.Group>
                        <div className="resume-upload-sec">
                            <div className="avatar-img-preview">
                                <Image
                                    src={previewImage ? previewImage : props.profile.data.picture}
                                    alt="Image"
                                    className="user-img"
                                />
                            </div>
                            <div className="fileUpload">
                                <input type="file" id="picture" accept="image/*" name="picture" className="upload" onChange={(event) => handleChangeImage(event)}/>
                                <span>Browse</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                Resume
                                <span className="optional-badge">Optional</span>
                            </Form.Label>
                            <div className="resume-upload-sec">
                                <div className="resume-file-sec">
                                    <a href={props.profile.data.resume} target="_blank">View resume</a>
                                </div>
                                <div className="fileUpload">
                                    <input type="file" className="upload" name="resume" id="resume" accept=".pdf" className="upload" onChange={(event) => handleChangeFile(event)}/>
                                    <span>Upload</span>
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Group>
                                <Form.Label>
                                    About
                                    <span className="optional-badge">Optional</span>
                                </Form.Label>
                                <Form.Control 
                                    placeholder="About" 
                                    type="text"
                                    controlId="About"
                                    name="about"
                                    defaultValue={props.profile.data.about}
                                    onChange={(event) =>
                                        setProfileInputData({
                                            ...profileInputData,
                                            about: event.currentTarget.value,
                                        })
                                    }
                                />
                            </Form.Group>
                        </Form.Group>

                        <Form.Group>
                            <Form.Check 
                                type="checkbox"
                                id="default-checkbox"
                                label="I am interested in remote job opportunities"
                                name="is_remote_job"
                                value="YES"
                                className="form-check-label-1"
                                checked={isRemoteJobChecked}
                                onChange={(event) => {
                                    setIsRemoteJobChecked(!isRemoteJobChecked);
                                    setProfileInputData({
                                        ...profileInputData,
                                        is_remote_job: !isRemoteJobChecked,
                                    });
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check 
                                type="checkbox"
                                id="default-checkbox-1"
                                label="Display my profile to employers"
                                name="is_profile_visible"
                                checked={isProfileVisibleChecked}
                                onChange={(event) => {
                                    setIsProfileVisibleChecked(!isProfileVisibleChecked);
                                    setProfileInputData({
                                        ...profileInputData,
                                        is_profile_visible: !isProfileVisibleChecked,
                                    })
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <p className="separator">My account</p>
                <Row>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                placeholder="Email"
                                type="text"
                                controlId="Email"
                                name="email"
                                defaultValue={props.profile.data.email}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        email: event.currentTarget.value,
                                    })
                                } 
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="text-right">
                        <Button
                            className="default-btn"
                            onClick={handleSubmit}
                            disabled={props.updateUserDetails.buttonDisable}
                            >
                            {props.updateUserDetails.loadingButtonContent 
                                ? props.updateUserDetails.loadingButtonContent
                                : "Save"}
                        </Button>
                    </Col>
                </Row>
            </Form>           
        </>
    );
};

const mapStateToPros = (state) => ({
    profile: state.users.profile,
    updateUserDetails: state.users.updateUserDetails,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(UpdateProfile));