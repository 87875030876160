import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Media,
  Form,
} from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import {
  fetchCompanyListStart,
  fetchJobCategoryListStart,
  fetchServiceLocationStart,
  fetchJobCityStart,
  fetchJobRegionStart,
} from "../../store/actions/UserJobAction";
import configuration from "react-global-configuration";

const queryString = require("query-string");

const HomeIndex = (props) => {
  useEffect(() => {
    props.dispatch(
      fetchCompanyListStart({
        loadMore: "",
        take: 3,
      })
    );
    props.dispatch(fetchJobCategoryListStart({loadMore: ""}));
    props.dispatch(fetchJobRegionStart());
    props.dispatch(fetchJobCityStart());
  }, []);
  var location = {}
  const handleRegionRedirect = (region) => {
    const query = queryString.stringify(
      {region : [region.region_unique_id]},
      {arrayFormatSeparator: "|" }
    );
    location = {
      pathname : `/jobs-listing`,
      search : query,
      state: {
        is_banner: 1,
      },
    }
    return location;
  }

  const handleCityRedirect = (city) => {
    const query = queryString.stringify(
      {city : [city.city_unique_id]},
      {arrayFormatSeparator: "|" }
    );
    location = {
      pathname : `/jobs-listing`,
      search : query,
      state: {
        is_banner: 1,
      },
    }
    return location;
  }
  const handleCategoryRedirect = (category) => {
    const query = queryString.stringify(
      {sector : [category.category_unique_id]},
      {arrayFormatSeparator: "|" }
    );
    location = {
      pathname : `/jobs-listing`,
      search : query,
      state: {
        is_banner: 1,
      },
    }
    return location;
  }
  return (
    <>
      <div className="home-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="home-banner-card">
                <div className="home-banner-cover"></div>
                <div className="home-banner-info">
                  <h3>Jobs from your favourite {configuration.get("configData.site_name")} companies.</h3>
                  <p>
                  {configuration.get("configData.site_name")} is a niche job board for the {configuration.get("configData.site_name")} and
                    Payments industry. Search, discover & apply to your next
                    {configuration.get("configData.site_name")} job on {configuration.get("configData.site_name")}.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="home-header-card-box">
                <Image
                  src={
                    window.location.origin + `/assets/img/applicant-icon.png`
                  }
                  alt="Image"
                  className="home-applicant-img"
                />
                <div className="home-header-card-info">
                  <h4>{configuration.get("configData.site_name")} Professionals</h4>
                  <p>Browse {configuration.get("configData.site_name")} jobs.</p>
                  {localStorage.getItem("userLoginStatus") ? (
                    <div className="home-header-card-btn-sec">
                      <Link to={"/jobs-listing"} className="default-btn mr-3">
                        Find a job
                      </Link>
                      <Link
                        to={"/user/profile"}
                        className="default-btn-outline"
                      >
                        View dashboard
                      </Link>
                    </div>
                  ) : (
                    <div className="home-header-card-btn-sec">
                      <Link to={"/jobs-listing"} className="default-btn mr-3">
                        Find a job
                      </Link>
                      <Link to={"/user/signup"} className="default-btn-outline">
                        Create an account
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="home-header-card-box">
                <Image
                  src={window.location.origin + `/assets/img/employer-icon.png`}
                  alt="Image"
                  className="home-applicant-img"
                />
                <div className="home-header-card-info">
                  <h4>{configuration.get("configData.site_name")} Employers</h4>
                  <p>Hire {configuration.get("configData.site_name")} talent.</p>
                  <div className="home-header-card-btn-sec">
                    {localStorage.getItem("employerLoginStatus") ? (
                      <Link to="/create-post" className="default-btn mr-3">
                        Post a job
                      </Link>
                    ) : (
                      <Link to="/employer/login" className="default-btn mr-3">
                        Post a job
                      </Link>
                    )}
                    <Link to="/employer/signup" className="default-btn-outline">
                      Create an account
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* <div className="tech-category">
            <div className="job-filter-header-sec">
              <h3 className="section-title">Nice Board Employers</h3>
            </div>
            <Row>
              {props.companies.loading
                ? "Loading"
                : props.companies.data.companies.length > 0
                ? props.companies.data.companies.slice(0, 3).map((company) => (
                    <Col md={4} key={company.company_id}>
                      <Link to={"/jobs-listing/company/"+company.company_unique_id}>
                        <div className="job-company-listing-card">
                          <div className="job-company-listing-logo-bg"></div>
                          <Image
                            src={company.picture}
                            alt={company.name}
                            className="job-company-listing-logo"
                          />
                          <div className="job-company-listing-info">
                            <h4>{company.name}</h4>
                            <p>{company.company_tagline}</p>
                          </div>
                          <div className="job-company-listing-counting">
                            <p className="company-jobs">
                              <span>{company.total_jobs} jobs</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  ))
                : ""}
            </Row>
            <Row>
              <Col md={12}>
                <div className="next-btn">
                  <Link to={"/companies"} className="default-btn-outline">
                    More Nice Board employers
                  </Link>
                </div>
              </Col>
            </Row>
          </div> */}
          <div className="tech-category">
            <div className="job-filter-header-sec">
              <h3 className="section-title">Jobs by location</h3>
            </div>
            <div className="job-by-location-box">
              <Row>
                <Col md={4}>
                  <div className="job-by-location-card">
                    <p className="separator">By Region</p>
                    <ul className="location-list list-unstyled">
                      {props.regions.loading
                        ? "Loading..."
                      : props.regions.data.regions.length > 0
                        ? props.regions.data.regions.map((region) => 
                        <Media as="li">
                          <Link to={handleRegionRedirect(region)}>{region.name}</Link>
                        </Media>
                        ): ""}
                    </ul>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="job-by-location-card">
                    <p className="separator">By City</p>
                    <ul className="location-list list-unstyled">
                      {props.cities.loading
                        ? "Loading..."
                      : props.cities.data.cities.length > 0
                        ? props.cities.data.cities.map((city) => 
                        <Media as="li">
                          <Link to={handleCityRedirect(city)}>{city.name}</Link>
                        </Media>
                      ): ""}
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="next-btn">
                    <Link to={"/locations"} className="default-btn-outline">
                      All Location
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="tech-category">
            <div className="job-filter-header-sec">
              <h3 className="section-title">{configuration.get("configData.site_name")} Sectors</h3>
            </div>

            <Row>
              {props.categories.loading
                ? "Loading"
                : props.categories.data.categories.length > 0
                ? props.categories.data.categories
                    .slice(0, 6)
                    .map((category) => (
                      <Col md={4} key={category.category_id}>
                        <Link
                          to={handleCategoryRedirect(category)}
                        >
                          <div className="job-category-listing-card">
                            <div className="job-category-listing-logo-bg"></div>
                            <div className="job-category-icon-sec"></div>
                            <div className="job-category-listing-info">
                              <h4>{category.name}</h4>
                            </div>
                            <div className="job-category-listing-counting">
                              <p className="category-jobs">
                                <span>{category.total_jobs} jobs</span>
                              </p>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    ))
                : ""}
            </Row>
            <Row>
              <Col md={12}>
                <div className="next-btn">
                  <Link to={"/sectors"} className="default-btn-outline">
                    More {configuration.get("configData.site_name")} sectors
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="tech-category">
            <div className="job-filter-header-sec">
              <h3 className="section-title">Blog Posts</h3>
            </div>
            <Row>
              <Col md={4}>
                <div className="job-blog-card">
                  <h4>Welcome, Basiq!</h4>
                  <p>
                    We are pleased to announce that Basiq is on board with us at
                    {configuration.get("configData.site_name")}! Sydney-based Basiq is the leading open banking
                    platform enabling developers to...
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="job-blog-card">
                  <h4>{configuration.get("configData.site_name")} Job Seeking in 2022: What to Consider</h4>
                  <p>
                    Now that we know 2022 is going to be the year of the
                    candidate, it’s worth considering how jobseekers within the
                    flourishing {configuration.get("configData.site_name")} job market...
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="job-blog-card">
                  <h4> {configuration.get("configData.site_name")} Jobs in 2022: A Candidate’s Market!</h4>
                  <p>
                    As 2021 ends and 2022 looms, a lot is being said about the
                    unpredictability of the times, be it the context of travel,
                    health, lifestyle...
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="next-btn">
                  <Link to="" className="default-btn-outline">
                    View the blog
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  companies: state.userjobs.companies,
  categories: state.userjobs.jobCategories,
  locations: state.userjobs.locations,
  jobs: state.userjobs.jobs,
  jobtypes: state.userjobs.jobtypes,
  regions: state.userjobs.regions,
  cities: state.userjobs.cities,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(HomeIndex));
