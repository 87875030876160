import React, { useState, useEffect, useContext } from "react";
import {Form, Button, Image, Modal} from "react-bootstrap";
import {
    fetchJobCityStart,
    userJobAlertSaveStart,
} from "../../store/actions/UserJobAction";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";

const EmailAlertModal = (props) => {

    const [inputData, setInputData] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(userJobAlertSaveStart(inputData));
        props.closeEmailAlertModal();
    };

    return (
        <>
        <Modal
            className="modal-dialog-center email-alert-modal"
            size="md"
            centered
            show={props.emailAlert}
            onHide={props.closeEmailAlertModal}
        >
            <Form>
                <Modal.Header>
                    <Modal.Title>Never miss another job opportunity at JobClick</Modal.Title>
                    <div className="close-model-icon" onClick={() =>props.closeEmailAlertModal()}>
                        <i className="fas fa-window-close"></i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <h6>Get notified by email as soon as new jobs are posted for:</h6>
                    <Form 
                        onSubmit={handleSubmit}
                        id="form"
                        method="post"
                        name="form"
                        autoComplete="off"
                    >
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control 
                                type="text" 
                                placeholder="Job Title" 
                                name="title"
                                onChange={(event) =>
                                    setInputData({
                                        ...inputData,
                                        title: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                        {props.categories.loading
                            ? "Loading..."
                        : props.categories.data.categories.length > 0
                            ? 
                            <Form.Group className="mb-3">
                                <select
                                    className="form-select form-control"
                                    onChange={(event) =>
                                        setInputData({
                                            ...inputData,
                                            category_id: event.currentTarget.value,
                                        })
                                    }
                                    name="category_id"
                                >
                                    <option selected>Select Sector</option>
                                    {props.categories.data.categories.map((category) => 
                                        <option 
                                            key={category.category_id}
                                            value={category.category_id}
                                        >
                                            {category.name}
                                        </option>
                                    )}
                                </select>
                            </Form.Group>
                        : ""}
                        {props.regions.loading
                            ? "Loading..."
                        : props.regions.data.regions.length > 0
                            ? 
                            <Form.Group className="mb-3">
                                <select
                                    className="form-select form-control"
                                    onChange={(event) =>
                                        {
                                            setInputData({
                                                ...inputData,
                                                region_id: event.currentTarget.value,
                                            })
                                            props.dispatch(fetchJobCityStart({region_unique_id:event.currentTarget.value}));
                                        }
                                    }
                                    name="region_id"
                                >
                                    <option selected>Select Region</option>
                                    {props.regions.data.regions.map((region) => 
                                        <option 
                                            key={region.region_id}
                                            value={region.region_unique_id}
                                        >
                                            {region.name}
                                        </option>
                                    )}
                                </select>
                            </Form.Group>
                        : ""}
                        {props.cities.loading
                        ? 
                            <Form.Group className="mb-2">
                                <select
                                    className="form-select form-control"
                                    aria-label="Default select example"
                                    name="city_id"
                                >   
                                    <option value="" selected>Select City</option>
                                </select>
                            </Form.Group>
                        : 
                        <Form.Group className="mb-3">
                            <select
                                className="form-select form-control"
                                name="city_id"
                                onChange={(event) =>
                                    {
                                        setInputData({
                                            ...inputData,
                                            city_id: event.currentTarget.value,
                                        })
                                    }
                                }
                            >
                                <option selected>Select City</option>
                                {props.cities.data.cities.length > 0
                                ? props.cities.data.cities.map((city) => 
                                    <option 
                                        key={city.city_id}
                                        value={city.city_id}
                                    >
                                        {city.name}
                                    </option>
                                ) :''}
                            </select>
                        </Form.Group>
                        }
                        <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
                            <Form.Control 
                                type="email" 
                                placeholder="Email Address"
                                name="email"
                                onChange={(event) =>
                                    setInputData({
                                        ...inputData,
                                        email: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn btn-danger"
                        onClick={props.closeEmailAlertModal}
                    >
                        No Thanks!
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-success"
                        onClick={(event) => handleSubmit(event)}
                    >
                        Create Job Alert
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
        </>
    );
};

const mapStateToPros = (state) => ({
    cities: state.userjobs.cities,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(EmailAlertModal));
