import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media, Form } from "react-bootstrap";


const StaticPage = (props) => {
  return (
    <>
      <div className="static-page-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="home-banner-info">
                <h3>Static Page</h3>
                <p>Privacy Policy</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="static-page-card">
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>User's Acknowledgment and Acceptance of Terms</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>
                      Niceboard ("Us" or "We") provides the Niceboard site and various related services (collectively, the "site") to you, the user, subject to your compliance with all the terms, conditions, and notices contained or referenced herein (the "Terms of Use"), as well as any other written agreement between us and you. In addition, when using particular services or materials on this site, users shall be subject to any posted rules applicable to such services or materials that may contain terms and conditions in addition to those in these Terms of Use. All such guidelines or rules are hereby incorporated by reference into these Terms of Use.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>Description of Services</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>
                      We make various services available on this site including, but not limited to providing employment ads, and other like services. You are responsible for providing, at your own expense, all equipment necessary to use the services, including a computer, modem, and Internet access (including payment of all fees associated with such access).
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>Conduct on Site</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>You are expressly and emphatically restricted from all of the following:
                        <ul className="list-unstyled">
                          <li><i className="fas fa-angle-double-right mr-2"></i>victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;</li>
                          <li><i className="fas fa-angle-double-right mr-2"></i>infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party;</li>
                          <li><i className="fas fa-angle-double-right mr-2"></i> constitutes unauthorized or unsolicited advertising, junk or bulk email (also known as "spamming"), chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling;</li>
                          <li><i className="fas fa-angle-double-right mr-2"></i>contains software viruses or any other computer code, files, or programs that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of any third party; or</li>
                          <li><i className="fas fa-angle-double-right mr-2"></i>impersonates any person or entity, including any of our employees or representatives.</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>Third Party Sites and Information</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>This site may link you to other sites on the Internet or otherwise include references to information, documents, software, materials and/or services provided by other parties. These sites may contain information or material that some people may find inappropriate or offensive. These other sites and parties are not under our control, and you acknowledge that we are not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such sites, nor are we responsible for errors or omissions in any references to other parties or their products and services. The inclusion of such a link or reference is provided merely as a convenience and does not imply endorsement of, or association with, the site or party by us, or any warranty of any kind, either express or implied.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>No warranties</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>
                        This website is provided “as is,” with all faults, andCodegama makes no express or implied representations or warranties, of any kind related to this website or the materials contained on this website. Additionally, nothing contained on this website shall be construed as providing consult or advice to you.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>Unauthorized Use of Materials</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>
                      Please do not submit confidential or proprietary information to us unless we have mutually agreed in writing otherwise. We are also unable to accept your unsolicited ideas or proposals, so please do not submit them to us in any circumstance.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>Limitation of Liability</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>
                      FURTHER, WE SHALL NOT BE LIABLE IN ANY WAY FOR THIRD PARTY GOODS AND SERVICES OFFERED THROUGH THIS SITE OR FOR ASSISTANCE IN CONDUCTING COMMERCIAL TRANSACTIONS THROUGH THIS SITE, INCLUDING WITHOUT LIMITATION THE PROCESSING OF ORDERS.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>Indemnification</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>
                      Upon a request by us, you agree to defend, indemnify, and hold us and our Affiliates harmless from all liabilities, claims, and expenses, including attorney’s fees, that arise from your use or misuse of this site. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>Security and Password</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>
                      You are solely responsible for maintaining the confidentiality of your password and account and for any and all statements made and acts or omissions that occur through the use of your password and account. Therefore, you must take steps to ensure that others do not gain access to your password and account. Our personnel will never ask you for your password. You may not transfer or share your account with anyone, and we reserve the right to immediately terminate your account if you do transfer or share your account.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>Participation in Promotions</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>
                      From time to time, this site may include advertisements offered by third parties. You may enter into correspondence with or participate in promotions of the advertisers showing their products on this site. Any such correspondence or promotions, including the delivery of and the payment for goods and services, and any other terms, conditions, warranties or representations associated with such correspondence or promotions, are solely between you and the advertiser. We assume no liability, obligation or responsibility for any part of any such correspondence or promotion.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>E-mail, Messaging, Blogging, and Chat Services</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>
                      We may make email, messaging, blogging, or chat services (collectively, "Communications") available to users of our site, either directly or through a third-party provider. We make available separate supplemental agreements characterizing the relationship between you and us that, except where expressly noted or contradictory, includes these Terms.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <div className="resp-align-center">
                      <b>International Use</b>
                    </div>
                    <ul className="list-unstyled">
                      <li><i className="fas fa-angle-double-right mr-2"></i>
                      Although this site may be accessible worldwide, we make no representation that materials on this site are appropriate or available for use in locations outside the United States, and accessing them from territories where their contents are illegal is prohibited. Those who choose to access this site from other locations do so on their own initiative and are responsible for compliance with local laws. Any offer for any product, service, and/or information made in connection with this site is void where prohibited.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default (StaticPage);
