import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Media,
  Form,
} from "react-bootstrap";
import FilterIndex from "./FilterIndex";
import JobDisplayCard from "../helper/JobDisplayCard";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import InfiniteScroll from "../helper/InfiniteScroll";
import {
  fetchJobCategoryListStart,
  fetchServiceLocationStart,
  fetchJobsStart,
  fetchJobTypeListStart,
  loadMoreJobsStart,
  fetchSalaryFilterStart,
  fetchJobRegionStart,
  fetchJobCityStart,
} from "../../store/actions/UserJobAction";
import EmailAlertModal from "../Modals/EmailAlertModal";

const queryString = require("query-string");

const JobsListingIndex = (props) => {
  const [searchData, setSearchData] = useState({});
  const [isBanner, setIsBanner] = useState(0);

  const parsedValues = queryString.parse(props.location.search ,  {arrayFormatSeparator: "|" });
  
  useEffect(() => {
    props.dispatch(fetchSalaryFilterStart());
    let inputData;
    if (props.location.state && props.location.state.is_banner) {
      setIsBanner(props.location.state.is_banner);
      inputData = {...inputData , is_banner : props.location.state.is_banner}
    }

    var regionId,cityId,sectorId,jobType = "";
    if(Object.keys(parsedValues).length > 0){
      if(parsedValues.region){
        regionId = parsedValues.region;
        inputData = {...inputData , region : parsedValues.region}
      }
      if(parsedValues.city){
        cityId = parsedValues.city;
        inputData = {...inputData , city : parsedValues.city}
      }
      if(parsedValues.sector){
        sectorId = parsedValues.sector;
        inputData = {...inputData , sector : parsedValues.sector}
      }
      if(parsedValues.job_type){
        jobType = parsedValues.job_type;
        inputData = {...inputData , job_type : parsedValues.job_type}
      }
      if(parsedValues.company){
        inputData = {...inputData , company : parsedValues.company}
      }
      setSearchData(inputData);
    }
    
    props.dispatch(fetchJobRegionStart({region_unique_id:regionId ? regionId : '' }));
    props.dispatch(fetchJobCategoryListStart({loadMore: "",sector_unique_id:sectorId ? sectorId : ''}));
    props.dispatch(fetchJobTypeListStart({job_type_unique_id:jobType ? jobType : ''}));
    props.dispatch(fetchJobCityStart(
      {
        region_unique_id:regionId ? regionId : '',
        city_unique_id:cityId ? cityId : ''
      }
    ));
  }, []);

  const [emailAlert, setEmailAlert] = useState(false);

  const closeEmailAlertModal = () => {
      console.log("close")
    setEmailAlert(false);
  };

  const getSearchData = (event) => {
    event.preventDefault();
    if(event.target.name == 'region'){
      setSearchData({
        ...searchData,
        [event.target.name]: [event.target.value],
        'city': '',
      });
    } else {
      setSearchData({
        ...searchData,
        [event.target.name]: [event.target.value],
      });
    }

    let queryData =  {...parsedValues};

    if(event.target.name){
      queryData = {...queryData , [event.target.name] : event.target.value}
    }

    if(event.target.name == 'region'){
      queryData = {...queryData , 'city' : ''}
    }

    const query = queryString.stringify(
      queryData,
      {arrayFormatSeparator: '|'}
    );

    props.history.replace({
      pathname: props.location.pathname,
      search: query,
    });
    
  };

  useEffect(() => {
    props.dispatch(
      fetchJobsStart(searchData)
    );
  }, [searchData]);

  const getSearchPriceRange = (min,max) => {
    setSearchData({
      ...searchData,
      'from_salary':min,
      'to_salary':max,
    });

    props.dispatch(
      fetchJobsStart({
        'from_salary':min,
        'to_salary':max
      })
    );
  };

  return (
    <>
      <div className="category-listing-single-sec">
        {isBanner && props.jobs.loading ? (
          " "
        ) : props.jobs.data.banner_section ? 
        <div className="companies-banner-all-sec">
            <div className="companies-banner-all-bg-sec">
              <Image
                  src={props.jobs.data.banner_section.picture}
                  alt="Image"
                  className="companies-banner-all-bg"
              />
              <div className="companies-banner-all-content">
                <h4>{props.jobs.data.banner_section.name}</h4>
                <p>{props.jobs.data.banner_section.description}</p>
              </div>
            </div>
        </div>
        : ""}
        <Container>
          {/* <Row>
                <Col md={12}>
                    <div className="home-banner-info">
                        <Image
                            src={window.location.origin + `/assets/img/company-logo.png`}
                            alt="Image"
                            className="company-single-logo"
                        />
                        <h3>Payment Networks & Schemes jobs</h3>
                        <p>Browse all Payment Networks & Schemes jobs.</p>
                        <div className="company-link-sec">
                            <Link to="#" className="company-link">
                                Website
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row> */}
          <Row>
            <Col md={9}>
              <div className="job-filter-header-sec mt-3">
                <h3 className="section-title">
                  JobClick Jobs
                  <span className="count">
                    {props.jobs.loading ? "" : props.jobs.data.jobs_list.length}
                  </span>{" "}
                </h3>
              </div>
            </Col>
            <Col md={3}>
              <div className="job-filter-header-sec mt-3">
                <div className="live-job-create-alert-btn">
                  <Link to="#" className="apply-btn" onClick={() => setEmailAlert(true)}>CREATE AN EMAIL ALERT</Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FilterIndex
                categories={props.categories}
                regions={props.regions}
                jobtypes={props.jobtypes}
                getSearchData={getSearchData}
                getSearchPriceRange={getSearchPriceRange}
                salaryFilter={props.salaryFilter}
              />
            </Col>
            <Col md={9}>
              <div className="job-list-sec">
                <ul className="list-unstyled" style={{ positon: "relative" }}>
                  {props.jobs.loading ? (
                    "Loading"
                  ) : props.jobs.data.jobs_list.length > 0 ? (
                    <>
                      <InfiniteScroll
                        fetchAction={loadMoreJobsStart}
                        fetchData={props.jobs}
                        fetchedData={props.jobs.data.jobs_list}
                        skip={props.jobs.data.jobs_list.length}
                        take={12}
                        skippedFetch={true}
                        total={props.jobs.data.total}
                        dispatchData={searchData}
                      >
                        {props.jobs.data.jobs_list.map((job) => (
                          <JobDisplayCard job={job} key={job.company_job_id} />
                        ))}
                      </InfiniteScroll>
                    </>
                  ) : (
                    "No Data found"
                  )}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <EmailAlertModal 
          emailAlert={emailAlert} 
          categories={props.categories}
          regions={props.regions}
          closeEmailAlertModal={closeEmailAlertModal} 
        />
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  companies: state.userjobs.companies,
  categories: state.userjobs.jobCategories,
  // locations: state.userjobs.locations,
  jobs: state.userjobs.jobs,
  jobtypes: state.userjobs.jobtypes,
  salaryFilter: state.userjobs.salaryFilter,
  regions: state.userjobs.regions,
  cities: state.userjobs.cities,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(JobsListingIndex));
