import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media, Form} from "react-bootstrap";

const CategoryListingSingleIndex = (props) => {
  return (
    <>
      <div className="category-listing-single-sec">
        <Container>
           <Row>
              <Col md={12}>
                <div className="home-banner-info">
                    <h3>Payment Networks & Schemes jobs</h3>
                    <p>Browse all Payment Networks & Schemes jobs.</p>
                </div>
              </Col>
           </Row>
           <Row className="mt-5">
               <Col md={12}>
                   <div className="job-filter-header-sec">
                       <h3 className="section-title">
                           Fintech Jobs
                           <span className="count">1303</span> </h3>
                       <p className="pagination-info">Page 1 of 66</p>
                   </div>
               </Col>
           </Row>
           <Row>
                <Col md={3}>
                    <div className="sticky-box">
                        <Form>
                            <div className="filter-search-sec">
                                <h5>Quick Search</h5>
                                <Form.Group className="mb-0">
                                    <Form.Control type="email" placeholder="Company or job title" />
                                </Form.Group>
                            </div>
                            <div className="filter-search-sec">
                                <h5>Location</h5>
                                <Form.Group className="mb-0 location-grid-view">
                                    <select className="form-select form-control" aria-label="Default select example">
                                        <option selected>All locations</option>
                                        <option value="1">USA</option>
                                        <option value="2">UK</option>
                                        <option value="3">UAE</option>
                                    </select>
                                    <select className="form-select form-control" aria-label="Default select example">
                                        <option selected>All State</option>
                                        <option value="1">USA</option>
                                        <option value="2">UK</option>
                                        <option value="3">UAE</option>
                                    </select>
                                    <select className="form-select form-control" aria-label="Default select example">
                                        <option selected>All Countries</option>
                                        <option value="1">USA</option>
                                        <option value="2">UK</option>
                                        <option value="3">UAE</option>
                                    </select>
                                </Form.Group>
                                <Form.Group className="mb-0">
                                    <Form.Check 
                                        type="switch"
                                        id="custom-switch"
                                        label="Remote"
                                    />
                                </Form.Group>
                            </div>
                            <div className="filter-search-sec">
                                <h5>Niceboard Category</h5>
                                <Form.Group className="mb-0">
                                    <select className="form-select form-control" aria-label="Default select example">
                                        <option selected>All categories</option>
                                        <option value="1">USA</option>
                                        <option value="2">UK</option>
                                        <option value="3">UAE</option>
                                    </select>
                                </Form.Group>
                            </div>
                            <div className="filter-search-sec">
                                <h5>Job Function</h5>
                                <Form.Group className="mb-0">
                                    <select className="form-select form-control" aria-label="Default select example">
                                        <option selected>All function</option>
                                        <option value="1">USA</option>
                                        <option value="2">UK</option>
                                        <option value="3">UAE</option>
                                    </select>
                                </Form.Group>
                            </div>
                            <div className="filter-search-sec">
                                <h5>Job Type</h5>
                                <Form.Group className="mb-0">
                                    <select className="form-select form-control" aria-label="Default select example">
                                        <option selected>All jobs</option>
                                        <option value="1">USA</option>
                                        <option value="2">UK</option>
                                        <option value="3">UAE</option>
                                    </select>
                                </Form.Group>
                            </div>
                        </Form>
                    </div>
                </Col>
                <Col md={9}>
                    <div className="job-list-sec">
                        <ul className="list-unstyled">
                            <Media as="li">
                                <Link to="#" target="_blank">
                                    <div className="job-list-card">
                                        <div className="job-list-content">
                                            <Image
                                                src={window.location.origin + `/assets/img/company-logo.png`}
                                                alt="Image"
                                                className="job-list-logo"
                                            />
                                            <div className="job-list-info">
                                                <h4 className="position">Product Designer</h4>
                                                <h6 className="company">Kraken</h6>
                                                <div className="meta">
                                                    <p className="location">
                                                        <span className="city">South Korea</span>
                                                        <span className="remote">Remote</span>
                                                    </p>
                                                    <p className="type">
                                                        Full-time
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="job-list-action-sec">
                                            <p className="time-ago">1d</p>
                                            <Button className="save-btn">
                                            </Button>
                                        </div>
                                    </div>
                                </Link>
                            </Media>
                            <Media as="li">
                                <Link to="#" target="_blank">
                                    <div className="job-list-card">
                                        <div className="job-list-content">
                                            <Image
                                                src={window.location.origin + `/assets/img/company-logo-1.png`}
                                                alt="Image"
                                                className="job-list-logo"
                                            />
                                            <div className="job-list-info">
                                                <h4 className="position">Senior Product Designer</h4>
                                                <h6 className="company">Coinbase</h6>
                                                <div className="meta">
                                                    <p className="location">
                                                        <span className="city">Tokyo Prefecture, Japan</span>
                                                    </p>
                                                    <p className="type">
                                                        Full-time
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="job-list-action-sec">
                                            <p className="time-ago">1d</p>
                                            <Button className="save-btn">
                                            </Button>
                                        </div>
                                    </div>
                                </Link>
                            </Media>
                            <Media as="li">
                                <Link to="#" target="_blank">
                                    <div className="job-list-card">
                                        <div className="job-list-content">
                                            <Image
                                                src={window.location.origin + `/assets/img/company-logo-2.png`}
                                                alt="Image"
                                                className="job-list-logo"
                                            />
                                            <div className="job-list-info">
                                                <h4 className="position">Outbound Sales Manager, SMB</h4>
                                                <h6 className="company">Afterpay</h6>
                                                <div className="meta">
                                                    <p className="location">
                                                        <span className="city">Sydney, New South Wales, Australia</span>
                                                        <span className="remote">Remote</span>
                                                    </p>
                                                    <p className="type">
                                                        Full-time
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="job-list-action-sec">
                                            <p className="time-ago">1d</p>
                                            <Button className="save-btn">
                                            </Button>
                                        </div>
                                    </div>
                                </Link>
                            </Media>
                            <Media as="li">
                                <Link to="#" target="_blank">
                                    <div className="job-list-card">
                                        <div className="job-list-content">
                                            <Image
                                                src={window.location.origin + `/assets/img/company-logo-3.png`}
                                                alt="Image"
                                                className="job-list-logo"
                                            />
                                            <div className="job-list-info">
                                                <h4 className="position">Customer Success Manager</h4>
                                                <h6 className="company">Mambu</h6>
                                                <div className="meta">
                                                    <p className="location">
                                                        <span className="city">Singapore</span>
                                                    </p>
                                                    <p className="type">
                                                        Full-time
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="job-list-action-sec">
                                            <p className="time-ago">1d</p>
                                            <Button className="save-btn">
                                            </Button>
                                        </div>
                                    </div>
                                </Link>
                            </Media>
                        </ul>
                        <div className="category-single-listing-btn-sec">
                            <Link to="#" className="default-btn-outline">
                                Next page
                            </Link>
                        </div>
                    </div>
                </Col>
           </Row>
        </Container>
      </div>
    </>
  );
};

export default (CategoryListingSingleIndex);
