import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
const Sidebar = (props) => {
  return <>Mobile Header</>;
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Sidebar);
