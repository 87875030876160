import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media, Form} from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import {
    saveWishlistStart,
    userJobApplyStart,
} from "../../store/actions/UserJobAction";
import { createNotification } from "react-redux-notify";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";

const JobDisplayCard = (props) => {
    const { job } = props;

    const [likeStatus, setLikeStatus] = useState("");

    const handleWishlist = (event, status) => {
        event.preventDefault();
        if(localStorage.getItem("userLoginStatus")) {
            setLikeStatus(status);
            props.dispatch(saveWishlistStart({ company_job_id: job.company_job_id }));
        } else {
            const notificationMessage = getErrorNotificationMessage('Login to continue');
            props.dispatch(createNotification(notificationMessage));
        }
    };

    const handleApply = (event, company_job_id) => {
        event.preventDefault();
        if(localStorage.getItem("userLoginStatus")) {
            props.dispatch(userJobApplyStart({ company_job_id: company_job_id }));
        } else {
            const notificationMessage = getErrorNotificationMessage('Login to continue');
            props.dispatch(createNotification(notificationMessage));
        }
    };

    return (
        <Media as="li">
            <Link to={"/job/" + job.company_job_unique_id} target="_blank">
                <div className="job-list-card">
                    <div className="job-list-content">
                        <Image
                            src={job.company.company_logo}
                            alt="Image"
                            className="job-list-logo"
                        />
                        <div className="job-list-info">
                            <h4 className="position">{job.name}</h4>
                            <h6 className="company">{job.company.company_name}</h6>
                            <div className="meta">
                                <p className="location">
                                    <span className="city">{job.city_name},{job.region_name}</span>
                                    {job.job_type ?
                                        <span className="remote">{job.job_type.name}</span>
                                    : ''}
                                </p>
                                <p className="type">Full-time</p>
                            </div>
                        </div>
                    </div>
                    <div className="job-list-action-sec">
                        <p className="time-ago">{job.created}</p>
                        <div className="apply-option-box">
                            <div>
                                {likeStatus !== "" ? (
                                    <>
                                        {likeStatus === "added" ? (
                                            <Button className="save-btn active" onClick={(event) => handleWishlist(event,'removed')}></Button>
                                        ) : null}
                                        {likeStatus === "removed" ? (
                                            <Button className="save-btn" onClick={(event) => handleWishlist(event,'added')}></Button>
                                        ) : null}
                                    </>
                                ) : job.is_user_liked == 1 ? (
                                    <Button className="save-btn active" onClick={(event) => handleWishlist(event,'removed')}></Button>
                                ) : (
                                    <Button className="save-btn" onClick={(event) => handleWishlist(event,'added')}></Button>
                                )}
                            </div>
                            <div className="apply-option-btn-sec">
                                <Link className="apply-btn" to="#" onClick={(event) => handleApply(event,job.company_job_id)}>Apply</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </Media>      
    );
};
const mapStateToPros = (state) => ({
   
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(JobDisplayCard));
