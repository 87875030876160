import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import {
    userLoginStart,
} from "../../../store/actions/UserAction";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";

const LoginIndex = (props) => {

    const [loginInputData, setLoginInputData] = useState({});

    const handleLogin = (event) => {
        event.preventDefault();
        props.dispatch(userLoginStart(loginInputData));
    };

    return (
    <>
      <div className="login-sec">
        <Container>
            <Row className="justify-content-md-center">
                <Col md={10} xl={6} lg={6}>
                    <div className="login-card">
                        <div className="login-box">
                            <h4>Job Seeker Log in</h4>
                            <Form
                                className="auth-form"
                                onSubmit={handleLogin}
                                method="post"
                                autoComplete="off"
                            >
                                <Form.Group className="mb-3" controlId="loginemail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        controlId="loginemail"
                                        placeholder="E-mail"
                                        required
                                        value={loginInputData.email}
                                        name="email"
                                        autoComplete="nope"
                                        onChange={(event) =>
                                          setLoginInputData({
                                            ...loginInputData,
                                            email: event.currentTarget.value,
                                          })
                                        }
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control 
                                        type="password"
                                        controlId="loginpassword"
                                        placeholder="Password"
                                        required
                                        autocomplete="off"
                                        value={loginInputData.password}
                                        name="password"
                                        onChange={(event) =>
                                          setLoginInputData({
                                            ...loginInputData,
                                            password: event.currentTarget.value,
                                          })
                                        } 
                                    />
                                </Form.Group>
                                <p>Forgot password? <Link to="/forgot-password">Reset it here</Link></p>
                                <div className="btn-sec"> 
                                    <Button
                                        id="login"
                                        type="submit"
                                        onClick={handleLogin}
                                        className="default-btn btn-block"
                                        disabled={props.login.buttonDisable}
                                        >
                                        {props.login.loadingButtonContent !== null
                                            ? props.login.loadingButtonContent
                                            : "Login"}
                                    </Button>
                                </div>
                                {/* <div className="auth-footer-sec">
                                    <p>Or <Link to="#">sign in as a team member</Link></p>
                                </div> */}
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  );
};


const mapStateToPros = (state) => ({
    login: state.users.loginInputData,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(LoginIndex));
