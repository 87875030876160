import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import { fetchCompanySubscriptionStart } from "../../store/actions/CompanySubscriptionAction";
import useInfiniteScroll from "../helper/useInfiniteScroll";
import SubscriptionDataCard from "./SubscriptionDataCard";

const SubscriptionList = (props) => {
    useEffect(() => {
        props.dispatch(fetchCompanySubscriptionStart());
    }, []);

    const fetchSubscriptionData = () => {
        setTimeout(() => {
          if (props.subscriptions.length !== 0) {
            props.dispatch(fetchCompanySubscriptionStart({
                skip:props.subscriptions.skip,
            }));
            setIsFetching(false);
          } else {
            setNoMoreData(true);
          }
        }, 3000);
    };

    const [isFetching, setIsFetching] = useInfiniteScroll(fetchSubscriptionData);

    const [noMoreData, setNoMoreData] = useState(false);

    const { subscriptions } = props;

    return (
        <>
            <div className="home-sec">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="home-banner-card">
                                <div className="home-banner-info">
                                    <h3>Subscription Lists</h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="margin-top-lg">
                        <Col md={12}>
                            <div className="select-plan-sec-single">
                                <div className="select-plan-box">
                                    {subscriptions.loading ? (
                                        "Loading"
                                    ) : subscriptions.data.subscriptions.length > 0 ? (
                                        <>
                                            {subscriptions.data.subscriptions.map((subscription) => (
                                                <SubscriptionDataCard subscription={subscription} key={subscription.company_subscription_id}/>
                                            ))}
                                        </>
                                    ) : null}
                                    
                                </div>
                                {noMoreData !== true ? (
                                    <>{isFetching && "Fetching more items..."}</>
                                ) : (
                                    "No More Data"
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    subscriptions: state.companySubscription.subscription,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(mapStateToPros, mapDispatchToProps)(SubscriptionList);
  