import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchEmployerDetailsSuccess,
  fetchEmployerDetailsFailure,
  employerLoginSuccess,
  employerLoginFailure,
  updateEmployerDetailsSuccess,
  updateEmployerDetailsFailure,
  employerRegisterSuccess,
  employerRegisterFailure,
  employerForgotPasswordSuccess,
  employerForgotPasswordFailure,
  employerDeleteAccountSuccess,
  employerDeleteAccountFailure,
  employerResetPasswordFailure,
  employerResetPasswordSuccess,
  employerChangePasswordSuccess,
  employerChangePasswordFailure,
} from "../actions/EmployerAction";
import api from "../../Environment";

import {
  FETCH_EMPLOYER_DETAILS_START,
  EMPLOYER_LOGIN_START,
  UPDATE_EMPLOYER_DETAILS_START,
  EMPLOYER_FORGOT_PASSWORD_START,
  EMPLOYER_DELETE_ACCOUNT_START,
  EMPLOYER_RESET_PASSWORD_START,
  EMPLOYER_CHANGE_PASSWORD_START,
  EMPLOYER_REGISTER_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";

function* getEmployerDetailsAPI() {
  try {
    const response = yield api.empPostMethod("profile");

    if (response.data.success) {
      yield put(fetchEmployerDetailsSuccess(response.data));
      localStorage.setItem("employerLoginStatus", true);
      localStorage.setItem("employer_picture", response.data.data.company_logo);
      localStorage.setItem("employerName", response.data.data.name);
      localStorage.setItem("employer_unique_id", response.data.data.company_unique_id);
      localStorage.setItem("employerId", response.data.data.company_id);
      localStorage.setItem("employerAccessToken", response.data.data.token);
      localStorage.setItem("employerAccountType", response.data.data.account_type);
      localStorage.setItem("is_landing_page_enabled", response.data.data.is_landing_page_enabled);
    } else {
      yield put(fetchEmployerDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchEmployerDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* employerLoginAPI(action) {
  try {

    const response = yield api.empPostMethod("login", action.data);
    yield put(employerLoginSuccess(response.data));
    if (response.data.success) {
        localStorage.setItem("employerLoginStatus", true);
        localStorage.setItem("employer_picture", response.data.data.company_logo);
        localStorage.setItem("employerName", response.data.data.name);
        localStorage.setItem("employer_unique_id", response.data.data.company_unique_id);
        localStorage.setItem("employerId", response.data.data.company_id);
        localStorage.setItem("employerAccessToken", response.data.data.token);
        localStorage.setItem("employerAccountType", response.data.data.account_type);
        localStorage.setItem("is_landing_page_enabled", response.data.data.is_landing_page_enabled);
        localStorage.removeItem("userLoginStatus");
        localStorage.removeItem("user_picture");
        localStorage.removeItem("name");
        localStorage.removeItem("user_unique_id");
        localStorage.removeItem("userId");
        localStorage.removeItem("accessToken");
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/employer/profile");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(employerLoginFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* updateEmployerDetailsAPI(action) {
  try {
    const response = yield api.empPostMethod("update_profile" , action.data);

    if (response.data.success) {
      yield put(updateEmployerDetailsSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(updateEmployerDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(updateEmployerDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* employerRegisterAPI() {
  try {
    const employerData = yield select(
      (state) => state.employers.registerInputData.data
    );
    const response = yield api.empPostMethod("register", employerData);
    yield put(employerRegisterSuccess(response.data));

    if (response.data.success) {
      localStorage.setItem("employerId", response.data.data.company_id);
      localStorage.setItem("employerAccessToken", response.data.data.token);
      localStorage.setItem("employerLoginStatus", true);
      localStorage.setItem("employer_picture", response.data.data.company_logo);
      localStorage.setItem("employername", response.data.data.name);
      localStorage.setItem(
        "employer_unique_id",
        response.data.data.company_unique_id
      );
      localStorage.setItem("is_landing_page_enabled", response.data.data.is_landing_page_enabled);
      localStorage.setItem("employerAccountType", response.data.data.account_type);
      localStorage.removeItem("userLoginStatus");
      localStorage.removeItem("user_picture");
      localStorage.removeItem("name");
      localStorage.removeItem("user_unique_id");
      localStorage.removeItem("userId");
      localStorage.removeItem("accessToken");
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/employer/profile");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(employerRegisterFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* resetPasswordAPI() {
  try {
    const inputData = yield select(
      (state) => state.employers.resetPasswordInputData.inputData
    );
    const response = yield api.empPostMethod("reset_password", inputData);
    yield put(employerResetPasswordSuccess(response.data));
    if (response.data.success) {
      localStorage.setItem("employerLoginStatus", true);
      localStorage.setItem("employer_picture", response.data.data.company_logo);
      localStorage.setItem("employerName", response.data.data.name);
      localStorage.setItem("employer_unique_id", response.data.data.company_unique_id);
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      localStorage.setItem("employerId", response.data.data.company_id);
      localStorage.setItem("employerAccessToken", response.data.data.token);
      localStorage.setItem("employerAccountType", response.data.data.account_type);
      yield put(createNotification(notificationMessage));
      window.location.assign("/employer/profile");

    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(employerResetPasswordFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* changePasswordAPI() {
  try {
    const inputData = yield select(
      (state) => state.employers.changePasswordInputData.data
    );
    const response = yield api.empPostMethod("change_password", inputData);
    yield put(employerChangePasswordSuccess(response.data.data));
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(employerChangePasswordFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* forgotPasswordAPI() {
  try {
    const employerData = yield select(
      (state) => state.employers.forgotPasswordInputData.data
    );

    if (
      !employerData.email ||
      employerData.email == undefined ||
      employerData.email == null
    ) {
      const notificationMessage = getErrorNotificationMessage(
        "Please enter the email address"
      );
      yield put(createNotification(notificationMessage));
      yield put(employerForgotPasswordFailure());
    } else {
      const response = yield api.empPostMethod("forgot_password", employerData);
      yield put(employerForgotPasswordSuccess(response.data));
      if (response.data.success) {
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/");
      } else {
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(createNotification(notificationMessage));
      }
    }
  } catch (error) {
    yield put(employerForgotPasswordFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* deleteAccountAPI() {
  try {
    const employerData = yield select(
      (state) => state.employers.deleteAccount.inputData
    );
    const response = yield api.empPostMethod("delete_account", employerData);
    yield put(employerDeleteAccountSuccess(response.data));
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      localStorage.removeItem("employerLoginStatus");
      localStorage.removeItem("employer_picture");
      localStorage.removeItem("employerName");
      localStorage.removeItem("employerId");
      localStorage.removeItem("employerAccessToken");
      localStorage.removeItem("employer_unique_id");
      localStorage.removeItem("employerAccountType");
      window.location.assign("/");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(employerDeleteAccountFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}


export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_EMPLOYER_DETAILS_START, getEmployerDetailsAPI),
    yield takeLatest(EMPLOYER_LOGIN_START, employerLoginAPI),
    yield takeLatest(UPDATE_EMPLOYER_DETAILS_START, updateEmployerDetailsAPI),
    yield takeLatest(EMPLOYER_CHANGE_PASSWORD_START, changePasswordAPI),
    yield takeLatest(EMPLOYER_RESET_PASSWORD_START, resetPasswordAPI),
    yield takeLatest(EMPLOYER_FORGOT_PASSWORD_START, forgotPasswordAPI),
    yield takeLatest(EMPLOYER_DELETE_ACCOUNT_START, deleteAccountAPI),
    yield takeLatest(EMPLOYER_REGISTER_START, employerRegisterAPI),
  ]);
}
