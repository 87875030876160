import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Media } from "react-bootstrap";

const HomeFooter = () => {
  return (
    <>
      <footer className="footer-sec">
        <Container>
          <Row>
            <Col md={3}>
              <ul className="footer-widget list-unstyled">
                <Media as="li" className="footer-title">
                  <h4>JobClick</h4>
                </Media>
                <Media as="li">
                  <Link to="#">Contact us</Link>
                </Media>
                <Media as="li">
                  <Link to="#">LinkedIn</Link>
                </Media>
                <Media as="li">
                  <Link to="#">Terms of Service</Link>
                </Media>
                <Media as="li">
                  <Link to="#">Privacy Policy</Link>
                </Media>
              </ul>
            </Col>
            <Col md={3}>
              <ul className="footer-widget list-unstyled">
                <Media as="li" className="footer-title">
                  <h4>Menu</h4>
                </Media>
                <Media as="li">
                  <Link target="_blank" to="/jobs-listing">
                    Search Jobs
                  </Link>
                </Media>
                <Media as="li">
                  <Link to="#">About</Link>
                </Media>
                <Media as="li">
                  <Link to="#">FAQ</Link>
                </Media>
                <Media as="li">
                  <Link to="#">Blog</Link>
                </Media>
              </ul>
            </Col>
            <Col md={3}>
              <ul className="footer-widget list-unstyled">
                <Media as="li" className="footer-title">
                  <h4>Browse Jobs By</h4>
                </Media>
                <Media as="li">
                  <Link to="/companies">Companies</Link>
                </Media>
                <Media as="li">
                  <Link to="/locations">Locations</Link>
                </Media>
                <Media as="li">
                  <Link to="/sectors">Sectors</Link>
                </Media>
              </ul>
            </Col>
            <Col md={3}>
              <ul className="footer-widget list-unstyled">
                <Media as="li" className="footer-title">
                  <h4>Employers</h4>
                </Media>
                <Media as="li">
                  <Link to="/employer/login">Log in</Link>
                </Media>
                <Media as="li">
                  <Link to="/employer/signup">Sign up</Link>
                </Media>

                <Media as="li" className="footer-title mt-2">
                  <h4>Job Seekers</h4>
                </Media>
                <Media as="li">
                  <Link to="/user/login">Log in</Link>
                </Media>
                <Media as="li">
                  <Link to="/user/signup">Sign up</Link>
                </Media>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(HomeFooter);
