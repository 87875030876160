import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchSubscriptionSuccess,
  fetchSubscriptionFailure,
  fetchMySubscriptionSuccess,
  fetchMySubscriptionFailure,
  fetchSingleSubscriptionSuccess,
  fetchSingleSubscriptionFailure,
  subscriptionPaymentSuccess,
  subscriptionPaymentFailure,
} from "../actions/SubscriptionAction";

import api from "../../Environment";
import {
  FETCH_SUBSCRIPTION_START,
  FETCH_MY_SUBSCRIPTION_START,
  FETCH_SINGLE_SUBSCRIPTION_START,
  SUBSCRIPTION_PAYMENT_BY_CARD_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";

function* getSubscriptionAPI(action) {
  try {
    const response = yield api.empPostMethod(
      "subscription_list", action.data
    );
    if (response.data.success) {
      yield put(fetchSubscriptionSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchSubscriptionFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchSubscriptionFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getMySubscriptionAPI(action) {
  try {
    const response = yield api.empPostMethod("subscriptions_history",action.data);
    if (response.data.success) {
      yield put(fetchMySubscriptionSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchMySubscriptionFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchMySubscriptionFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getSingleSubscriptionAPI(action) {
  try {
    const response = yield api.empPostMethod(
      "subscriptions_view",
      action.data
    );
    if (response.data.success) {
      yield put(fetchSingleSubscriptionSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchSingleSubscriptionFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchSingleSubscriptionFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* paymentByCardAPI(action) {
  try {
    const response = yield api.empPostMethod(
      "subscription_payment_by_card",
      action.data
    );
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(subscriptionPaymentSuccess(response.data.data));
      console.log(response.data.data.subscription.with_company_profile);
      localStorage.setItem("is_landing_page_enabled",response.data.data.subscription.with_company_profile);
      window.location.assign("/subscription-history");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(subscriptionPaymentFailure(response.data.error));
    }
  } catch (error) {
    yield put(subscriptionPaymentFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_SUBSCRIPTION_START, getSubscriptionAPI)]);
  yield all([
    yield takeLatest(FETCH_MY_SUBSCRIPTION_START, getMySubscriptionAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_SINGLE_SUBSCRIPTION_START, getSingleSubscriptionAPI),
  ]);
  yield all([
    yield takeLatest(SUBSCRIPTION_PAYMENT_BY_CARD_START, paymentByCardAPI),
  ]);
}
