import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Media } from "react-bootstrap";

const EmptyFooter = () => {
  return <>
    <footer className="footer-sec">
      <Container>
        <Row>
          <Col md={4}>
            <ul className="footer-widget">
              <Media as="li" className="footer-title">
                <h4>NICEBOARD</h4>
              </Media>
              <Media as="li">
                <Link to="#">
                  About us
                </Link>
              </Media>
              <Media as="li">
                <Link to="#">
                  Blog
                </Link>
              </Media>
              <Media as="li">
                <Link to="#">
                  Terms of Service
                </Link>
              </Media>
              <Media as="li">
                <Link to="#">
                  Privacy Policy
                </Link>
              </Media>
              <Media as="li">
                <Link to="#">
                  Affiliate Program
                </Link>
              </Media>
              <Media as="li" className="footer-address">
                MktCo LLC
                <br></br>
                16192 Coastal Highway
                <br></br>
                Lewes, Delaware, 19958
              </Media>
            </ul>
          </Col>
          <Col md={4}>
            <ul className="footer-widget">
              <Media as="li" className="footer-title">
                <h4>JOB BOARD</h4>
              </Media>
              <Media as="li">
                <Link to="#">
                  Features
                </Link>
              </Media>
              <Media as="li">
                <Link to="#">
                  Pricing
                </Link>
              </Media>
              <Media as="li">
                <Link to="#">
                  FAQs
                </Link>
              </Media>
              <Media as="li">
                <Link to="#">
                  Try now
                </Link>
              </Media>
              <Media as="li">
                <Link to="#">
                  Log in
                </Link>
              </Media>
              <Media as="li">
                <Link to="#">
                  For employers
                </Link>
              </Media>
              <Media as="li" className="footer-title mt-2">
                <h4>CAREER PAGE</h4>
              </Media>
              <Media as="li">
                <Link to="#">
                  Career page software
                </Link>
              </Media>
            </ul>
          </Col>
          <Col md={4}>
            <ul className="footer-widget">
              <Media as="li" className="footer-title">
                <h4>LET'S CONNECT</h4>
              </Media>
              <Media as="li">
                <Link to="#">
                  Email us
                </Link>
              </Media>
              <Media as="li">
                <Link to="#">
                  LinkedIn
                </Link>
              </Media>
              <Media as="li">
                <Link to="#">
                  <Image
                    src={window.location.origin + `/assets/img/heart-badge-white.svg`}
                    alt="Image"
                    className="footer-badge-logo"
                  />
                </Link>
              </Media>
              <Media as="li" className="footer-title mt-2">
                <h4>NETWORK</h4>
              </Media>
              <Media as="li">
                <Link to="#">
                  Open jobs
                </Link>
              </Media>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
    <div className="sub-footer">
      <div className="sub-footer-card">
        <p>© 2021 Niceboard — Modern, easy-to-use job board software.</p>
      </div>
    </div>
  </>;
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(EmptyFooter);
