import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import EmptyFooter from "./Footer/EmptyFooter";
import EmptyHeader from "./Header/EmptyHeader";
import Sidebar from "./Header/Sidebar";

const EmptyLayout = (props) => {

  return (
    <>
      <EmptyHeader/>
      <ToastContainer />
      <div className="main-warapper">
        {props.children}
      </div>
      <EmptyFooter />
    </>
  );
};

export default EmptyLayout;
