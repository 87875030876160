import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import {
  fetchEmployerJobTypeStart,
  fetchEmployerJobCategoryStart,
  fetchEmployerRegionStart,
  fetchEmployerCityStart
} from "../../store/actions/LookupAction";
import {
  addJobStart,
} from "../../store/actions/JobAction";

const Step2 = (props) => {

  const [inputData, setInputData] = useState({});

  useEffect(() => {
    props.dispatch(fetchEmployerJobCategoryStart());
    props.dispatch(fetchEmployerJobTypeStart());
    props.dispatch(fetchEmployerRegionStart());
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(addJobStart(inputData));
  };

  return (
    <>
    <div className="post-job-type-sec">
      <div className="about-your-job-sec">
        <p className="separator">About your job</p>
        <Form 
          className="about-your-company-form"
          onSubmit={handleSubmit}
          id="form"
          method="post"
          name="form"
          autoComplete="off"
        >
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Job title</Form.Label>
                <Form.Control 
                  placeholder="Product Manager, Fintech" 
                  type="text"
                  controlId="name"
                  name="name"
                  onChange={(event) => {
                    setInputData({
                      ...inputData,
                      name: event.currentTarget.value,
                    })
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="resp-mrg-btm-xs">
              <h5 className="label-about-your-job">Job Type</h5>
              {props.jobtypes.loading
                ? "Loading..."
              : props.jobtypes.data.job_types.length > 0 ? 
                <Form.Group className="mb-0">
                  <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(event) =>
                        {
                          setInputData({
                            ...inputData,
                            job_type_id: event.currentTarget.value,
                          })
                        }
                      }
                      name="job_type_id"
                  >
                      <option value="" selected>Select Job Type</option>
                      {props.jobtypes.data.job_types.map((jobtype) => 
                          <option 
                              key={jobtype.job_type_id}
                              value={jobtype.job_type_id}
                          >
                              {jobtype.name}
                          </option>
                      )}
                  </select>
                </Form.Group>
              : ""}
            </Col>
            <Col md={4} className="resp-mrg-btm-xs">
              <h5 className="label-about-your-job">Sector</h5>
              {props.categories.loading
                ? "Loading..."
              : props.categories.data.categories.length > 0
                ? 
                <Form.Group>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(event) =>
                          {
                            setInputData({
                              ...inputData,
                              category_id: event.currentTarget.value,
                            })
                          }
                        }
                        name="category_id"
                    >
                        <option value="" selected>Select Sector</option>
                        {props.categories.data.categories.map((category) => 
                            <option 
                                key={category.category_id}
                                value={category.category_id}
                            >
                                {category.name}
                            </option>
                        )}
                    </select>
                </Form.Group>
            : ""}
            </Col>
            <Col md={4}>
              <h5 className="label-about-your-job">
                Job Region
              </h5>
              {props.regions.loading
                  ? "Loading..."
                : props.regions.data.regions.length > 0
                  ? 
                  <Form.Group>
                      <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(event) =>
                            {
                              setInputData({
                                ...inputData,
                                region_id: event.currentTarget.value,
                              });
                              props.dispatch(fetchEmployerCityStart({region_id: event.currentTarget.value}));
                            }
                          }
                          name="region_id"
                      >   
                      <option value="" selected>Select Location</option>
                      {props.regions.data.regions.map((region) => 
                          <option 
                              key={region.region_id}
                              value={region.region_id}
                          >
                              {region.name}
                          </option>
                      )}
                      </select>
                  </Form.Group>
              : ""}
            </Col>
            <Col md={4}>
              <h5 className="label-about-your-job">
                Job City
              </h5>
              {props.cities.loading
                  ? <Form.Group>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="city_id"
                    >   
                      <option value="" selected>Select Location</option>
                    </select>
                  </Form.Group>
                : props.cities.data.cities.length > 0
                  ? 
                  <Form.Group>
                      <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(event) =>
                            {
                              setInputData({
                                ...inputData,
                                city_id: event.currentTarget.value,
                              })
                            }
                          }
                          name="city_id"
                      >   
                      <option value="" selected>Select Location</option>
                      {props.cities.data.cities.map((city) => 
                          <option 
                              key={city.city_id}
                              value={city.city_id}
                          >
                              {city.name}
                          </option>
                      )}
                      </select>
                  </Form.Group>
              : ""}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                  <Form.Label>
                    Job description
                  </Form.Label>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    type="text"
                    controlId="description"
                    name="description"
                    onChange={(event) =>
                      {
                        setInputData({
                          ...inputData,
                          description: event.currentTarget.value,
                        })
                      }
                    }
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <h5 className="label-about-your-job">
                Salary Type
              </h5>
              <ul className="list-unstyled about-your-job-checkbox">
                <Media as="li">
                  <div className="inputGroup">
                    <input 
                      id="Monthly" 
                      name="salary_type" 
                      type="radio"
                      value="per month"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          salary_type: event.currentTarget.value,
                        })
                      }}
                    />
                    <label for="Monthly">
                      <span>Monthly</span>
                    </label>
                  </div>
                </Media>
                <Media as="li">
                  <div className="inputGroup">
                    <input 
                      id="Anually" 
                      name="salary_type" 
                      type="radio"
                      value="per year"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          salary_type: event.currentTarget.value,
                        })
                      }}
                    />
                    <label for="Anually">
                      <span>Anually</span>
                    </label>
                  </div>
                </Media>
              </ul>
            </Col>
            <Col md={4}>
              <h5 className="label-about-your-job">
                Amount
              </h5>
              <Form.Group>
                <Form.Control 
                  placeholder="Amount" 
                  type="number"
                  controlId="amount"
                  name="amount"
                  onChange={(event) => {
                    setInputData({
                      ...inputData,
                      amount: event.currentTarget.value,
                    })
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h5 className="label-about-your-job">
                Accept applications 
              </h5>
              <ul className="list-unstyled about-your-job-checkbox">
                <Media as="li">
                  <div className="inputGroup">
                    <input 
                      id="option3" 
                      name="option3" 
                      type="checkbox"
                      checked={true}
                    />
                    <label for="option3">
                      <span>Directly (via form)</span>
                    </label>
                  </div>
                </Media>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-right">
              <Button
                className="default-btn"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>  
    </>
  );
};

const mapStateToPros = (state) => ({
  categories:state.lookups.jobCategories,
  jobtypes:state.lookups.jobtypes,
  cities:state.lookups.cities,
  regions:state.lookups.regions,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Step2));
