import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Nav, Tab, Form } from "react-bootstrap";
import UpdateProfile from "./UpdateProfile";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";
import Dashboard from "./Dashboard";
import {
    fetchEmployerDetailsStart,
} from "../../store/actions/EmployerAction";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";

const ProfileIndex = (props) => {

    useEffect(() => {
        props.dispatch(fetchEmployerDetailsStart());
    }, []);

  return (
    <>
      <div className="profile-sec">
        <Container>
            <div className="profile-header-sec">
                <h3>Employer Dashboard</h3>
                <p>Manage your job employer profile.</p>
            </div>
            <div className="profile-tab-sec">
                <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                    <Row>
                        <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="dashboard">Dashboard</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="profile">Profile</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/employers/jobs">Jobs</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/employers-applicants">Applicants</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/subscriptions">Subscriptions</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/subscription-history">Payments</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/cards">Cards</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="change_password">Change Password</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="delete_account">Delete Account</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        </Col>
                        <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="dashboard">
                                {props.profile.loading ? '' : <Dashboard profile={props.profile}/>}
                            </Tab.Pane>
                            <Tab.Pane eventKey="profile">
                                {props.profile.loading ? '' : <UpdateProfile profile={props.profile}/>}
                            </Tab.Pane>
                            <Tab.Pane eventKey="change_password">
                                <ChangePassword />
                            </Tab.Pane>
                            <Tab.Pane eventKey="delete_account">
                                <DeleteAccount />
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
    profile: state.employers.profile,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(ProfileIndex));