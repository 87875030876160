import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Image, Nav, Navbar, Media } from "react-bootstrap";


const EmptyHeader = (props) => {
  return <>
      <div className="landing-header-sec">
      <Navbar expand="lg">
          <Container>
            <Link to="/">
              <Image
                src={window.location.origin + `/assets/img/logo.png`}
                alt="Image"
                className="logo"
              />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <i className="fas fa-bars"></i>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#">How it works</Nav.Link>
                <Nav.Link href="#">Live examples</Nav.Link>
                <Nav.Link href="#">Pricing</Nav.Link>
                <Nav.Link href="#">Features</Nav.Link>
              </Nav>
              <Nav className="ml-auto resp-flex">
                <Nav.Link href="#">Sing in</Nav.Link>
                <Nav.Link href="#" className="default-btn">Try FREE</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
  </>;
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EmptyHeader);
