import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  userLoginSuccess,
  userLoginFailure,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  userRegisterSuccess,
  userRegisterFailure,
  userForgotPasswordSuccess,
  userForgotPasswordFailure,
  userDeleteAccountSuccess,
  userDeleteAccountFailure,
  userResetPasswordFailure,
  userResetPasswordSuccess,
  userChangePasswordSuccess,
  userChangePasswordFailure,
} from "../actions/UserAction";
import api from "../../Environment";

import {
  FETCH_USER_DETAILS_START,
  USER_LOGIN_START,
  UPDATE_USER_DETAILS_START,
  USER_FORGOT_PASSWORD_START,
  USER_DELETE_ACCOUNT_START,
  USER_RESET_PASSWORD_START,
  USER_CHANGE_PASSWORD_START,
  USER_REGISTER_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";

function* getUserDetailsAPI() {
  try {
    const response = yield api.userPostMethod("profile");

    if (response.data.success) {
      yield put(fetchUserDetailsSuccess(response.data));
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("userId", response.data.data.user_id);
        localStorage.setItem("accessToken", response.data.data.token);
    } else {
      yield put(fetchUserDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* userLoginAPI(action) {
  try {

    const response = yield api.userPostMethod("login", action.data);
    yield put(userLoginSuccess(response.data));
    if (response.data.success) {
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("user_picture", response.data.data.picture);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem(
          "user_unique_id",
          response.data.data.user_unique_id
        );
        localStorage.setItem("userId", response.data.data.user_id);
        localStorage.setItem("accessToken", response.data.data.token);
        localStorage.removeItem("employerLoginStatus");
        localStorage.removeItem("employer_picture");
        localStorage.removeItem("employerName");
        localStorage.removeItem("employerId");
        localStorage.removeItem("employerAccessToken");
        localStorage.removeItem("employer_unique_id");
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/user/profile");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(userLoginFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* updateUserDetailsAPI(action) {
  try {
    const response = yield api.userPostMethod("update_profile" , action.data);

    if (response.data.success) {
      yield put(updateUserDetailsSuccess(response.data.data));
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem(
        "user_unique_id",
        response.data.data.user_unique_id
      );
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(updateUserDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(updateUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* userRegisterAPI() {
  try {
    const userData = yield select(
      (state) => state.users.registerInputData.data
    );
    const response = yield api.userPostMethod("register", userData);
    yield put(userRegisterSuccess(response.data));

    if (response.data.success) {
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem(
        "user_unique_id",
        response.data.data.user_unique_id
      );
      localStorage.removeItem("employerLoginStatus");
      localStorage.removeItem("employer_picture");
      localStorage.removeItem("employerName");
      localStorage.removeItem("employerId");
      localStorage.removeItem("employerAccessToken");
      localStorage.removeItem("employer_unique_id");
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/user/profile");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(userRegisterFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* resetPasswordAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.resetPasswordInputData.inputData
    );
    const response = yield api.userPostMethod("reset_password", inputData);
    yield put(userResetPasswordSuccess(response.data));
    if (response.data.success) {
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
      yield put(createNotification(notificationMessage));
      window.location.assign("/");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(userResetPasswordFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* changePasswordAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.changePasswordInputData.data
    );
    const response = yield api.userPostMethod("change_password", inputData);
    yield put(userChangePasswordSuccess(response.data.data));
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(userChangePasswordFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* forgotPasswordAPI() {
  try {
    const userData = yield select(
      (state) => state.users.forgotPasswordInputData.data
    );

    if (
      !userData.email ||
      userData.email == undefined ||
      userData.email == null
    ) {
      const notificationMessage = getErrorNotificationMessage(
        "Please enter the email address"
      );
      yield put(createNotification(notificationMessage));
      yield put(userForgotPasswordFailure());
    } else {
      const response = yield api.userPostMethod("forgot_password", userData);
      yield put(userForgotPasswordSuccess(response.data));
      if (response.data.success) {
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/");
      } else {
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(createNotification(notificationMessage));
      }
    }
  } catch (error) {
    yield put(userForgotPasswordFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* deleteAccountAPI() {
  try {
    const userData = yield select(
      (state) => state.users.deleteAccount.inputData
    );
    const response = yield api.userPostMethod("delete_account", userData);
    yield put(userDeleteAccountSuccess(response.data));
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      localStorage.removeItem("userLoginStatus");
      localStorage.removeItem("user_picture");
      localStorage.removeItem("name");
      localStorage.removeItem("user_unique_id");
      localStorage.removeItem("userId");
      localStorage.removeItem("accessToken");
      window.location.assign("/");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(userDeleteAccountFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}


export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_USER_DETAILS_START, getUserDetailsAPI),
    yield takeLatest(USER_LOGIN_START, userLoginAPI),
    yield takeLatest(UPDATE_USER_DETAILS_START, updateUserDetailsAPI),
    yield takeLatest(USER_CHANGE_PASSWORD_START, changePasswordAPI),
    yield takeLatest(USER_RESET_PASSWORD_START, resetPasswordAPI),
    yield takeLatest(USER_FORGOT_PASSWORD_START, forgotPasswordAPI),
    yield takeLatest(USER_DELETE_ACCOUNT_START, deleteAccountAPI),
    yield takeLatest(USER_REGISTER_START, userRegisterAPI),
  ]);
}
