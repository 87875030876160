import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import HomeFooter from "./Footer/HomeFooter";
import HomeHeader from "./Header/HomeHeader";

const HomeLayout = (props) => {

  return (
    <>
      <HomeHeader/>
      <ToastContainer />
      <div className="main-warapper">
        {props.children}
      </div>
      <HomeFooter />
    </>
  );
};

export default HomeLayout;
