import {
    FETCH_CLOSED_APPLICATIONS_START,
    FETCH_CLOSED_APPLICATIONS_SUCCESS,
    FETCH_CLOSED_APPLICATIONS_FAILURE,
    FETCH_NEW_APPLICATIONS_START,
    FETCH_NEW_APPLICATIONS_SUCCESS,
    FETCH_NEW_APPLICATIONS_FAILURE,
} from "../actions/ActionConstant";
  
const initialState = {
    closedApplications: {
        data: {},
        loading: true,
        error: false,
    },
    newApplications: {
        data: {
            jobs: [],
        },
        inputData: {},
        loading: true,
        error: false,
        skip: 0,
        length: 0,
    },
};
  
const ApplicationReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case FETCH_CLOSED_APPLICATIONS_START:
            return {
                ...state,
                closedApplications: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_CLOSED_APPLICATIONS_SUCCESS:
            return {
                ...state,
                closedApplications: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_CLOSED_APPLICATIONS_FAILURE:
            return {
                ...state,
                closedApplications: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
            
        case FETCH_NEW_APPLICATIONS_START:
            return {
                ...state,
                newApplications: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                    data: {
                        jobs: [...state.newApplications.data.jobs],
                    },
                    skip: state.newApplications.skip,
                    length: state.newApplications.length,
                },
            };
        case FETCH_NEW_APPLICATIONS_SUCCESS:
            return {
                ...state,
                newApplications: {
                    loading: false,
                    error: false,
                    inputData: action.data,
                    data: {
                      jobs: [
                        ...state.newApplications.data.jobs,
                        ...action.data.job_applicants,
                      ],
                    },
                    loading: false,
                    error: false,
                    skip: action.data.job_applicants.length + state.newApplications.skip,
                    length: action.data.job_applicants.length,
                },
            };
        case FETCH_NEW_APPLICATIONS_FAILURE:
            return {
                ...state,
                newApplications: {
                    inputData: action.data,
                    data: {
                      jobs: [],
                    },
                    loading: false,
                    error: action.error,
                    skip: state.newApplications.skip,
                    length: state.newApplications.length,
                },
            };
        default:
            return state;
    }
};
  
export default ApplicationReducer;
  