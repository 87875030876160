import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media, Form } from "react-bootstrap";


const ChatIndex = (props) => {
    return (
        <>
            <div className="static-page-sec">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="home-banner-info">
                                <h3>Chat</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="chat-box">
                                <div className="discussions">
                                    <div className="discussion search">
                                        <div className="searchbar">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                            <input type="text" placeholder="Search..."></input>
                                        </div>
                                    </div>
                                    <div className="discussion message-active">
                                        <div className="photo">
                                            <div className="online"></div>
                                        </div>
                                        <div className="desc-contact">
                                            <p className="name">Megan Leib</p>
                                            <p className="message">9 pm at the bar if possible 😳</p>
                                        </div>
                                        <div className="timer">12 sec</div>
                                    </div>

                                    <div className="discussion">
                                        <div className="photo">
                                            <div className="online"></div>
                                        </div>
                                        <div className="desc-contact">
                                            <p className="name">Dave Corlew</p>
                                            <p className="message">Let's meet for a coffee or something today ?</p>
                                        </div>
                                        <div className="timer">3 min</div>
                                    </div>

                                    <div className="discussion">
                                        <div className="photo">
                                        </div>
                                        <div className="desc-contact">
                                            <p className="name">Jerome Seiber</p>
                                            <p className="message">I've sent you the annual report</p>
                                        </div>
                                        <div className="timer">42 min</div>
                                    </div>

                                    <div className="discussion">
                                        <div className="photo">
                                            <div className="online"></div>
                                        </div>
                                        <div className="desc-contact">
                                            <p className="name">Thomas Dbtn</p>
                                            <p className="message">See you tomorrow ! 🙂</p>
                                        </div>
                                        <div className="timer">2 hour</div>
                                    </div>

                                    <div className="discussion">
                                        <div className="photo">
                                        </div>
                                        <div className="desc-contact">
                                            <p className="name">Elsie Amador</p>
                                            <p className="message">What the issue is going on ?</p>
                                        </div>
                                        <div className="timer">1 day</div>
                                    </div>
                                    <div className="discussion">
                                        <div className="photo">
                                            <div className="online"></div>
                                        </div>
                                        <div className="desc-contact">
                                            <p className="name">Paul Walker</p>
                                            <p className="message">You can't see me</p>
                                        </div>
                                        <div className="timer">1 week</div>
                                    </div>
                                </div>
                                <div className="chat chat-room resp-hide">
                                    <div className="header-chat">
                                        <i className="far fa-user-circle icon"></i>
                                        <p className="name">Megan Leib</p>
                                    </div>
                                    <div className="messages-chat">
                                        <div className="message">
                                            <div className="message-left-sec">
                                                <div className="photo">
                                                    <div className="online"></div>
                                                </div>
                                                <div className="message-content">
                                                    <p className="text"> Hi, how are you ? </p>
                                                    <p className="text"> What are you doing tonight ? Want to go take a drink ?</p>
                                                    <p className="time"> 14h58</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="message">
                                            <div className="message-right-sec">
                                                <div className="photo">
                                                    <div className="online"></div>
                                                </div>
                                                <div className="message-content">
                                                    <p className="text"> Hey Megan ! It's been a while 😃</p>
                                                    <p className="text"> When can we meet ?</p>
                                                    <p className="response-time time"> 15h04</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="message">
                                            <div className="message-left-sec">
                                                <div className="photo">
                                                    <div className="online"></div>
                                                </div>
                                                <div className="message-content">
                                                    <p className="text"> Hi, how are you ? </p>
                                                    <p className="text"> What are you doing tonight ? Want to go take a drink ?</p>
                                                    <p className="time"> 14h58</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="message">
                                            <div className="message-right-sec">
                                                <div className="photo">
                                                    <div className="online"></div>
                                                </div>
                                                <div className="message-content">
                                                    <p className="text"> Hey Megan ! It's been a while 😃</p>
                                                    <p className="text"> When can we meet ?</p>
                                                    <p className="response-time time"> 15h04</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer-chat">
                                        <i className="far fa-smile icon"></i>
                                        <input type="text" className="write-message" placeholder="Type your message here"></input>
                                        <i className="icon send far fa-paper-plane"></i>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default (ChatIndex);
