import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { Container, Row, Col, Image, Nav, Tab, Form,Button } from "react-bootstrap";
import { connect } from "react-redux";
import {
    updateEmployerDetailsStart,
} from "../../store/actions/EmployerAction";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../Constant/constants";

const UpdateProfile = (props) => {

    const [profileInputData, setProfileInputData] = useState({});

    const [isAnonymousEnabled, setIsAnonymousEnabled] = useState(false);

    useEffect(() => {
        setIsAnonymousEnabled(props.profile.data.is_anonymous_enabled ? true : false);
        
    }, [props.profile.data]);

    const [previewImage, setPreviewImage] = useState('');
    const [previewCoverImage,  setPreviewCoverImage] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(updateEmployerDetailsStart(profileInputData));
    };

    const handleChangeImage = (event) => {
        if (event.currentTarget.type === "file") {
            setProfileInputData({
                ...profileInputData,
                [event.currentTarget.name]: event.currentTarget.files[0],
            });
            let reader = new FileReader();

            let file = event.currentTarget.files[0];
            
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    const handleChangeCoverImage = (event) => {
        
        if (event.currentTarget.type === "file") {
            setProfileInputData({
                ...profileInputData,
                [event.currentTarget.name]: event.currentTarget.files[0],
            });
            let reader = new FileReader();

            let file = event.currentTarget.files[0];
            
            reader.onloadend = () => {
                setPreviewCoverImage(reader.result);
            };

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    let autocomplete;

    const renderAutoComplete = () => {
      
        const { google } = props;
        if (!google) {
            console.log("asdfsadfasdfno");
            return;
        }

        autocomplete = new google.maps.places.Autocomplete(autocomplete, {
            types: ["geocode"]
        });

        autocomplete.setFields(["address_component", "geometry", "name"]);

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            console.log("Place", place);
            if (!place.geometry) return;
            let full_address = "";
            place.address_components.map(
                (address) =>
                (full_address =
                    full_address == ""
                        ? address.long_name
                        : full_address + "," + address.long_name)
            );

            setProfileInputData({
                ...profileInputData,
                address: full_address,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
            })
        });

    };
  
    return (
        <>
            <h3>Company Profile</h3>
            <Form 
                className="profile-form-sec auth-form"
                onSubmit={handleSubmit}
                id="form"
                method="post"
                name="form"
                autoComplete="off"
            >
                <p className="separator">About your company</p>
                <Row>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control 
                                placeholder="Company name"
                                type="text"
                                controlId="fCompanyName"
                                required
                                name="name"
                                defaultValue={props.profile.data.name}
                                autoComplete="nope"
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        name: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Company tagline</Form.Label>
                            <Form.Control 
                                placeholder="Company tagline" 
                                type="text"
                                controlId="CompanyTagline"
                                required
                                name="company_tagline"
                                autoComplete="nope"
                                defaultValue={props.profile.data.company_tagline}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        company_tagline: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                Company logo
                            </Form.Label>
                        </Form.Group>
                        <div className="avatar-preview-sec">
                            <div className="avatar-img-preview">
                                <Image
                                    src={previewImage ? previewImage : props.profile.data.company_logo}
                                    alt="Image"
                                    className="user-img"
                                />
                            </div>
                            <input type="file" id="picture" accept="image/*" name="picture" onChange={(event) => handleChangeImage(event)}/>
                            <label for="picture" id="picture-label"><span>Browse...</span></label>
                        </div>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                Cover Image
                            </Form.Label>
                        </Form.Group>
                        <div className="avatar-preview-sec">
                            <div className="avatar-img-preview">
                                <Image
                                    src={previewCoverImage ? previewCoverImage : props.profile.data.cover}
                                    alt="Image"
                                    className="user-img"
                                />
                            </div>
                            <input type="file" id="cover" accept="image/*" name="cover" onChange={(event) => handleChangeCoverImage(event)}/>
                            <label for="cover" id="picture-label"><span>Browse...</span></label>
                        </div>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-2">
                            <Form.Group>
                                <Form.Label>
                                    Company description
                                    <span className="optional-badge">Optional</span>
                                </Form.Label>
                                <Form.Control 
                                    placeholder="About" 
                                    type="text"
                                    controlId="About"
                                    name="about"
                                    autoComplete="nope"
                                    defaultValue={props.profile.data.about}
                                    onChange={(event) =>
                                        setProfileInputData({
                                            ...profileInputData,
                                            about: event.currentTarget.value,
                                        })
                                    }
                                />
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control 
                                placeholder="Address" 
                                type="text"
                                controlId="Address"
                                required
                                name="address"
                                onFocus={renderAutoComplete}
                                ref={ref => (autocomplete = ref)}
                                defaultValue={props.profile.data.address}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        address: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-2">
                            <Form.Group>
                                <Form.Label>
                                    Hide all company information and post anonymously
                                </Form.Label>

                                <Form.Check 
                                    type="checkbox"
                                    id="default-checkbox"
                                    label="Enable anonymous profile"
                                    name="is_anonymous_enabled"
                                    value="YES"
                                    className="form-check-label-1"
                                    checked={isAnonymousEnabled}
                                    onChange={(event) => {
                                        setIsAnonymousEnabled(!isAnonymousEnabled);
                                        setProfileInputData({
                                            ...profileInputData,
                                            is_anonymous_enabled: !isAnonymousEnabled,
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Form.Group>
                    </Col>
                </Row>  
                <p className="separator">External Links</p>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>
                                Company website
                            </Form.Label>
                            <Form.Control 
                                placeholder="Company website" 
                                type="text"
                                controlId="CompanyWebsite"
                                defaultValue={props.profile.data.website}
                                name="website"
                                autoComplete="nope"
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        website: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>
                                Company Linkedin 
                                <span className="optional-badge">Optional</span>
                            </Form.Label>
                            <Form.Control 
                                placeholder="Company Linkedin " 
                                type="text"
                                controlId="CompanyLinkedin "
                                defaultValue={props.profile.data.linkedin}
                                name="linkedin"
                                autoComplete="nope"
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        linkedin: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>
                                Company twitter handle
                                <span className="optional-badge">Optional</span>
                            </Form.Label>
                            <Form.Control 
                                placeholder="Company twitter handle" 
                                type="text"
                                controlId="twitter"
                                defaultValue={props.profile.data.twitter}
                                name="twitter"
                                autoComplete="nope"
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        twitter: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>
                                Company Facebook 
                                <span className="optional-badge">Optional</span>
                            </Form.Label>
                            <Form.Control 
                                placeholder="Company Facebook " 
                                type="text"
                                controlId="CompanyFacebook "
                                defaultValue={props.profile.data.facebook}
                                name="facebook"
                                autoComplete="nope"
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        facebook: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row> 
                <p className="separator">Your employer account</p>
                <Row>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                placeholder="Email"
                                type="text"
                                controlId="Email"
                                name="email"
                                defaultValue={props.profile.data.email}
                                onChange={(event) =>
                                    setProfileInputData({
                                        ...profileInputData,
                                        email: event.currentTarget.value,
                                    })
                                } 
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="text-right">
                        <Button
                            className="default-btn"
                            onClick={handleSubmit}
                            disabled={props.updateEmployerDetails.buttonDisable}
                            >
                            {props.updateEmployerDetails.loadingButtonContent 
                                ? props.updateEmployerDetails.loadingButtonContent
                                : "Save"}
                        </Button>
                    </Col>
                </Row>
            </Form>           
        </>
    );
};

const mapStateToPros = (state) => ({
    updateEmployerDetails: state.employers.updateEmployerDetails,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
const connector = connect(mapStateToPros, mapDispatchToProps)(translate(UpdateProfile));

export default GoogleApiWrapper({
    apiKey: apiConstants.google_api_key
})(connector);