import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media, Form } from "react-bootstrap";


const MobileChatRoom = (props) => {
    return (
        <>
            <div className="static-page-sec">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="home-banner-info">
                                <h3>Chat</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="chat chat-room resp-chat-room">
                                    <div className="header-chat">
                                        <i className="far fa-user-circle icon"></i>
                                        <p className="name">Megan Leib</p>
                                    </div>
                                    <div className="messages-chat">
                                        <div className="message">
                                            <div className="message-left-sec">
                                                <div className="photo">
                                                    <div className="online"></div>
                                                </div>
                                                <div className="message-content">
                                                    <p className="text"> Hi, how are you ? </p>
                                                    <p className="text"> What are you doing tonight ? Want to go take a drink ?</p>
                                                    <p className="time"> 14h58</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="message">
                                            <div className="message-right-sec">
                                                <div className="photo">
                                                    <div className="online"></div>
                                                </div>
                                                <div className="message-content">
                                                    <p className="text"> Hey Megan ! It's been a while 😃</p>
                                                    <p className="text"> When can we meet ?</p>
                                                    <p className="response-time time"> 15h04</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="message">
                                            <div className="message-left-sec">
                                                <div className="photo">
                                                    <div className="online"></div>
                                                </div>
                                                <div className="message-content">
                                                    <p className="text"> Hi, how are you ? </p>
                                                    <p className="text"> What are you doing tonight ? Want to go take a drink ?</p>
                                                    <p className="time"> 14h58</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="message">
                                            <div className="message-right-sec">
                                                <div className="photo">
                                                    <div className="online"></div>
                                                </div>
                                                <div className="message-content">
                                                    <p className="text"> Hey Megan ! It's been a while 😃</p>
                                                    <p className="text"> When can we meet ?</p>
                                                    <p className="response-time time"> 15h04</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer-chat">
                                        <i className="far fa-smile icon"></i>
                                        <input type="text" className="write-message" placeholder="Type your message here"></input>
                                        <i className="icon send far fa-paper-plane"></i>
                                    </div>
                                </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default (MobileChatRoom);
