import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";

import {
  userRegisterStart,
} from "../../../store/actions/UserAction";
const Step2 = (props) => {


  useEffect(() => {
   
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(props.signupInputData);
    props.dispatch(userRegisterStart(props.signupInputData));
  };

  return (
    <>
    <div className="post-job-type-sec">
      <div className="about-your-job-sec">
        <p className="separator">Address <span>Optional</span></p>
        <Form 
          className="about-your-company-form"
          onSubmit={handleSubmit}
          id="form"
          method="post"
          name="form"
          autoComplete="off"
        >
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Address
                </Form.Label>
                <Form.Control 
                  placeholder="Address" 
                  type="text"
                  controlId="Address"
                  value={props.signupInputData.address}
                  name="address"
                  autoComplete="nope"
                  onChange={(event) =>
                      props.setSignupInputData({
                          ...props.signupInputData,
                          address: event.currentTarget.value,
                      })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  City
                </Form.Label>
                <Form.Control 
                  placeholder="City" 
                  type="text"
                  controlId="City"
                  value={props.signupInputData.city}
                  name="city"
                  autoComplete="nope"
                  onChange={(event) =>
                    props.setSignupInputData({
                          ...props.signupInputData,
                          city: event.currentTarget.value,
                      })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  State
                </Form.Label>
                <Form.Control 
                  placeholder="State" 
                  type="text"
                  controlId="State"
                  value={props.signupInputData.state}
                  name="state"
                  autoComplete="nope"
                  onChange={(event) =>
                    props.setSignupInputData({
                          ...props.signupInputData,
                          state: event.currentTarget.value,
                      })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Zipcode
                </Form.Label>
                <Form.Control 
                  placeholder="Zipcode" 
                  type="text"
                  controlId="Zipcode"
                  value={props.signupInputData.zipcode}
                  name="zip"
                  autoComplete="nope"
                  onChange={(event) =>
                    props.setSignupInputData({
                          ...props.signupInputData,
                          zip: event.currentTarget.value,
                      })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Country
                </Form.Label>
                <Form.Control 
                  placeholder="Country" 
                  type="text"
                  controlId="Country"
                  value={props.signupInputData.country}
                  name="country"
                  autoComplete="nope"
                  onChange={(event) =>
                    props.setSignupInputData({
                          ...props.signupInputData,
                          country: event.currentTarget.value,
                      })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="text-right">
              <Button
                className="default-btn"
                onClick={handleSubmit}
              >
                SignUp
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>  
    </>
  );
};

const mapStateToPros = (state) => ({

});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Step2));
