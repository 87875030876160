import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Media,
  Form,
} from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchCompanyListStart } from "../../store/actions/UserJobAction";
import useInfiniteScroll from "../helper/useInfiniteScroll";
const queryString = require("query-string");

const CompanyListingIndex = (props) => {
  const [searchKey, setSearchkey] = useState("");

  useEffect(() => {
    props.dispatch(
      fetchCompanyListStart({
        loadMore: "",
      })
    );
  }, []);

  const fetchCompanyData = () => {
    setTimeout(() => {
      if (props.companies.length !== 0) {
        props.dispatch(
          fetchCompanyListStart({
            skip: props.companies.skip,
            loadMore: "loadMore",
            search_key: searchKey,
          })
        );
        setIsFetching(false);
      } else {
        setNoMoreData(true);
      }
    }, 3000);
  };

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchCompanyData);

  const [noMoreData, setNoMoreData] = useState(false);

  const handleSearch = (event, search_key) => {
    event.preventDefault();
    setSearchkey(search_key);
    props.dispatch(fetchCompanyListStart({ search_key: search_key }));
  };

  var location = {}
  
  const handleCompanyRedirect = (company) => {
    const query = queryString.stringify(
      {company : [company.company_unique_id]},
      {arrayFormatSeparator: "|" }
    );
    location = {
      pathname : `/jobs-listing`,
      search : query
    }
    return location;
  }

  return (
    <>
      <div className="company-listing-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="home-banner-info">
                <h3>Leading Fintech's looking for you.</h3>
                <p>
                  Fintexer is a niche job board for the Fintech and Payments
                  industry. Search, discover & apply to your next Fintech job on
                  Fintexer.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <div className="job-filter-header-sec">
                <h3 className="section-title">
                  Companies
                  <span className="count">
                    {props.companies.loading
                      ? "Loading"
                      : props.companies.data.companies.length}
                  </span>
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3} className="resp-mrg-btm-xs">
              <div className="sticky-box">
                <Form>
                  <div className="filter-search-sec">
                    <h5>Search by name</h5>
                    <Form.Group className="mb-0">
                      <Form.Control
                        type="text"
                        name="search_key"
                        placeholder="Search companies.."
                        onChange={(event) =>
                          event.currentTarget.value &&
                          event.currentTarget.value.length > 3
                            ? handleSearch(event, event.currentTarget.value)
                            : ""
                        }
                      />
                    </Form.Group>
                  </div>
                </Form>
              </div>
            </Col>
            <Col md={9}>
              <div className="job-company-listing-sec">
                <div className="job-company-listing-box">
                  {props.companies.loading
                    ? "Loading"
                    : props.companies.data.companies.length > 0
                    ? props.companies.data.companies.map((company) => (
                        <Link
                          target="_blank"
                          to={handleCompanyRedirect(company)}
                        >
                          <div className="job-company-listing-card">
                            <div className="job-company-listing-logo-bg"></div>
                            <Image
                              src={company.picture}
                              alt={company.name}
                              className="job-company-listing-logo"
                            />
                            <div className="job-company-listing-info">
                              <h4>{company.name}</h4>
                              <p>{company.company_tagline}</p>
                            </div>
                            <div className="job-company-listing-counting">
                              <p className="company-jobs">
                                <span>{company.total_jobs} jobs</span>
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))
                    : ""}
                </div>
                {noMoreData !== true ? (
                  <>{isFetching && "Fetching more items..."}</>
                ) : (
                  "No More Data"
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  companies: state.userjobs.companies,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(CompanyListingIndex));
