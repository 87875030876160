import React, { useEffect } from "react";
import api from "../../Environment";

const Logout = (props) => {
  useEffect(() => {
    api.empPostMethod("logout").then((response) => {
      if (response.data.success) {
      } else {
      }
    });
    localStorage.removeItem("employerLoginStatus");
    localStorage.removeItem("employer_picture");
    localStorage.removeItem("employerName");
    localStorage.removeItem("employerId");
    localStorage.removeItem("employerAccessToken");
    localStorage.removeItem("employer_unique_id");
    props.history.push("/");

  }, []);
  return "";
};

export default Logout;
