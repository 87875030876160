import React, { useEffect } from "react";
import api from "../../../Environment";

const Logout = (props) => {
  useEffect(() => {
    api.userPostMethod("logout").then((response) => {
      if (response.data.success) {
        console.log("success");
      } else {
      }
    });
    localStorage.removeItem("userLoginStatus");
    localStorage.removeItem("user_picture");
    localStorage.removeItem("name");
    localStorage.removeItem("user_unique_id");
    localStorage.removeItem("userId");
    localStorage.removeItem("accessToken");
    props.history.push("/");

  }, []);
  return "";
};

export default Logout;
