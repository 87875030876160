import React, { useState } from "react";
import { Dropdown, Image, Media, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import PaymentModal from "./PaymentModal";

const SubscriptionDataCard = (props) => {

    const [paymentModal, setPaymentModal] = useState(false);

    const openPaymentModal = (event) => {
        event.preventDefault();
        setPaymentModal(true);
    };

    const closePaymentModal = () => {
        setPaymentModal(false);
    };

    return (

        <div className="select-plan-card" key={props.subscription.subscription_id}>
            <div className="select-plan-header">
                <div className="select-plan-info">
                    <h4 className="name">{props.subscription.name}</h4>
                    <h6 className="price">{props.subscription.amount_formatted}</h6>
                    <ul className="list-unstyled select-plan-action">
                        <Media as="li" className="add-count">
                            {props.subscription.no_of_jobs} Jobs
                        </Media>
                        <Media as="li" className="day-count">
                            {props.subscription.plan_formatted}
                        </Media>
                        {props.subscription.with_company_profile ? 
                            <Media as="li" className="theme-text-color">
                                With Company Profile
                            </Media>
                        : ''}
                    </ul>
                </div>
                <div className="select-plan-btn-action">
                    <Button
                        className="default-btn-outline"
                        type="submit"
                        disabled={props.subscription.buttonDisable}
                        onClick={(event) => openPaymentModal(event)}
                    >
                        Select
                    </Button>

                </div>
            </div>
            <div className="select-plan-body">
                <div className="description">
                <p
                    dangerouslySetInnerHTML={{
                        __html: props.subscription.description != undefined ? props.subscription.description : "",
                    }}
                ></p>
                </div>
            </div>
            <PaymentModal 
                paymentModal={paymentModal}
                closePaymentModal={closePaymentModal}
                subscription={props.subscription}
            />
        </div>
        );
    };
    
const mapStateToPros = (state) => ({
});
    
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
    
export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(SubscriptionDataCard));
    