import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Nav, Tab, Form,Button } from "react-bootstrap";
import {
    userRegisterStart,
} from "../../../store/actions/UserAction";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";

const SignUp = (props) => {

    const [signupInputData, setSignupInputData] = useState({});

    const [previewImage, setPreviewImage] = useState('');

    const [isRemoteJobChecked, setIsRemoteJobChecked] = useState(0);

    const [isProfileVisibleChecked, setIsProfileVisibleChecked] = useState(0);

    const handleSignup = (event) => {
        event.preventDefault();
        props.dispatch(userRegisterStart(signupInputData));
    };

    const handleChangeImage = (event) => {
        if (event.currentTarget.type === "file") {
            setSignupInputData({
                ...signupInputData,
                [event.currentTarget.name]: event.currentTarget.files[0],
            });
            let reader = new FileReader();

            let file = event.currentTarget.files[0];
            
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    const handleChangeFile = (event) => {
        if (event.currentTarget.type === "file") {
            setSignupInputData({
                ...signupInputData,
                [event.currentTarget.name]: event.currentTarget.files[0],
            });
        }
    };

    return (
        <>
        <div className="profile-sec">
            <Container>
                <div className="profile-header-sec">
                    <h3>Create your job seeker profile.</h3>
                    <p>All fields are optional. Your information will not be shared with third parties.</p>
                </div>
                <div className="profile-tab-sec">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                        <Row>
                            <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="profile">
                                    <Form 
                                        className="profile-form-sec auth-form"
                                        onSubmit={handleSignup}
                                        id="form"
                                        method="post"
                                        name="form"
                                        autoComplete="off"
                                    >
                                        <p className="separator">Personal information</p>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control 
                                                        placeholder="First name"
                                                        type="text"
                                                        controlId="firstName"
                                                        required
                                                        value={signupInputData.first_name}
                                                        name="first_name"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                first_name: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control 
                                                        placeholder="Last name" 
                                                        type="text"
                                                        controlId="lastName"
                                                        required
                                                        value={signupInputData.last_name}
                                                        name="last_name"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                last_name: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        LinkedIn profile
                                                        <span className="optional-badge">Optional</span>
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="LinkedIn profile" 
                                                        type="text"
                                                        controlId="LinkedInProfile"
                                                        value={signupInputData.linkedin}
                                                        name="linkedin"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                linkedin: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Phone number
                                                        <span className="optional-badge">Optional</span>
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="Phone number" 
                                                        type="text"
                                                        controlId="PhoneNumber"
                                                        value={signupInputData.mobile}
                                                        name="mobile"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                mobile: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <p className="separator">Address <span>Optional</span></p>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Address
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="Address" 
                                                        type="text"
                                                        controlId="Address"
                                                        value={signupInputData.address}
                                                        name="address"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                address: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        City
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="City" 
                                                        type="text"
                                                        controlId="City"
                                                        value={signupInputData.city}
                                                        name="city"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                city: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        State
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="State" 
                                                        type="text"
                                                        controlId="State"
                                                        value={signupInputData.state}
                                                        name="state"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                state: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Zipcode
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="Zipcode" 
                                                        type="text"
                                                        controlId="Zipcode"
                                                        value={signupInputData.zipcode}
                                                        name="zip"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                zip: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Country
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="Country" 
                                                        type="text"
                                                        controlId="Country"
                                                        value={signupInputData.country}
                                                        name="country"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                country: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <p className="separator">Profile</p>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>
                                                        Photo
                                                        <span className="optional-badge">Optional</span>
                                                    </Form.Label>
                                                </Form.Group>
                                                <div className="avatar-preview-sec">
                                                    <div className="avatar-img-preview">
                                                        <Image
                                                            src={previewImage ? previewImage : window.location.origin + `/assets/img/user.png`}
                                                            alt="Image"
                                                            className="user-img"
                                                        />
                                                    </div>
                                                    <input type="file" id="picture" accept="image/*" name="picture" onChange={(event) => handleChangeImage(event)}/>
                                                    <label for="picture" id="picture-label"><span>Browse...</span></label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>
                                                        Resume
                                                        <span className="optional-badge">Optional</span>
                                                    </Form.Label>
                                                    <div className="resume-upload-sec">
                                                        {/* <div className="resume-file-sec">
                                                            <Link to="#" target="_blank">View resume</Link>
                                                        </div> */}
                                                        <div className="fileUpload">
                                                            <input type="file" name="resume" id="resume" accept=".pdf" className="upload" onChange={(event) => handleChangeFile(event)}/>
                                                            <span>Upload</span>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            About
                                                            <span className="optional-badge">Optional</span>
                                                        </Form.Label>
                                                        <Form.Control 
                                                            placeholder="About" 
                                                            type="text"
                                                            controlId="About"
                                                            value={signupInputData.about}
                                                            name="about"
                                                            autoComplete="nope"
                                                            onChange={(event) =>
                                                                setSignupInputData({
                                                                    ...signupInputData,
                                                                    about: event.currentTarget.value,
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Check 
                                                        type="checkbox"
                                                        id="default-checkbox"
                                                        label="I am interested in remote job opportunities"
                                                        name="is_remote_job"
                                                        className="form-check-label-1"
                                                        checked={isRemoteJobChecked}
                                                        onChange={(event) => {
                                                            setIsRemoteJobChecked(!isRemoteJobChecked);
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                is_remote_job: !isRemoteJobChecked,
                                                            });
                                                        }}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Check 
                                                        type="checkbox"
                                                        id="default-checkbox-1"
                                                        label="Display my profile to employers"
                                                        className="form-check-label-1"
                                                        name="is_profile_visible"
                                                        checked={isProfileVisibleChecked}
                                                        onChange={(event) => {
                                                            setIsProfileVisibleChecked(!isProfileVisibleChecked);
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                is_profile_visible: !isProfileVisibleChecked,
                                                            })
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <p className="separator">Create your account</p>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control 
                                                        placeholder="E-mail"
                                                        type="text"
                                                        controlId="loginemail"
                                                        value={signupInputData.email}
                                                        name="email"
                                                        required
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                email: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Password
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="Password" 
                                                        type="password"
                                                        required
                                                        controlId="Password"
                                                        value={signupInputData.password}
                                                        name="password"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                password: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} className="text-right">
                                                <Button
                                                    id="signup"
                                                    type="submit"
                                                    onClick={handleSignup}
                                                    className="default-btn btn-block"
                                                    disabled={props.signup.buttonDisable}
                                                    >
                                                    {props.signup.loadingButtonContent !== null
                                                        ? props.signup.loadingButtonContent
                                                        : "Create Account"}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Tab.Pane>
                            </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </Container>
        </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    signup: state.users.registerInputData,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(SignUp));
