import {
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_FORGOT_PASSWORD_START,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAILURE,
  USER_DELETE_ACCOUNT_START,
  USER_DELETE_ACCOUNT_SUCCESS,
  USER_DELETE_ACCOUNT_FAILURE,
  USER_CHANGE_PASSWORD_START,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_FAILURE,
  USER_RESET_PASSWORD_START,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAILURE,
  USER_REGISTER_START,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_START,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  profile: {
    data: {},
    loading: false,
    error: false,
  },
  loginInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  registerInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  resetPasswordInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  forgotPasswordInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  changePasswordInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  deleteAccount: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  registerVerify: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    buttonLoadingContent: null,
    inputData: {},
  },
  registerVerifyResend: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    buttonLoadingContent: null,
    inputData: {},
  },
  updateUserDetails : {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent : "",
    buttonDisable : false
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_START:
      return {
        ...state,
        loginInputData: {
          data: {
            ...action.data,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
        loginInputData: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        loginInputData: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case FETCH_USER_DETAILS_START:
      return {
        ...state,
        profile: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        profile: {
          data: {},
          loading: false,
          error: true,
        },
      };
    case UPDATE_USER_DETAILS_START:
      return {
        ...state,
        updateUserDetails: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent : "",
          buttonDisable : true
        },
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        updateUserDetails: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent : "",
          buttonDisable : false
        },
      };
    case UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        updateUserDetails: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent : "",
          buttonDisable : false
        },
      };
    case USER_REGISTER_START:
      return {
        ...state,
        registerInputData: {
          data: {
            ...action.data,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
        },
        registerInputData: {
          data: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case USER_REGISTER_FAILURE:
      return {
        ...state,
        registerInputData: {
          data: {},
          loading: false,
          error: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case USER_FORGOT_PASSWORD_START:
      return {
        ...state,
        forgotPasswordInputData: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case USER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordInputData: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case USER_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordInputData: {
          data: {},
          loading: true,
          error: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case USER_DELETE_ACCOUNT_START:
      return {
        ...state,
        deleteAccount: {
          data: {},
          loading: true,
          error: {},
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case USER_DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccount: {
          data: action.data,
          loading: true,
          error: {},
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case USER_DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteAccount: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case USER_CHANGE_PASSWORD_START:
      return {
        ...state,
        changePasswordInputData: {
          data: action.data,
          loading: true,
          error: false,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading please wait",
      };
    case USER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordInputData: {
          data: {},
          success: action.data,
          loading: false,
          error: false,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case USER_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordInputData: {
          data: {},
          loading: true,
          error: action.error,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case USER_RESET_PASSWORD_START:
      return {
        ...state,
        resetPasswordInputData: {
          inputData: action.data,
          loading: false,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case USER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
        inputData: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case USER_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        buttonDisable: false,
        loadingButtonContent: null,
      };
    default:
      return state;
  }
};

export default userReducer;
