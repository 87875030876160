import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Nav, Tab, Form, Media, Button } from "react-bootstrap";

const FeaturedBrowseCompanies = (props) => {
    return (
        <>
            <div className="featured-browse-companies-sec">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="featured-browse-companies-header">
                                <h5>Featured Companies</h5>
                                {/* <Link to="#">See All</Link> */}
                            </div>
                            <div className="featured-browse-companies-box">
                                {props.companies.loading
                                    ? "Loading"
                                : props.companies.data.companies.length > 0
                                    ? props.companies.data.companies.map((company) => (
                                    <Link 
                                        target="_blank"
                                        key={company.company_unique_id}
                                        to={company.is_landing_page_enabled ? `/company-view/`+company.company_unique_id : props.handleCompanyRedirect(company)}
                                    >
                                        <div className="featured-browse-companies-card">
                                            <div className="featured-browse-companies-img-sec">
                                                <Image
                                                    src={company.company_logo}
                                                    alt={company.name}
                                                    className="featured-browse-companies-img"
                                                />
                                            </div>
                                            <div className="featured-browse-companies-info">
                                                {/* <div className="featured-browse-companies-logo-sec">
                                                    <Image
                                                        src={company.company_logo}
                                                        alt={company.name}
                                                        className="featured-browse-companies-logo"
                                                    />
                                                </div> */}
                                                <h4>{company.name}</h4>
                                                <h5>{company.company_tagline}</h5>
                                                <div className="job-live-status">
                                                    <p>{company.total_jobs} Jobs Live</p>
                                                    <Image
                                                        src={window.location.origin + `/assets/img/icon/jobs-live-icon.svg`}
                                                        alt="Image"
                                                        className="job-live-icon"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    ))
                                : ""}
                                
                            </div>
                        </Col>
                    </Row>
                    {props.noMoreData !== true ? (
                        <>{props.isFetching && "Fetching more items..."}</>
                    ) : (
                        "No More Data"
                    )}
                </Container>
            </div>
        </>
    );
};

export default (FeaturedBrowseCompanies);