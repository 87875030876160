import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Image,
  Nav,
  Navbar,
  Media,
  Dropdown,
} from "react-bootstrap";
import configuration from "react-global-configuration";

const HomeHeader = (props) => {
  return (
    <>
      <div className="landing-header-sec">
        <Navbar expand="lg">
          <Container>
            <div className="mobile-header-profile-sec home-dropdown">
            {!localStorage.getItem("employerLoginStatus") ? 
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <Image
                    src={
                      localStorage.getItem("userLoginStatus")
                        ? localStorage.getItem("user_picture")
                        : window.location.origin +
                          `/assets/img/icon/icon-jobseeker.png`
                    }
                    alt="Image"
                    className="user-img-header"
                  />
                </Dropdown.Toggle>
                {localStorage.getItem("userLoginStatus") ? (
                  <Dropdown.Menu>
                    <p>Job Seekers</p>
                    <Dropdown.Item href="/user/profile">Profile</Dropdown.Item>
                    <Dropdown.Item href="/user/logout">Sign out</Dropdown.Item>
                  </Dropdown.Menu>
                ) : (
                  <Dropdown.Menu>
                    <p>Job Seekers</p>
                    <Dropdown.Item href="/user/login">Log in</Dropdown.Item>
                    <Dropdown.Item href="/user/signup">Sign up</Dropdown.Item>
                  </Dropdown.Menu>
                )}
              </Dropdown>
              : ''}
              {!localStorage.getItem("userLoginStatus") ? 
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <Image
                      src={
                        localStorage.getItem("employerLoginStatus")
                          ? localStorage.getItem("employer_picture")
                          : window.location.origin +
                            `/assets/img/employer-icon.png`
                      }
                      alt="Image"
                      className="user-img-header"
                    />
                  </Dropdown.Toggle>
                  {localStorage.getItem("employerLoginStatus") ? (
                    <Dropdown.Menu>
                      <p>Employers</p>
                      <Dropdown.Item href="/employer/profile">
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item href="/employers/jobs">Jobs</Dropdown.Item>
                      <Dropdown.Item href="/employer/logout">
                        Sign out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  ) : (
                    <Dropdown.Menu>
                      <p>Employers</p>
                      <Dropdown.Item href="/employer/login">Log in</Dropdown.Item>
                      <Dropdown.Item href="/employer/signup">
                        Sign up
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              : ''}
            </div>
            <Link to="/">
              <Image
                src={configuration.get("configData.site_logo")}
                alt="Image"
                className="logo"
              />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <i className="fas fa-bars"></i>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link target="_blank" href="/jobs-listing">
                  Search Jobs
                </Nav.Link>
                <Nav.Link href="/companies">Companies</Nav.Link>
                <Nav.Link href="/sectors">Sectors</Nav.Link>
                <Nav.Link href="/static-page">About</Nav.Link>
                <Nav.Link href="/static-page">FAQ</Nav.Link>
                <Nav.Link href="#">Blog</Nav.Link>
              </Nav>
              <Nav className="ml-auto resp-flex home-dropdown">
                {!localStorage.getItem("employerLoginStatus") ? 
                  <Dropdown className="mobile-hide">
                    <Dropdown.Toggle id="dropdown-basic">
                      <Image
                        src={
                          localStorage.getItem("userLoginStatus")
                            ? localStorage.getItem("user_picture")
                            : window.location.origin +
                              `/assets/img/icon/icon-jobseeker.png`
                        }
                        alt="Image"
                        className="user-img-header"
                      />
                    </Dropdown.Toggle>

                    {localStorage.getItem("userLoginStatus") ? (
                      <Dropdown.Menu>
                        <p>Job Seekers</p>
                        <Dropdown.Item href="/user/profile">
                          Profile
                        </Dropdown.Item>
                        <Dropdown.Item href="/user/logout">
                          Sign out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    ) : (
                      <Dropdown.Menu>
                        <p>Job Seekers</p>
                        <Dropdown.Item href="/user/login">Log in</Dropdown.Item>
                        <Dropdown.Item href="/user/signup">Sign up</Dropdown.Item>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                : ""}
                {!localStorage.getItem("userLoginStatus") ? 
                  <Dropdown className="mobile-hide">
                    <Dropdown.Toggle id="dropdown-basic">
                      <Image
                        src={
                          localStorage.getItem("employerLoginStatus")
                            ? localStorage.getItem("employer_picture")
                            : window.location.origin +
                              `/assets/img/employer-icon.png`
                        }
                        alt="Image"
                        className="user-img-header"
                      />
                    </Dropdown.Toggle>
                    {localStorage.getItem("employerLoginStatus") ? (
                      <Dropdown.Menu>
                        <p>Employers</p>
                        <Dropdown.Item href="/employer/profile">
                          Profile
                        </Dropdown.Item>
                        {localStorage.getItem("is_landing_page_enabled") == 0 ? 
                          <Dropdown.Item href="/subscriptions">Landing Page</Dropdown.Item>
                        : 
                          <Dropdown.Item href={`/company-view/`+localStorage.getItem("employer_unique_id")}>Landing Page</Dropdown.Item>
                        }
                      
                        <Dropdown.Item href="/employers/jobs">Jobs</Dropdown.Item>
                        <Dropdown.Item href="/employer/logout">
                          Sign out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    ) : (
                      <Dropdown.Menu>
                        <p>Employers</p>
                        <Dropdown.Item href="/employer/login">
                          Log in
                        </Dropdown.Item>
                        <Dropdown.Item href="/employer/signup">
                          Sign up
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                : ""}
                {localStorage.getItem("employerLoginStatus") ? (
                  <Nav.Link href="/create-post" className="default-btn">
                    New Job
                  </Nav.Link>
                ) : (
                  ""
                )}

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomeHeader);
