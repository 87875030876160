import {
    EMPLOYER_FETCH_JOB_START,
    EMPLOYER_FETCH_JOB_SUCCESS,
    EMPLOYER_FETCH_JOB_FAILURE,
    ADD_JOB_START,
    ADD_JOB_SUCCESS,
    ADD_JOB_FAILURE,
    EDIT_JOB,
    UPDATE_JOB_START,
    UPDATE_JOB_SUCCESS,
    UPDATE_JOB_FAILURE,
    DELETE_JOB_START,
    DELETE_JOB_SUCCESS,
    DELETE_JOB_FAILURE,
    PUBLISH_JOB_START,
    PUBLISH_JOB_SUCCESS,
    PUBLISH_JOB_FAILURE,
    EMPLOYER_FETCH_SINGLE_JOB_START,
    EMPLOYER_FETCH_SINGLE_JOB_SUCCESS,
    EMPLOYER_FETCH_SINGLE_JOB_FAILURE,
} from "../actions/ActionConstant";
  
const initialState = {
    job: {
        data: {
            jobs: [],
        },
        inputData: {},
        loading: true,
        error: false,
        skip: 0,
        length: 0,
        loadMore: false,
    },
    addJob: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    deleteJob: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    publishJob: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    singleJob: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    updateJob: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    buttonDisable: false,
    loadingButtonContent: null,
};
  
const JobReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMPLOYER_FETCH_JOB_START:
            return {
                ...state,
                job: {
                    inputData: action.data,
                    data: {
                        jobs: 
                        action.data.loadMore === "loadMore"
                          ? [...state.job.data.jobs] : [],
                    },
                    loadMore: action.data.loadMore === "loadMore" ? true : false,
                    loading: true,
                    error: false,
                    skip: action.data.loadMore === "loadMore" ? state.job.skip : 0,
                    length: action.data.loadMore === "loadMore" ? state.job.length : 0,
                },
            };
        case EMPLOYER_FETCH_JOB_SUCCESS:
            return {
                ...state,
                job: {
                    inputData: action.data,
                    data: {
                        jobs: [
                            ...state.job.data.jobs,
                            ...action.data.company_jobs,
                        ],
                    },
                    loading: false,
                    error: false,
                    skip: action.data.company_jobs.length + state.job.skip,
                    length: action.data.company_jobs.length,
                },
            };
        case EMPLOYER_FETCH_JOB_FAILURE:
            return {
                ...state,
                job: {
                    inputData: action.data,
                    data: {
                        jobs: [],
                    },
                    loading: false,
                    loadMore: state.job.loadMore,
                    error: action.error,
                    skip: state.job.skip,
                    length: state.job.length,
                },
            };
        case ADD_JOB_START:
            return {
                ...state,
                addJob: {
                    data: action.data,
                    loading: true,
                    error: false,
                },
                buttonDisable: true,
                loadingButtonContent: "Loading... Please wait",
            };
        case ADD_JOB_SUCCESS:
            return {
                ...state,
                addJob: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
                buttonDisable: false,
                loadingButtonContent: null,
            };
        case ADD_JOB_FAILURE:
            return {
                ...state,
                addJob: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
                buttonDisable: false,
                loadingButtonContent: null,
            };
        case UPDATE_JOB_START:
            return {
                ...state,
                updateJob: {
                    data: {},
                    loading: true,
                    error: false,
                },
                buttonDisable: true,
                loadingButtonContent: "Loading... Please wait",
            };
        case UPDATE_JOB_SUCCESS:
            return {
                ...state,
                updateJob: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
                buttonDisable: false,
                loadingButtonContent: null,
            };
        case UPDATE_JOB_FAILURE:
            return {
                ...state,
                updateJob: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
                buttonDisable: false,
                loadingButtonContent: null,
            };
        case EDIT_JOB:
            return {
                ...state,
                singleJob: {
                    loading: false,
                    error: false,
                    data: {
                        jobs: {
                            ...state.singleJob.data.jobs,
                            [action.name]: action.value,
                        },
                    },
                },
            };
        case DELETE_JOB_START:
            return {
                ...state,
                deleteJob: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                },
            };
        case DELETE_JOB_SUCCESS:
            return {
                ...state,
                deleteJob: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case DELETE_JOB_FAILURE:
            return {
                ...state,
                deleteJob: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case EMPLOYER_FETCH_SINGLE_JOB_START:
            return {
                ...state,
                singleJob: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                },
            };
        case EMPLOYER_FETCH_SINGLE_JOB_SUCCESS:
            return {
                ...state,
                singleJob: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case EMPLOYER_FETCH_SINGLE_JOB_FAILURE:
            return {
                ...state,
                singleJob: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case PUBLISH_JOB_START:
            return {
                ...state,
                publishJob: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                },
            };
        case PUBLISH_JOB_SUCCESS:
            return {
                ...state,
                publishJob: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case PUBLISH_JOB_FAILURE:
            return {
                ...state,
                publishJob: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        default:
            return state;
    }
};
  
export default JobReducer;
  