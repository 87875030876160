import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchSingleJobStart,addJobStart } from "../../store/actions/JobAction";
import {
    fetchEmployerJobTypeStart,
    fetchEmployerJobCategoryStart,
    fetchEmployerRegionStart,
    fetchEmployerCityStart
} from "../../store/actions/LookupAction";

const JobEdit = (props) => {

    const [inputData, setInputData] = useState({});

    useEffect(() => {
        props.dispatch(fetchEmployerRegionStart());
        props.dispatch(fetchEmployerJobCategoryStart());
        props.dispatch(fetchEmployerJobTypeStart());
        if (props.singleJob.loading)
            props.dispatch(
                fetchSingleJobStart({
                    company_job_unique_id: props.match.params.company_job_unique_id,
                })
            );
    }, []);

    useEffect(() => {
        if (!props.singleJob.loading) {
            setInputData({
                ...inputData,
                company_job_id:props.singleJob.data.company_job.company_job_id,
                name: props.singleJob.data.company_job.name,
                description: props.singleJob.data.company_job.description,
                job_type_id: props.singleJob.data.company_job.job_type_id,
                category_id: props.singleJob.data.company_job.category_id,
                region_id: props.singleJob.data.company_job.region_id,
                city_id: props.singleJob.data.company_job.city_id,
                amount: props.singleJob.data.company_job.amount,
            });
            props.dispatch(fetchEmployerCityStart({region_id: props.singleJob.data.company_job.region_id}));
        }
        
    }, [props.singleJob.data]);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(addJobStart(inputData));
    };

    return (
    <>
        <Container>
            {props.singleJob.loading ? (
                ""
            ) : (
            <div className="post-job-type-sec">
                <div className="about-your-job-sec">
                    <Form 
                        className="about-your-company-form"
                        onSubmit={handleSubmit}
                        id="form"
                        method="post"
                        name="form"
                        autoComplete="off"
                    >
                        <Row>
                            <Col md={12}>
                                <Form.Group>
                                    <Form.Label>Job title</Form.Label>
                                    <Form.Control 
                                        placeholder="Product Manager, Fintech" 
                                        type="text"
                                        controlId="title"
                                        name="name"
                                        defaultValue={props.singleJob.data.company_job.company_name}
                                        onChange={(event) =>
                                            setInputData({
                                                ...inputData,
                                                name: event.currentTarget.value,
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} className="resp-mrg-btm-xs">
                            <h5 className="label-about-your-job">Job Type</h5>
                                {props.jobtypes.loading
                                    ? "Loading..."
                                : props.jobtypes.data.job_types.length > 0 ? 
                                    <Form.Group className="mb-0">
                                        <select
                                            className="form-select form-control"
                                            aria-label="Default select example"
                                            onChange={(event) =>
                                                setInputData({
                                                    ...inputData,
                                                    job_type_id: event.currentTarget.value,
                                                })
                                            }
                                            name="job_type_id"
                                        >
                                            <option value="" selected>Select Job Type</option>
                                            {props.jobtypes.data.job_types.map((jobtype) => 
                                                <option 
                                                    key={jobtype.job_type_id}
                                                    value={jobtype.job_type_id}
                                                    selected={jobtype.job_type_id == props.singleJob.data.company_job.job_type_id ? true : false}
                                                >
                                                    {jobtype.name}
                                                </option>
                                            )}
                                        </select>
                                    </Form.Group>
                                : ""}
                            </Col>
                            <Col md={4} className="resp-mrg-btm-xs">
                                <h5 className="label-about-your-job">Sector</h5>
                                {props.categories.loading
                                    ? "Loading..."
                                : props.categories.data.categories.length > 0 ? 
                                    <Form.Group>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            onChange={(event) =>
                                                setInputData({
                                                ...inputData,
                                                category_id: event.currentTarget.value,
                                                })
                                            }
                                            name="category_id"
                                        >
                                            <option value="" selected>Select Sector</option>
                                            {props.categories.data.categories.map((category) => 
                                                <option 
                                                    key={category.category_id}
                                                    value={category.category_id}
                                                    selected={category.category_id == props.singleJob.data.company_job.category_id ? true : false}
                                                >
                                                    {category.name}
                                                </option>
                                            )}
                                        </select>
                                    </Form.Group>
                                : ""}
                            </Col>
                            <Col md={4}>
                            <h5 className="label-about-your-job">Job Region</h5>
                                {props.regions.loading
                                    ? "Loading..."
                                : props.regions.data.regions.length > 0 ? 
                                    <Form.Group>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            onChange={(event) =>
                                            {
                                                setInputData({
                                                ...inputData,
                                                region_id: event.currentTarget.value,
                                                });
                                                props.dispatch(fetchEmployerCityStart({region_id: event.currentTarget.value}));
                                            }
                                            }
                                            name="region_id"
                                        >   
                                            <option value="" selected>Select Location</option>
                                            {props.regions.data.regions.map((region) => 
                                                <option 
                                                    key={region.region_id}
                                                    value={region.region_id}
                                                    selected={region.region_id == props.singleJob.data.company_job.region_id ? true : false}
                                                >
                                                    {region.name}
                                                </option>
                                            )}
                                        </select>
                                    </Form.Group>
                                : ""}
                            </Col>
                            <Col md={4}>
                                <h5 className="label-about-your-job">
                                    Job City
                                </h5>
                                {props.cities.loading
                                    ? 
                                        <Form.Group>
                                            <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            name="city_id"
                                            >   
                                                <option value="" selected>Select Location</option>
                                            </select>
                                        </Form.Group>
                                    : props.cities.data.cities.length > 0
                                    ? 
                                        <Form.Group>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                onChange={(event) =>
                                                    {
                                                    setInputData({
                                                        ...inputData,
                                                        city_id: event.currentTarget.value,
                                                    })
                                                    }
                                                }
                                                name="city_id"
                                            >   
                                            <option value="" selected>Select Location</option>
                                            {props.cities.data.cities.map((city) => 
                                                <option 
                                                    key={city.city_id}
                                                    value={city.city_id}
                                                    selected={city.city_id == props.singleJob.data.company_job.city_id ? true : false}
                                                >
                                                    {city.name}
                                                </option>
                                            )}
                                            </select>
                                        </Form.Group>
                                    : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group>
                                    <Form.Label>
                                        Job description
                                    </Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        rows={3}
                                        type="text"
                                        controlId="description"
                                        name="description"
                                        defaultValue={props.singleJob.data.company_job.description}
                                        onChange={(event) =>
                                            setInputData({
                                                ...inputData,
                                                description: event.currentTarget.value,
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <h5 className="label-about-your-job">
                                    Salary Type
                                </h5>
                                <ul className="list-unstyled about-your-job-checkbox">
                                    <Media as="li">
                                        <div className="inputGroup">
                                            <input 
                                                id="Monthly" 
                                                name="salary_type" 
                                                type="radio"
                                                defaultChecked={props.singleJob.data.company_job.salary_type == 'per month' ? true : false}
                                                value="per month"
                                                onChange={(event) => {
                                                    setInputData({
                                                        ...inputData,
                                                        salary_type: event.currentTarget.value,
                                                    })
                                                }}
                                            />
                                            <label for="Monthly">
                                                <span>Monthly</span>
                                            </label>
                                        </div>
                                    </Media>
                                    <Media as="li">
                                        <div className="inputGroup">
                                            <input 
                                                id="Anually" 
                                                name="salary_type" 
                                                type="radio"
                                                value="per year"
                                                defaultChecked={props.singleJob.data.company_job.salary_type == 'per year' ? true : false}
                                                onChange={(event) => {
                                                    setInputData({
                                                        ...inputData,
                                                        salary_type: event.currentTarget.value,
                                                    })
                                                }}
                                            />
                                            <label for="Anually">
                                                <span>Anually</span>
                                            </label>
                                        </div>
                                    </Media>
                                </ul>
                            </Col>
                            <Col md={4}>
                                <h5 className="label-about-your-job">
                                    Amount
                                </h5>
                                <Form.Group>
                                    <Form.Control 
                                        placeholder="Amount" 
                                        type="number"
                                        controlId="amount"
                                        name="amount"
                                        defaultValue={props.singleJob.data.company_job.amount}
                                        onChange={(event) => {
                                            setInputData({
                                                ...inputData,
                                                amount: event.currentTarget.value,
                                            })
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <h5 className="label-about-your-job">
                                    Accept applications 
                                </h5>
                                <ul className="list-unstyled about-your-job-checkbox">
                                    <Media as="li">
                                        <div className="inputGroup">
                                            <input 
                                                id="option3" 
                                                name="option3" 
                                                type="checkbox"
                                                checked={true}
                                            />
                                            <label for="option3">
                                                <span>Directly (via form)</span>
                                            </label>
                                        </div>
                                    </Media>
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-right">
                                <Button
                                    className="default-btn"
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            )}
        </Container>
    </>
    );
};



const mapStateToPros = (state) => ({
    categories:state.lookups.jobCategories,
    jobtypes:state.lookups.jobtypes,
    singleJob: state.jobs.singleJob,
    regions:state.lookups.regions,
    cities:state.lookups.cities,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(mapStateToPros, mapDispatchToProps)(JobEdit);
  