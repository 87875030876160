import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Nav, Tab, Form,Button } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import {
    fetchWishlistStart,
} from "../../../store/actions/UserJobAction";
import useInfiniteScroll from "../../helper/useInfiniteScroll";

const WishList = (props) => {

    useEffect(() => {
        props.dispatch(fetchWishlistStart());
    }, []);

    const fetchWishlistData = () => {
        setTimeout(() => {
          if (props.wishlist.length !== 0) {
            props.dispatch(fetchWishlistStart({
                skip:props.wishlist.skip,
            }));
            setIsFetching(false);
          } else {
            setNoMoreData(true);
          }
        }, 3000);
    };

    const [isFetching, setIsFetching] = useInfiniteScroll(fetchWishlistData);

    const [noMoreData, setNoMoreData] = useState(false);

    return (
    <>
        <h3>My saved jobs</h3>
        <div className="my-job-application-sec">
            {/* <div className="my-job-application-header">
                <div className="my-job-application-select">
                    <Row className="align-items-center">
                        <Col md={4}>
                            <p className="total-count-jobs">6 jobs</p>
                        </Col>
                    </Row>
                </div>sdssd
            </div> */}
            <div className="my-job-application-body">
                {props.wishlist.loading ? (
                    "Loading"
                ) : props.wishlist.data.wishlists.length > 0 ? (
                    props.wishlist.data.wishlists.map((wishlist) => (
                        <div className="my-job-application-card" key={wishlist.wishlist_id}>
                            <div className="my-job-application-info">
                                <h4 className="name">{wishlist.company_job.name}</h4>
                                <p className="site-url">{wishlist.company_job.company_name}</p>
                                <div className="meta">
                                    <p className="applications">{wishlist.company_job.service_location_name}</p>
                                </div>
                                <div className="my-jobs-buttons">
                                    <Link to={"/job/" + wishlist.company_job.company_job_unique_id} target="_blank" className="view-job-btn">View job</Link>
                                </div>
                            </div>
                            <div className="my-job-application-applied-date">
                                <p className="created-at">{wishlist.created}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    "No Data found"
                )}
            </div>
            {noMoreData !== true ? (
                <>{isFetching && "Fetching more items..."}</>
            ) : (
                "No More Data"
            )}
        </div>
    </>
    );
};

const mapStateToPros = (state) => ({
    wishlist: state.userjobs.wishlist,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(WishList));
