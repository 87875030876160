import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import PageReducer from "./PageReducer";
import UserJobReducer from "./UserJobReducer";
import EmployerReducer from "./EmployerReducer";
import DashboardReducer from "./DashboardReducer";
import JobReducer from "./JobReducer";
import ApplicationReducer from "./ApplicationReducer";
import SubscriptionReducer from "./SubscriptionReducer";
import CardsReducer from "./CardsReducer";
import LookupReducer from "./LookupReducer";
import CompanySubscriptionReducer from "./CompanySubscriptionReducer";

export default combineReducers({
  users: UserReducer,
  page: PageReducer,
  userjobs: UserJobReducer,
  employers: EmployerReducer,
  dashbord: DashboardReducer,
  jobs: JobReducer,
  applications: ApplicationReducer,
  subscriptions: SubscriptionReducer,
  cards: CardsReducer,
  lookups: LookupReducer,
  companySubscription:CompanySubscriptionReducer,
});
