import {
    FETCH_COMPANY_SUBSCRIPTION_START,
    FETCH_COMPANY_SUBSCRIPTION_SUCCESS,
    FETCH_COMPANY_SUBSCRIPTION_FAILURE,
    FETCH_MY_COMPANY_SUBSCRIPTION_START,
    FETCH_MY_COMPANY_SUBSCRIPTION_SUCCESS,
    FETCH_MY_COMPANY_SUBSCRIPTION_FAILURE,
    COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_START,
    COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_SUCCESS,
    COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_FAILURE,
  } from "./ActionConstant";
  
  // Get subscription actions.
  
  export function fetchCompanySubscriptionStart(data) {
    return {
      type: FETCH_COMPANY_SUBSCRIPTION_START,
      data,
    };
  }
  
  export function fetchCompanySubscriptionSuccess(data) {
    return {
      type: FETCH_COMPANY_SUBSCRIPTION_SUCCESS,
      data,
    };
  }
  
  export function fetchCompanySubscriptionFailure(error) {
    return {
      type: FETCH_COMPANY_SUBSCRIPTION_FAILURE,
      error,
    };
  }
  
  // Get My subscription actions.
  
  export function fetchMyCompanySubscriptionStart(data) {
    return {
      type: FETCH_MY_COMPANY_SUBSCRIPTION_START,
      data,
    };
  }
  
  export function fetchMyCompanySubscriptionSuccess(data) {
    return {
      type: FETCH_MY_COMPANY_SUBSCRIPTION_SUCCESS,
      data,
    };
  }
  
  export function fetchMyCompanySubscriptionFailure(error) {
    return {
      type: FETCH_MY_COMPANY_SUBSCRIPTION_FAILURE,
      error,
    };
  }
  
  
  // Subscription Payment actions.
  
  export function companySubscriptionPaymentStart(data) {
    return {
      type: COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_START,
      data,
    };
  }
  
  export function companySubscriptionPaymentSuccess(data) {
    return {
      type: COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_SUCCESS,
      data,
    };
  }
  
  export function companySubscriptionPaymentFailure(error) {
    return {
      type: COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_FAILURE,
      error,
    };
  }