import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media, Form} from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import {
    fetchJobCityStart
} from "../../store/actions/UserJobAction";
const FilterIndex = (props) => {
    const [rangeValue, setRangeValue] = useState({min: 0, max: 0 });

    const setSearchPriceRange = (value) => {
        setRangeValue({
            ...rangeValue,
            min: value.value.min,
            max: value.value.max,
        });
        props.getSearchPriceRange(value.value.min,value.value.max);
    };

    useEffect(() => {
        console.log(props.salaryFilter.data);
        setRangeValue({
            ...rangeValue,
            min: 0,
            max: props.salaryFilter.data.max_salary,
        });
    }, [props.salaryFilter]);

    return (
        <div className="sticky-box">
            <Form>
                <div className="filter-search-sec">
                    <h5>Quick Search</h5>
                    <Form.Group className="mb-0">
                        <Form.Control
                            type="text"
                            name="search_key"
                            placeholder="Company or job title"
                            onChange={(event) =>
                                props.getSearchData(event)
                            }
                        />
                    </Form.Group>
                </div>
                <div className="filter-search-sec">
                    <h5>Locations</h5>
                    {props.regions.loading
                        ? "Loading..."
                        : props.regions.data.regions.length > 0
                        ? 
                        <Form.Group className="mb-2">
                            <select
                                className="form-select form-control"
                                aria-label="Default select example"
                                onChange={(event) =>
                                    {
                                        props.getSearchData(event);
                                        props.dispatch(fetchJobCityStart({region_unique_id:event.currentTarget.value}));
                                    }
                                }
                                name="region"
                            >   
                            <option value="" selected>Select Region</option>
                            {props.regions.data.regions.map((region) => 
                                <option 
                                    key={region.region_id}
                                    value={region.region_unique_id}
                                    selected={region.is_selected ? true : false}
                                >
                                    {region.name}
                                </option>
                            )}
                            </select>
                        </Form.Group>
                    : ""}
                    {props.cities.loading
                        ? 
                            <Form.Group className="mb-2">
                                <select
                                    className="form-select form-control"
                                    aria-label="Default select example"
                                    name="city_id"
                                >   
                                    <option value="" selected>Select City</option>
                                </select>
                            </Form.Group>
                        : 
                        <Form.Group className="mb-2">
                            <select
                                className="form-select form-control"
                                aria-label="Default select example"
                                onChange={(event) =>
                                    props.getSearchData(event)
                                }
                                name="city"
                            >   
                            <option value="" selected>Select City</option>

                            {props.cities.data.cities.length > 0
                            ? props.cities.data.cities.map((city) => 
                                <option 
                                    key={city.city_id}
                                    value={city.city_unique_id}
                                    selected={city.is_selected ? true : false}
                                >
                                    {city.name}
                                </option>
                            ) :''}
                            </select>
                        </Form.Group>
                    }
                </div>
                <div className="filter-search-sec">
                    <h5>Sectors</h5>
                    {props.categories.loading
                        ? "Loading..."
                        : props.categories.data.categories.length > 0
                        ? 
                        <Form.Group className="mb-0">
                            <select
                                className="form-select form-control"
                                aria-label="Default select example"
                                onChange={(event) =>
                                    props.getSearchData(event)
                                }
                                name="sector"
                            >
                                <option value="" selected>All Sectors</option>
                                {props.categories.data.categories.map((category) => 
                                    <option 
                                        key={category.category_id}
                                        value={category.category_unique_id}
                                        selected={category.is_selected ? true : false}
                                    >
                                        {category.name}
                                    </option>
                                )}
                            </select>
                        </Form.Group>
                    : ""}
                </div>
                <div className="filter-search-sec">
                    <h5>Job Types</h5>
                    {props.jobtypes.loading
                        ? "Loading..."
                        : props.jobtypes.data.job_types.length > 0
                        ? 
                        <Form.Group className="mb-0">
                            <select
                                className="form-select form-control"
                                aria-label="Default select example"
                                onChange={(event) =>
                                    props.getSearchData(event)
                                }
                                name="job_type"
                            >
                                <option value="" selected>All Job Types</option>
                                {props.jobtypes.data.job_types.map((jobtype) => 
                                    <option 
                                        key={jobtype.job_type_id}
                                        value={jobtype.job_type_unique_id}
                                        selected={jobtype.is_selected ? true : false}
                                    >
                                        {jobtype.name}
                                    </option>
                                )}
                            </select>
                        </Form.Group>
                    : ""}
                </div> 

                <div className="filter-search-sec">
                    <h5>Salary</h5>
                    {props.salaryFilter.loading
                        ? "Loading..."
                        : props.salaryFilter.data.salary_filter.length > 0
                        ? 
                        <Form.Group className="mb-3 mt-4">
                            <InputRange
                                maxValue={props.salaryFilter.data.max_salary}
                                minValue={0}
                                value={rangeValue}
                                onChange={value => setSearchPriceRange({ value })}
                            />
                        </Form.Group>
                    : ""}
                </div>     
                <div className="filter-search-sec">
                    <h5>Salary</h5>
                    {props.salaryFilter.loading
                        ? "Loading..."
                        : props.salaryFilter.data.salary_filter.length > 0
                        ? 
                        <Form.Group className="mb-0">
                            {props.salaryFilter.data.salary_filter.map((salary) =>
                                <Form.Check 
                                    type="radio"
                                    id="default-checkbox"
                                    label={salary.label}
                                    name="salary_range"
                                    value={salary.to}
                                    onChange={(event) =>
                                        props.getSearchPriceRange(salary.from,salary.to)
                                    }
                                    className="form-check-label-1"
                                />
                            )}                        
                        </Form.Group>
                    : ""}
                </div>                    
            </Form>
        </div>
    );
};
const mapStateToPros = (state) => ({
    companies: state.userjobs.companies,
    categories: state.userjobs.jobCategories,
    locations: state.userjobs.locations,
    cities: state.userjobs.cities,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(FilterIndex));
