import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col } from "react-bootstrap";
import { translate, t } from "react-multi-lang";

const Dashboard = (props) => {

    return (
        <>
            <h3>Dashboard</h3>
            <div className="emp-profile-dashboard-box">
                <div className="emp-profile-dashboard-card">
                    <h5>Jobs posted</h5>
                    <p>{props.profile.data.total_jobs}</p>
                </div>
                <div className="emp-profile-dashboard-card">
                    <h5>Applicants</h5>
                    <p>{props.profile.data.total_job_aplicant}</p>
                </div>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({

});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Dashboard));
