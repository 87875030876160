import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { FETCH_EMPLOYER_DASHBOARD_START } from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";
import {
    fetchEmployerDashboardSuccess,
    fetchEmployerDashboardFailure,
} from "../actions/DashboardAction";

function* fetchDashboardAPI(action) {
  try {
    const response = yield api.postMethod(
      "static_pages_view", action.data
    );
    if (response.data.success) {
      yield put(fetchEmployerDashboardSuccess(response.data.data));
    } else {
      yield put(fetchEmployerDashboardFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchEmployerDashboardFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_EMPLOYER_DASHBOARD_START, fetchDashboardAPI)]);
}
