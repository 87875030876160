import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col } from "react-bootstrap";
import { userChangePasswordStart } from "../../../store/actions/UserAction";
import { translate, t } from "react-multi-lang";

const ChangePassword = (props) => {
    const [inputData, setInputData] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(userChangePasswordStart(inputData));
    };

    return (
        <>
            <h3>Change Password</h3>
            <Form 
                className="profile-form-sec auth-form"
                onSubmit={handleSubmit}
                id="form"
                method="post"
                name="form"
                autoComplete="off"
            >
                <Row>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control 
                                placeholder="Enter your old password"
                                type="password"
                                controlId="old_password"
                                name="old_password"
                                value={inputData.old_password}
                                onChange={(event) =>
                                    setInputData({
                                        ...inputData,
                                        old_password: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>New Password</Form.Label>
                            <Form.Control 
                                placeholder="Enter your new password"
                                id="password"
                                type="password"
                                controlId="password"
                                name="password"
                                value={inputData.password}
                                onChange={(event) =>
                                    setInputData({
                                        ...inputData,
                                        password: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control 
                                placeholder="Confirm Password"
                                id="password_confirmation"
                                type="password"
                                controlId="Email"
                                name="password_confirmation"
                                value={inputData.password_confirmation}
                                onChange={(event) =>
                                    setInputData({
                                        ...inputData,
                                        password_confirmation: event.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="text-right">
                        <Button
                            className="default-btn"
                            onClick={handleSubmit}
                            disabled={props.changePassword.buttonDisable}
                            >
                            {props.changePassword.loadingButtonContent 
                                ? props.changePassword.loadingButtonContent
                                : "Change Password"}
                        </Button>
                    </Col>
                </Row>
            </Form>           
        </>
    );
};

const mapStateToPros = (state) => ({
  changePassword: state.users.changePasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(ChangePassword));
