import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../../components/helper/ToastNotification";

const Step3 = (props) => {
  
  console.log(props.job.data);

  return (
    <>

    <div className="preview-listing-sec">
        <p className="separator">Preview listing</p>
        <div className="preview-listing-box">
          {props.job.loading ? '' : 
          <Link to={"/employers-job-view/" + props.job.data.company_job_unique_id}>
            <div className="preview-listing-card">
                <Image
                  src={props.job.data.company.company_logo}
                  alt="Image"
                  className="preview-listing-logo"
                />
                <div className="preview-listing-info">
                  <h4 className="position">{props.job.data.name}</h4>
                  <h6 className="company">{props.job.data.company_name}</h6>
                  <div className="meta">
                    <p className="location">
                      <span className="city">{props.job.data.city_name},{props.job.data.region_name}</span>
                      {props.job.data.job_type ?
                        <span className="remote">{props.job.data.job_type_name}</span>
                      : ''}
                    </p>
                    <p className="type">Full-time</p>
                  </div>
                  {/* <div className="tags">
                    <span className="tag">Card present</span>
                    <span className="tag">eCommerce</span>
                  </div> */}
                </div>
            </div>
          </Link>
          }
          {/* <div className="preview-listing-desc">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
          </div> */}
        </div>
    </div>
      
    </>
  );
};

const mapStateToPros = (state) => ({
  job:state.jobs.addJob,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Step3));
