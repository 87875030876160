  import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Media,
  Form,
  Badge,
} from "react-bootstrap";
import { connect } from "react-redux";
import { fetchJobStart,deleteJobStart,publishJobStart } from "../../store/actions/JobAction";
import useInfiniteScroll from "../helper/useInfiniteScroll";

const EmployersListJobs = (props) => {

  const [searchKey, setSearchkey] = useState('');

  useEffect(() => {
    props.dispatch(fetchJobStart({loadMore:''}));
  }, []);

  const fetchJobData = () => {
    setTimeout(() => {
      if (props.jobs.length !== 0) {
        props.dispatch(fetchJobStart({
            skip:props.jobs.skip,
            loadMore:'loadMore',
            search_key:searchKey,
        }));
        setIsFetching(false);
      } else {
        setNoMoreData(true);
      }
    }, 3000);
  };
  
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchJobData);

  const [noMoreData, setNoMoreData] = useState(false);

  const handleDelete = (event, company_job_id) => {
    event.preventDefault();
    props.dispatch(deleteJobStart({ company_job_id: company_job_id }));
  };

  const handlePublish = (event, company_job_id) => {
    event.preventDefault();
    props.dispatch(publishJobStart({ company_job_id: company_job_id }));
  };

  const handleSearch = (event, search_key) => {
    event.preventDefault();
    setSearchkey(search_key);
    props.dispatch(fetchJobStart({search_key:search_key,loadMore:''}));
  };

  return (
    <>
      <div className="home-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="home-banner-card">
                <div className="home-banner-info">
                  <h3>List Jobs</h3>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="margin-top-lg">
            <Col md={12}>
              {/* <div className="job-filter-header-sec">
                <h3 className="section-title">
                  Nice Board Jobs
                  <span className="count">1303</span>{" "}
                </h3>
                <p className="pagination-info">Page 1 of 66</p>
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="sticky-box">
                <Form>
                  <div className="filter-search-sec">
                    <h5>Quick Search</h5>
                    <Form.Group className="mb-0">
                      <Form.Control
                        type="text"
                        placeholder="Company or job title"
                        name="search_key"
                        onChange={(event) =>
                            event.currentTarget.value &&
                            event.currentTarget.value.length > 3
                                ? handleSearch(
                                    event,
                                    event.currentTarget.value
                                )
                            : ""
                        }
                      />
                    </Form.Group>
                  </div>
                  {/* <div className="filter-search-sec">
                    <h5>Location</h5>
                    <Form.Group className="mb-0">
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                      >
                        <option selected>All locations</option>
                        <option value="1">USA</option>
                        <option value="2">UK</option>
                        <option value="3">UAE</option>
                      </select>
                    </Form.Group>
                    <Form.Group className="mb-0">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Remote"
                      />
                    </Form.Group>
                  </div>
                  <div className="filter-search-sec">
                    <h5>Niceboard Category</h5>
                    <Form.Group className="mb-0">
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                      >
                        <option selected>All categories</option>
                        <option value="1">USA</option>
                        <option value="2">UK</option>
                        <option value="3">UAE</option>
                      </select>
                    </Form.Group>
                  </div>
                  <div className="filter-search-sec">
                    <h5>Job Function</h5>
                    <Form.Group className="mb-0">
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                      >
                        <option selected>All function</option>
                        <option value="1">USA</option>
                        <option value="2">UK</option>
                        <option value="3">UAE</option>
                      </select>
                    </Form.Group>
                  </div>
                  <div className="filter-search-sec">
                    <h5>Job Type</h5>
                    <Form.Group className="mb-0">
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                      >
                        <option selected>All jobs</option>
                        <option value="1">USA</option>
                        <option value="2">UK</option>
                        <option value="3">UAE</option>
                      </select>
                    </Form.Group>
                  </div> */}
                </Form>
              </div>
            </Col>
            <Col md={9}>
              <div className="job-list-sec">
                <ul className="list-unstyled">
                  {props.jobs.loading ? (
                    "Loading"
                  ) : props.jobs.data.jobs.length > 0 ? (
                      <>
                        {props.jobs.data.jobs.map((job) => (
                          <Media as="li">
                            <Link to={"/employers-job-view/" + job.company_job_unique_id} target="_blank">
                              <div className="job-list-card">
                                <div className="job-list-content">
                                  <Image
                                    src={job.company.company_logo}
                                    alt="Image"
                                    className="job-list-logo"
                                  />
                                  <div className="job-list-info">
                                    <h4 className="position">{job.name}</h4>
                                    <h6 className="company">{job.company.company_name}</h6>
                                    <div className="meta">
                                      <p className="location">
                                        <span className="city">{job.city_name},{job.region_name}</span>
                                        {job.job_type ?
                                          <span className="remote">{job.job_type.name}</span>
                                        : ''}
                                      </p>
                                      <p className="type">Full-time</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="job-list-action-sec">
                                  <div className="d-flex">
                                    {job.publish_status ? 
                                      <Badge pill bg="success" className="badge-green">Published</Badge>
                                    :""}
                                    <p className="time-ago">{job.created}</p>
                                  </div>
                                  <div className="d-flex">
                                    <Link className="edit-btn mr-2" to={"/job-edit/" + job.company_job_unique_id}></Link>
                                    <Button className="delete-btn" onClick={(event) => handleDelete(event,job.company_job_id)}></Button>
                                    {job.publish_status == 0 ? 
                                      <Button className="publish-btn" onClick={(event) => handlePublish(event,job.company_job_id)}></Button>
                                    :""}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </Media>
                        ))}
                      </>
                  ) : null}
                </ul>
              </div>  
              {noMoreData !== true ? (
                <>{isFetching && "Fetching more items..."}</>
              ) : (
                "No More Data"
              )}            
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  jobs: state.jobs.job,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EmployersListJobs);
