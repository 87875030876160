import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Nav, Tab, Form,Button } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import UpdateProfile from "./UpdateProfile";
import Wishlist from "./Wishlist";
import AppliedJobs from "./AppliedJobs";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";

const ProfileIndex = (props) => {

    return (
    <>
      <div className="profile-sec">
        <Container>
            <div className="profile-header-sec">
                <h3>Job seeker Dashboard</h3>
                <p>Manage your job seeker profile.</p>
            </div>
            <div className="profile-tab-sec">
                <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="profile">Profile</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/user/applied-jobs" eventKey="application">Application</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#" eventKey="wishlist">Wishlist</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#" eventKey="change-password">Change Password</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#" eventKey="delete-account">Delete Account</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="profile">
                                <UpdateProfile />
                            </Tab.Pane>
                            <Tab.Pane eventKey="application">
                                <AppliedJobs />
                            </Tab.Pane>
                            <Tab.Pane eventKey="wishlist">
                                <Wishlist />
                            </Tab.Pane>
                            <Tab.Pane eventKey="change-password">
                                <ChangePassword />
                            </Tab.Pane>
                            <Tab.Pane eventKey="delete-account">
                                <DeleteAccount />
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({

});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(ProfileIndex));
