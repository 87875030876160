import {
  FETCH_WISHLIST_START,
  FETCH_WISHLIST_SUCCESS,
  FETCH_WISHLIST_FAILURE,
  SAVE_WISHLIST_START,
  SAVE_WISHLIST_SUCCESS,
  SAVE_WISHLIST_FAILURE,
  CLEAR_WISHLIST_START,
  CLEAR_WISHLIST_SUCCESS,
  CLEAR_WISHLIST_FAILURE,
  FETCH_JOBS_START,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_FAILURE,
  FETCH_SINGLE_JOB_START,
  FETCH_SINGLE_JOB_SUCCESS,
  FETCH_SINGLE_JOB_FAILURE,
  FETCH_JOB_TYPE_LIST_START,
  FETCH_JOB_TYPE_LIST_SUCCESS,
  FETCH_JOB_TYPE_LIST_FAILURE,
  FETCH_JOB_CATEGORY_LIST_START,
  FETCH_JOB_CATEGORY_LIST_SUCCESS,
  FETCH_JOB_CATEGORY_LIST_FAILURE,
  USER_JOB_APPLY_START,
  USER_JOB_APPLY_SUCCESS,
  USER_JOB_APPLY_FAILURE,
  FETCH_JOB_COMPANY_LIST_START,
  FETCH_JOB_COMPANY_LIST_SUCCESS,
  FETCH_JOB_COMPANY_LIST_FAILURE,
  FETCH_SERVICE_LOCATION_START,
  FETCH_SERVICE_LOCATION_SUCCESS,
  FETCH_SERVICE_LOCATION_FAILURE,
  LOAD_MORE_JOBS_START,
  LOAD_MORE_JOBS_SUCCESS,
  LOAD_MORE_JOBS_FAILURE,
  FETCH_JOB_APPLIED_LIST_START,
  FETCH_JOB_APPLIED_LIST_SUCCESS,
  FETCH_JOB_APPLIED_LIST_FAILURE,
  FETCH_SALARY_FILTER_START,
  FETCH_SALARY_FILTER_SUCCESS,
  FETCH_SALARY_FILTER_FAILURE,
  FETCH_JOB_CITY_START,
  FETCH_JOB_CITY_SUCCESS,
  FETCH_JOB_CITY_FAILURE,
  FETCH_JOB_REGION_START,
  FETCH_JOB_REGION_SUCCESS,
  FETCH_JOB_REGION_FAILURE,
  USER_JOB_ALERT_SAVE_START,
  USER_JOB_ALERT_SAVE_SUCCESS,
  USER_JOB_ALERT_SAVE_FAILURE,
  FETCH_COMPANY_VIEW_START,
  FETCH_COMPANY_VIEW_SUCCESS,
  FETCH_COMPANY_VIEW_FAILURE,
  FETCH_SUBSCRIBED_COMPANY_START,
  FETCH_SUBSCRIBED_COMPANY_SUCCESS,
  FETCH_SUBSCRIBED_COMPANY_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  wishlist: {
    data: {
      wishlists: [],
    },
    inputData: {},
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  jobs: {
    data: [],
    inputData: {},
    loading: true,
    error: false,
  },
  singleJob: {
    data: {},
    inputData: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  saveWishlist: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  clearWishlist: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  jobCategories: {
    data: {
      categories: [],
    },
    inputData: {},
    loading: true,
    error: false,
    skip: 0,
    length: 0,
    loadMore:false,
  },
  jobtypes: {
    data: {},
    inputData: {},
    loading: true,
    error: false,
  },
  applyJob: {
    data: {},
    inputData: {},
    loading: true,
    error: false,
  },
  companies: {
    data: {
      companies: [],
    },
    inputData: {},
    loading: true,
    error: false,
    skip: 0,
    length: 0,
    loadMore: false,
  },
  locations: {
    data: {
      locations: [],
    },
    inputData: {},
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  appliedJobs: {
    data: {
      jobs: [],
    },
    inputData: {},
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  cities: {
    data: {},
    inputData: {},
    loading: true,
    error: false,
  },
  regions: {
    data: {},
    inputData: {},
    loading: true,
    error: false,
  },
  salaryFilter: {
    data: {},
    inputData: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  jobAlertSave: {
    data: {},
    inputData: {},
    loading: true,
    error: false,
  },
  singleCompany: {
    data: {},
    inputData: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  subscribedCompanies: {
    data: {
      companies: [],
    },
    inputData: {},
    loading: true,
    error: false,
    skip: 0,
    length: 0,
    loadMore: false,
  },
};

const UserJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WISHLIST_START:
      return {
        ...state,
        wishlist: {
          inputData: action.data,
          loading: true,
          error: false,
          data: {
            wishlists: [...state.wishlist.data.wishlists],
          },
          skip: state.wishlist.skip,
          length: state.wishlist.length,
        },
      };
    case FETCH_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: {
          loading: false,
          error: false,
          inputData: action.data,
          data: {
            wishlists: [
              ...state.wishlist.data.wishlists,
              ...action.data.wishlists,
            ],
          },
          loading: false,
          error: false,
          skip: action.data.wishlists.length + state.wishlist.skip,
          length: action.data.wishlists.length,
        },
      };
    case FETCH_WISHLIST_FAILURE:
      return {
        ...state,
        wishlist: {
          inputData: action.data,
          data: {
            wishlists: [],
          },
          loading: false,
          error: action.error,
          skip: state.wishlist.skip,
          length: state.wishlist.length,
        },
      };
    case SAVE_WISHLIST_START:
      return {
        ...state,
        saveBookmark: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case SAVE_WISHLIST_SUCCESS:
      return {
        ...state,
        saveWishlist: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SAVE_WISHLIST_FAILURE:
      return {
        ...state,
        saveWishlist: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case CLEAR_WISHLIST_START:
      return {
        ...state,
        clearWishlist: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading...",
          buttonDisable: true,
        },
      };
    case CLEAR_WISHLIST_SUCCESS:
      return {
        ...state,
        clearWishlist: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case CLEAR_WISHLIST_FAILURE:
      return {
        ...state,
        clearWishlist: {
          data: {},
          loading: true,
          error: action.data,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_JOBS_START:
      return {
        ...state,
        jobs: {
          data: [],
          loading: true,
          error: false,
        },
      };
    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        jobs: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_JOBS_FAILURE:
      return {
        ...state,
        jobs: {
          data: [],
          loading: true,
          error: action.error,
        },
      };
    case FETCH_SINGLE_JOB_START:
      return {
        ...state,
        singleJob: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait",
          buttonDisable: true,
        },
      };
    case FETCH_SINGLE_JOB_SUCCESS:
      return {
        ...state,
        singleJob: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_SINGLE_JOB_FAILURE:
      return {
        ...state,
        singleJob: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_JOB_TYPE_LIST_START:
      return {
        ...state,
        jobtypes: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_JOB_TYPE_LIST_SUCCESS:
      return {
        ...state,
        jobtypes: {
          inputData: action.data,
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_JOB_TYPE_LIST_FAILURE:
      return {
        ...state,
        jobtypes: {
          inputData: action.data,
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_JOB_CATEGORY_LIST_START:
      return {
        ...state,
        jobCategories: {
          inputData: action.data,
          loading: true,
          error: false,
          data: {
            categories: action.data.loadMore === "loadMore"
            ? [...state.jobCategories.data.categories] : '',
          },
          loadMore: action.data.loadMore === "loadMore" ? true : false,
          skip: action.data.loadMore === "loadMore" ? state.jobCategories.skip : 0,
          length:action.data.loadMore === "loadMore" ? state.jobCategories.length : 0,
        },
      };
    case FETCH_JOB_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        jobCategories: {
          inputData: action.data,
          data: {
            categories: [
              ...state.jobCategories.data.categories,
              ...action.data.categories,
            ],
          },
          loading: false,
          error: false,
          loadMore: state.jobCategories.loadMore,
          skip: action.data.categories.length + state.jobCategories.skip,
          length: action.data.categories.length,
        },
      };
    case FETCH_JOB_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        jobCategories: {
          inputData: action.data,
          data: {
            categories: [],
          },
          loading: false,
          error: action.error,
          skip: state.categories.skip,
          length: state.categories.length,
        },
      };
    case FETCH_JOB_COMPANY_LIST_START:
      return {
        ...state,
        companies: {
          inputData: action.data,
          data: {
            companies:
              action.data.loadMore === "loadMore"
                ? [...state.companies.data.companies]
                : [],
          },
          loadMore: action.data.loadMore === "loadMore" ? true : false,
          loading: true,
          error: false,
          skip: action.data.loadMore === "loadMore" ? state.companies.skip : 0,
          length:
            action.data.loadMore === "loadMore" ? state.companies.length : 0,
        },
      };
    case FETCH_JOB_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        companies: {
          inputData: action.data,
          data: {
            companies: [
              ...state.companies.data.companies,
              ...action.data.companies,
            ],
          },
          loadMore: state.companies.loadMore,
          loading: false,
          error: false,
          skip: action.data.companies.length + state.companies.skip,
          length: action.data.companies.length,
        },
      };
    case FETCH_JOB_COMPANY_LIST_FAILURE:
      return {
        ...state,
        companies: {
          inputData: action.data,
          data: {
            companies: [],
          },
          loading: false,
          error: action.error,
          skip: state.companies.skip,
          length: state.companies.length,
        },
      };
    case USER_JOB_APPLY_START:
      return {
        ...state,
        applyJob: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case USER_JOB_APPLY_SUCCESS:
      return {
        ...state,
        applyJob: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case USER_JOB_APPLY_FAILURE:
      return {
        ...state,
        applyJob: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_SERVICE_LOCATION_START:
      return {
        ...state,
        locations: {
          inputData: action.data,
          data: {
            locations: [...state.locations.data.locations],
          },
          loading: true,
          error: false,
          skip: state.locations.skip,
          length: state.locations.length,
        },
      };
    case FETCH_SERVICE_LOCATION_SUCCESS:
      return {
        ...state,
        locations: {
          inputData: action.data,
          data: {
            locations: [
              ...state.locations.data.locations,
              ...action.data.service_locations,
            ],
          },
          loading: false,
          error: false,
          skip: action.data.service_locations.length + state.locations.skip,
          length: action.data.service_locations.length,
        },
      };
    case FETCH_SERVICE_LOCATION_FAILURE:
      return {
        ...state,
        locations: {
          inputData: action.data,
          data: {
            locations: [],
          },
          loading: false,
          error: action.error,
          skip: state.locations.skip,
          length: state.locations.length,
        },
      };

    case LOAD_MORE_JOBS_START:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          inputData: action.data,
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_JOBS_SUCCESS:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          data: {
            ...state.jobs.data,
            jobs_list: [
              ...state.jobs.data.jobs_list,
              ...action.data.jobs_list,
            ],
          },
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_JOBS_FAILURE:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          loading: false,
          error: action.error,
        },
      };
    case FETCH_JOB_APPLIED_LIST_START:
      return {
        ...state,
        appliedJobs: {
          inputData: action.data,
          loading: true,
          error: false,
          data: {
            jobs: [...state.appliedJobs.data.jobs],
          },
          skip: state.appliedJobs.skip,
          length: state.appliedJobs.length,
        },
      };
    case FETCH_JOB_APPLIED_LIST_SUCCESS:
      return {
        ...state,
        appliedJobs: {
          loading: false,
          error: false,
          inputData: action.data,
          data: {
            jobs: [
              ...state.appliedJobs.data.jobs,
              ...action.data.job_applicants,
            ],
          },
          loading: false,
          error: false,
          skip: action.data.job_applicants.length + state.appliedJobs.skip,
          length: action.data.job_applicants.length,
        },
      };
    case FETCH_JOB_APPLIED_LIST_FAILURE:
      return {
        ...state,
        appliedJobs: {
          inputData: action.data,
          data: {
            jobs: [],
          },
          loading: false,
          error: action.error,
          skip: state.appliedJobs.skip,
          length: state.appliedJobs.length,
        },
      };
    case FETCH_SALARY_FILTER_START:
      return {
        ...state,
        salaryFilter: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait",
          buttonDisable: true,
        },
      };
    case FETCH_SALARY_FILTER_SUCCESS:
      return {
        ...state,
        salaryFilter: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_SALARY_FILTER_FAILURE:
      return {
        ...state,
        salaryFilter: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_JOB_CITY_START:
      return {
        ...state,
        cities: {
          inputData: action.data,
          loading: true,
          error: false,
          data: {},
        },
      };
    case FETCH_JOB_CITY_SUCCESS:
      return {
        ...state,
        cities: {
          inputData: action.data,
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_JOB_CITY_FAILURE:
      return {
        ...state,
        cities: {
          inputData: action.data,
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_JOB_REGION_START:
      return {
        ...state,
        regions: {
          inputData: action.data,
          loading: true,
          error: false,
          data: {},
        },
      };
    case FETCH_JOB_REGION_SUCCESS:
      return {
        ...state,
        regions: {
          inputData: action.data,
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_JOB_REGION_FAILURE:
      return {
        ...state,
        regions: {
          inputData: action.data,
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case USER_JOB_ALERT_SAVE_START:
      return {
        ...state,
        jobAlertSave: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case USER_JOB_ALERT_SAVE_SUCCESS:
      return {
        ...state,
        jobAlertSave: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case USER_JOB_ALERT_SAVE_FAILURE:
      return {
        ...state,
        jobAlertSave: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_COMPANY_VIEW_START:
      return {
        ...state,
        singleCompany: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait",
          buttonDisable: true,
        },
      };
    case FETCH_COMPANY_VIEW_SUCCESS:
      return {
        ...state,
        singleCompany: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_COMPANY_VIEW_FAILURE:
      return {
        ...state,
        singleCompany: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_SUBSCRIBED_COMPANY_START:
      return {
        ...state,
        subscribedCompanies: {
          inputData: action.data,
          data: {
            companies:
              action.data.loadMore === "loadMore"
                ? [...state.subscribedCompanies.data.companies]
                : [],
          },
          loadMore: action.data.loadMore === "loadMore" ? true : false,
          loading: true,
          error: false,
          skip: action.data.loadMore === "loadMore" ? state.subscribedCompanies.skip : 0,
          length:
            action.data.loadMore === "loadMore" ? state.subscribedCompanies.length : 0,
        },
      };
      case FETCH_SUBSCRIBED_COMPANY_SUCCESS:
        return {
          ...state,
          subscribedCompanies: {
            inputData: action.data,
            data: {
              companies: [
                ...state.subscribedCompanies.data.companies,
                ...action.data.companies,
              ],
            },
            loadMore: state.subscribedCompanies.loadMore,
            loading: false,
            error: false,
            skip: action.data.companies.length + state.subscribedCompanies.skip,
            length: action.data.companies.length,
          },
        };
      case FETCH_SUBSCRIBED_COMPANY_FAILURE:
        return {
          ...state,
          subscribedCompanies: {
            inputData: action.data,
            data: {
              companies: [],
            },
            loading: false,
            error: action.error,
            skip: state.subscribedCompanies.skip,
            length: state.subscribedCompanies.length,
          },
        };
    default:
      return state;
  }
};

export default UserJobReducer;
