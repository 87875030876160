import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";

const InfiniteScroll = (props) => {
  const { loaderComponent } = props;

  const [isFetching, setIsFetching] = useState(false);

  const [initialRender, seTinitialRender] = useState(false);

  const [loadMore, setLoadMore] = useState(props.skip != props.total);

  const initalRenderRef = useRef();

  initalRenderRef.current = initialRender;

  const infiniteRef = useRef();

  function offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}


  const setScrollHeight = () => {
    const targetHeight =
      document.getElementById("InfiniteScroller").clientHeight;
    const difference = document.scrollingElement.scrollHeight - targetHeight;
    const offsetHeight = props.useOffset ? props.useOffset : 300

    // dont delete this is for reference
    // window.scrollY + window.innerHeight > (document.scrollingElement.scrollHeight - 200) for full page scroll length
    //window.scrollY + window.innerHeight - difference > targetHeight - 200 for element target height based

    var divOffset = offset( document.getElementById("InfiniteScroller"))

    if (window.scrollY - parseInt(divOffset.top) + offsetHeight > targetHeight - offsetHeight) {
      if (initalRenderRef) {
        if (props.skippedFetch) {
          if (loadMore) {
            setIsFetching(true);
          }
        } else {
          setIsFetching(true);
        }
      }
    }
  };


  useEffect(() => {
    seTinitialRender(true);

    window.addEventListener("scroll", setScrollHeight);

    return () => {
      window.removeEventListener("scroll", setScrollHeight);
    };
  }, []);

  useEffect(() => {
    if (!props.fetchData.loading) {
      setIsFetching(false);
      if (props.fetchedData.length == props.total) {
        setLoadMore(false);
      }
    }
  }, [props.fetchData.data]);

  const overflowedParentHeight = () => {
    const targetHeight =
      document.getElementById("InfiniteScroller").scrollHeight;
    const difference = document.getElementById("InfiniteScroller").clientHeight;
    const scrolledheight =
      document.getElementById("InfiniteScroller").scrollTop + difference;

    if (scrolledheight > targetHeight - 50) {
      if (initalRenderRef) {
        if (props.skippedFetch) {
          if (loadMore) {
            setIsFetching(true);
          }
        } else {
          setIsFetching(true);
        }
      }
    }
  };

  useEffect(() => {
    if (isFetching) {
      if (initialRender) {
        if (props.skippedFetch) {
          if (loadMore) {
            if (props.dispatchData) {
              props.dispatch(
                props.fetchAction({
                  skip: props.skip,
                  take: props.take,
                  ...props.dispatchData,
                })
              );
            } else {
              props.dispatch(
                props.fetchAction({ skip: props.skip, take: props.take })
              );
            }
          }
        } else {
          props.dispatch(props.fetchAction());
        }
      }
    }
  }, [isFetching]);

  return (
    <>
      <div id="InfiniteScroller" 
        className={`${props.overflowClassName ? props.overflowClassName : ""}`} 
        onScroll={props.parentOverflow ? overflowedParentHeight : null}
        ref={infiniteRef}
      >
        {props.children}
        {isFetching && loadMore && (
          <>
            {props.useLoader ? (
              <div className={props.loaderclasses}>{props.loaderComponent}</div>
            ) : (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  padding: "2em 0",
                }}
              >
                <Spinner animation="border" role="status">
                  
                </Spinner>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(InfiniteScroll);
