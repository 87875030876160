import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    Image,
    Media,
    Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";

import {
    fetchNewApplicationsStart,
} from "../../store/actions/ApplicationAction";
import useInfiniteScroll from "../helper/useInfiniteScroll";


const EmployersApplicants = (props) => {

    useEffect(() => {
        props.dispatch(fetchNewApplicationsStart());
    }, []);

    const fetchAppliedJobData = () => {
        setTimeout(() => {
          if (props.applications.length !== 0) {
            props.dispatch(fetchNewApplicationsStart({
                skip:props.applications.skip,
            }));
            setIsFetching(false);
          } else {
            setNoMoreData(true);
          }
        }, 3000);
    };

    const [isFetching, setIsFetching] = useInfiniteScroll(fetchAppliedJobData);

    const [noMoreData, setNoMoreData] = useState(false);

    return (
        <>
            <div className="home-sec">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="home-banner-card">
                                <div className="home-banner-info">
                                    <h3>Applicants</h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="margin-top-lg">
                        <Col md={12}>
                            <div className="employer-applicants-sec">
                                <ul className="list-unstyled">
                                    {props.applications.loading ? (
                                        "Loading"
                                    ) : props.applications.data.jobs.length > 0 ? (
                                        props.applications.data.jobs.map((job) => (
                                        <Media as="li">
                                            <Link to={"/employers-job-view/" + job.company_job.company_job_unique_id}>
                                                <div className="employers-applicants-card">
                                                    <div className="employers-applicants-img-sec">
                                                        <Image
                                                            src={job.user.picture}
                                                            alt="Image"
                                                            className="employers-applicants-img"
                                                        />
                                                    </div>
                                                    <div className="employers-applicants-info job-list-info">
                                                        <h4 className="position mb-2">{job.user.name}</h4>
                                                        <h6 className="company">{job.company_job.name}</h6>
                                                        <h6 className="company">{job.company_job.company_name}</h6>
                                                        <div className="meta">
                                                            <p className="location">
                                                                <span className="city">{job.company_job.city_name},{job.company_job.region_name}</span>
                                                            </p>
                                                            <p className="type">Full-time</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="job-list-action-sec">
                                                    <p className="time-ago">{job.created}</p>
                                                </div>
                                            </Link>
                                        </Media>
                                        ))
                                    ) : (
                                        "No Data found"
                                    )}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    {noMoreData !== true ? (
                        <>{isFetching && "Fetching more items..."}</>
                    ) : (
                        "No More Data"
                    )}
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    applications: state.applications.newApplications,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(EmployersApplicants));