import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import {
    userForgotPasswordStart,
} from "../../../store/actions/UserAction";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";

const ForgotPasswordIndex = (props) => {

    const [forgotPasswordInputData, setForgotPasswordInputData] = useState({});

    const handleForgotPassword = (event) => {
        event.preventDefault();
        props.dispatch(userForgotPasswordStart(forgotPasswordInputData));
    };

    return (
    <>
        <div className="login-sec">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={10} xl={6} lg={6}>
                        <div className="login-card">
                            <div className="login-box">
                                <h4>Forgot password?</h4>
                                <Form className="auth-form" onSubmit={handleForgotPassword} method="post">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Your Email</Form.Label>
                                        <Form.Control 
                                            type="email" 
                                            placeholder="Your email address"
                                            required
                                            value={forgotPasswordInputData.email}
                                            name="email"
                                            onChange={(event) =>
                                                setForgotPasswordInputData({
                                                    ...forgotPasswordInputData,
                                                    email: event.currentTarget.value,
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    <div className="btn-sec"> 
                                        <Button
                                            id="forgotpassword"
                                            type="submit"
                                            onClick={handleForgotPassword}
                                            className="default-btn btn-block"
                                            disabled={props.forgotPassword.buttonDisable}
                                        >
                                            {props.forgotPassword.loadingButtonContent !== null
                                            ? props.forgotPassword.loadingButtonContent
                                            : "Forgot Password"}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
    );
};

const mapStateToPros = (state) => ({
    forgotPassword: state.users.forgotPasswordInputData,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(ForgotPasswordIndex));
  