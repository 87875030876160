import {
    EMPLOYER_FETCH_JOB_START,
    EMPLOYER_FETCH_JOB_SUCCESS,
    EMPLOYER_FETCH_JOB_FAILURE,
    ADD_JOB_START,
    ADD_JOB_SUCCESS,
    ADD_JOB_FAILURE,
    EDIT_JOB,
    UPDATE_JOB_START,
    UPDATE_JOB_SUCCESS,
    UPDATE_JOB_FAILURE,
    DELETE_JOB_START,
    DELETE_JOB_SUCCESS,
    DELETE_JOB_FAILURE,
    PUBLISH_JOB_START,
    PUBLISH_JOB_SUCCESS,
    PUBLISH_JOB_FAILURE,
    EMPLOYER_FETCH_SINGLE_JOB_START,
    EMPLOYER_FETCH_SINGLE_JOB_SUCCESS,
    EMPLOYER_FETCH_SINGLE_JOB_FAILURE,
} from "./ActionConstant";

export function fetchJobStart(data) {
    return {
        type: EMPLOYER_FETCH_JOB_START,
        data,
    };
}
  
export function fetchJobSuccess(data) {
    return {
        type: EMPLOYER_FETCH_JOB_SUCCESS,
        data,
    };
}
  
export function fetchJobFailure(error) {
    return {
        type: EMPLOYER_FETCH_JOB_FAILURE,
        error,
    };
}
  
// Add Job
export function addJobStart(data) {
    return {
        type: ADD_JOB_START,
        data,
    };
}
  
export function addJobSuccess(data) {
    return {
        type: ADD_JOB_SUCCESS,
        data,
    };
}
  
export function addJobFailure(error) {
    return {
        type: ADD_JOB_FAILURE,
        error,
    };
}
  
// Update Job
export function updateJobStart(data) {
    return {
        type: UPDATE_JOB_START,
        data,
    };
}
  
export function updateJobSuccess(data) {
    return {
        type: UPDATE_JOB_SUCCESS,
        data,
    };
  }
  
export function updateJobFailure(error) {
    return {
        type: UPDATE_JOB_FAILURE,
        error,
    };
}
  
  
// Publish Job
export function publishJobStart(data) {
    return {
        type: PUBLISH_JOB_START,
        data,
    };
}
  
export function publishJobSuccess(data) {
    return {
        type: PUBLISH_JOB_SUCCESS,
        data,
    };
  }
  
export function publishJobFailure(error) {
    return {
        type: PUBLISH_JOB_FAILURE,
        error,
    };
}
  
// Delete Job actions.
export function deleteJobStart(data) {
    return {
        type: DELETE_JOB_START,
        data,
    };
}
  
export function deleteJobSuccess(data) {
    return {
        type: DELETE_JOB_SUCCESS,
        data,
    };
}
  
export function deleteJobFailure(error) {
    return {
        type: DELETE_JOB_FAILURE,
        error,
    };
}

// Single Job actions.
export function fetchSingleJobStart(data) {
    return {
        type: EMPLOYER_FETCH_SINGLE_JOB_START,
        data,
    };
}
  
export function fetchSingleJobSuccess(data) {
    return {
        type: EMPLOYER_FETCH_SINGLE_JOB_SUCCESS,
        data,
    };
  }
  
export function fetchSingleJobFailure(error) {
    return {
        type: EMPLOYER_FETCH_SINGLE_JOB_FAILURE,
        error,
    };
}

// Edit Job details action.
export function editJob(name, value) {
    return {
        type: EDIT_JOB,
        name,
        value,
    };
}