import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";

const Step1 = (props) => {

  return (
    <>
      <div className="post-job-type-sec">
        <div className="about-your-job-sec">
          <p className="separator">Personal information</p>
          <Form 
            className="about-your-company-form"
            // onSubmit={handleSubmit}
            id="form"
            method="post"
            name="form"
            autoComplete="off"
          >
            <Row>
              <Col md={6}>
                <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control 
                        placeholder="First name"
                        type="text"
                        controlId="firstName"
                        required
                        value={props.signupInputData.first_name}
                        name="first_name"
                        autoComplete="nope"
                        onChange={(event) =>
                            props.setSignupInputData({
                                ...props.signupInputData,
                                first_name: event.currentTarget.value,
                            })
                        }
                    />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control 
                        placeholder="Last name" 
                        type="text"
                        controlId="lastName"
                        required
                        value={props.signupInputData.last_name}
                        name="last_name"
                        autoComplete="nope"
                        onChange={(event) =>
                          props.setSignupInputData({
                                ...props.signupInputData,
                                last_name: event.currentTarget.value,
                            })
                        }
                    />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                        placeholder="E-mail"
                        type="text"
                        controlId="loginemail"
                        value={props.signupInputData.email}
                        name="email"
                        required
                        autoComplete="nope"
                        onChange={(event) =>
                          props.setSignupInputData({
                                ...props.signupInputData,
                                email: event.currentTarget.value,
                            })
                        }
                    />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                    <Form.Label>
                        Password
                    </Form.Label>
                    <Form.Control 
                        placeholder="Password" 
                        type="password"
                        required
                        controlId="Password"
                        value={props.signupInputData.password}
                        name="password"
                        autoComplete="nope"
                        onChange={(event) =>
                          props.setSignupInputData({
                                ...props.signupInputData,
                                password: event.currentTarget.value,
                            })
                        }
                    />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </div>  
    </>
  );
};

const mapStateToPros = (state) => ({
  
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Step1));
