import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";

const Profile = (props) => {
  return <>Profile</>;
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Profile);
