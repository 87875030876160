import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Media,
  Form,
} from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchServiceLocationStart,fetchJobRegionStart,fetchJobCityStart } from "../../store/actions/UserJobAction";
import useInfiniteScroll from "../helper/useInfiniteScroll";
const queryString = require("query-string");

const LocationsIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchJobRegionStart());
    props.dispatch(fetchJobCityStart());
  }, []);

  // const fetchLocationData = () => {
  //   setTimeout(() => {
  //     if (props.locations.length !== 0) {
  //       props.dispatch(
  //         fetchServiceLocationStart({
  //           skip: props.locations.skip,
  //         })
  //       );
  //       setIsFetching(false);
  //     } else {
  //       setNoMoreData(true);
  //     }
  //   }, 3000);
  // };

  // const [isFetching, setIsFetching] = useInfiniteScroll(fetchLocationData);

  // const [noMoreData, setNoMoreData] = useState(false);

  var location = {}

  const handleCityRedirect = (city) => {
    const query = queryString.stringify(
      {city : [city.city_unique_id]},
      {arrayFormatSeparator: "|" }
    );
    location = {
      pathname : `/jobs-listing`,
      search : query
    }
    return location;
  }

  const handleRegionRedirect = (region) => {
    const query = queryString.stringify(
      {region : [region.region_unique_id]},
      {arrayFormatSeparator: "|" }
    );
    location = {
      pathname : `/jobs-listing`,
      search : query
    }
    return location;
  }

  return (
    <>
      <div className="category-listing-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="home-banner-info">
                <h3>Browse jobs by location or remote.</h3>
              </div>
            </Col>
          </Row>
          {props.regions.loading ? (
            "Loading"
          ) : props.regions.data.regions.length > 0 ? (
            <>
              <Row className="category-title-sec">
                <Col md={12}>
                  <h3>
                    Browse {props.regions.data.regions.length} Regions
                  </h3>
                </Col>
              </Row>
              <Row>
                {props.regions.data.regions.map((region) => (
                  <Col md={4}>
                    <Link
                      target="_blank"
                      to={handleRegionRedirect(region)}
                    >
                      <div className="job-category-listing-card">
                        <div className="job-category-listing-logo-bg"></div>
                        <div className="job-location-icon-sec"></div>
                        <div className="job-category-listing-info">
                          <h4>{region.name}</h4>
                        </div>
                        <div className="job-category-listing-counting">
                          <p className="category-jobs">
                            <span>{region.total_jobs} jobs</span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            ""
          )}
          {props.cities.loading ? (
            "Loading"
          ) : props.cities.data.cities.length > 0 ? (
            <>
              <Row className="category-title-sec">
                <Col md={12}>
                  <h3>
                    Browse {props.cities.data.cities.length} Cities
                  </h3>
                </Col>
              </Row>
              <Row>
                {props.cities.data.cities.map((city) => (
                  <Col md={4}>
                    <Link
                      target="_blank"
                      to={handleCityRedirect(city)}
                    >
                      <div className="job-category-listing-card">
                        <div className="job-category-listing-logo-bg"></div>
                        <div className="job-location-icon-sec"></div>
                        <div className="job-category-listing-info">
                          <h4>{city.name}</h4>
                        </div>
                        <div className="job-category-listing-counting">
                          <p className="category-jobs">
                            <span>{city.total_jobs} jobs</span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            ""
          )}
          {/* {noMoreData !== true ? (
            <>{isFetching && "Fetching more items..."}</>
          ) : (
            "No More Data"
          )} */}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  locations: state.userjobs.locations,
  cities: state.userjobs.cities,
  regions: state.userjobs.regions,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LocationsIndex));
