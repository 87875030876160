import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Nav, Tab, Form,Button } from "react-bootstrap";
import {
  employerRegisterStart,
} from "../../store/actions/EmployerAction";

import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../Constant/constants";

const SignUpIndex = (props) => {

    const [signupInputData, setSignupInputData] = useState({});

    const [previewImage, setPreviewImage] = useState('');

    const handleSignup = (event) => {
        event.preventDefault();
        props.dispatch(employerRegisterStart(signupInputData));
    };

    const handleChangeImage = (event) => {
        if (event.currentTarget.type === "file") {
            setSignupInputData({
                ...signupInputData,
                [event.currentTarget.name]: event.currentTarget.files[0],
            });
            let reader = new FileReader();

            let file = event.currentTarget.files[0];
            
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    let autocomplete;

    const renderAutoComplete = () => {
      
        const { google } = props;
        if (!google) {
            console.log("asdfsadfasdfno");
            return;
        }

        autocomplete = new google.maps.places.Autocomplete(autocomplete, {
            types: ["geocode"]
        });

        autocomplete.setFields(["address_component", "geometry", "name"]);

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            console.log("Place", place);
            if (!place.geometry) return;
            let full_address = "";
            place.address_components.map(
                (address) =>
                (full_address =
                    full_address == ""
                        ? address.long_name
                        : full_address + "," + address.long_name)
            );

            setSignupInputData({
                ...signupInputData,
                address: full_address,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
            })
        });

    };
  
    return (
        <>
        <div className="profile-sec">
            <Container>
                <div className="profile-header-sec">
                    <h3>Employer Sign up</h3>
                    <p>Create an employer account for your company.</p>
                </div>
                <div className="profile-tab-sec">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                        <Row>
                            <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="profile">
                                    <Form 
                                        className="profile-form-sec auth-form"
                                        onSubmit={handleSignup}
                                        id="form"
                                        method="post"
                                        name="form"
                                        autoComplete="off"
                                    >
                                        <p className="separator">About your company</p>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Company Name</Form.Label>
                                                    <Form.Control 
                                                      placeholder="Company name"
                                                      type="text"
                                                      controlId="fCompanyName"
                                                      required
                                                      value={signupInputData.name}
                                                      name="name"
                                                      autoComplete="nope"
                                                      onChange={(event) =>
                                                        setSignupInputData({
                                                            ...signupInputData,
                                                            name: event.currentTarget.value,
                                                        })
                                                      }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Company tagline</Form.Label>
                                                    <Form.Control 
                                                      placeholder="Company tagline" 
                                                      type="text"
                                                      controlId="CompanyTagline"
                                                      required
                                                      value={signupInputData.company_tagline}
                                                      name="company_tagline"
                                                      autoComplete="nope"
                                                      onChange={(event) =>
                                                        setSignupInputData({
                                                            ...signupInputData,
                                                            company_tagline: event.currentTarget.value,
                                                        })
                                                      }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>
                                                      Company logo
                                                    </Form.Label>
                                                </Form.Group>
                                                <div className="avatar-preview-sec">
                                                    <div className="avatar-img-preview">
                                                        <Image
                                                            src={previewImage ? previewImage : window.location.origin + `/assets/img/user.png`}
                                                            alt="Image"
                                                            className="user-img"
                                                        />
                                                    </div>
                                                    <input type="file" id="picture" accept="image/*" name="picture" onChange={(event) => handleChangeImage(event)}/>
                                                    <label for="picture" id="picture-label"><span>Browse...</span></label>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group className="mb-2">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Company description
                                                            <span className="optional-badge">Optional</span>
                                                        </Form.Label>
                                                        <Form.Control 
                                                            placeholder="About" 
                                                            type="text"
                                                            controlId="About"
                                                            value={signupInputData.about}
                                                            name="about"
                                                            autoComplete="nope"
                                                            onChange={(event) =>
                                                                setSignupInputData({
                                                                    ...signupInputData,
                                                                    about: event.currentTarget.value,
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control 
                                                        placeholder="Address" 
                                                        type="text"
                                                        controlId="Address"
                                                        required
                                                        name="address"
                                                        onFocus={renderAutoComplete}
                                                        ref={ref => (autocomplete = ref)}
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                address: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>                                        
                                        <p className="separator">External Links</p>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                      Company website
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="Company website" 
                                                        type="text"
                                                        controlId="CompanyWebsite"
                                                        value={signupInputData.website}
                                                        name="website"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                website: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                      Company Linkedin 
                                                        <span className="optional-badge">Optional</span>
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="Company Linkedin " 
                                                        type="text"
                                                        controlId="CompanyLinkedin "
                                                        value={signupInputData.linkedin}
                                                        name="linkedin"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                linkedin: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                      Company twitter handle
                                                      <span className="optional-badge">Optional</span>
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="Company twitter handle" 
                                                        type="text"
                                                        controlId="twitter"
                                                        value={signupInputData.twitter}
                                                        name="twitter"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                twitter: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                      Company Facebook 
                                                      <span className="optional-badge">Optional</span>
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="Company Facebook " 
                                                        type="text"
                                                        controlId="CompanyFacebook "
                                                        value={signupInputData.facebook}
                                                        name="facebook"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                facebook: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>                                        
                                        <p className="separator">Create your employer account</p>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control 
                                                        placeholder="E-mail"
                                                        type="text"
                                                        controlId="loginemail"
                                                        value={signupInputData.email}
                                                        name="email"
                                                        required
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                email: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Password
                                                    </Form.Label>
                                                    <Form.Control 
                                                        placeholder="Password" 
                                                        type="password"
                                                        required
                                                        controlId="Password"
                                                        value={signupInputData.password}
                                                        name="password"
                                                        autoComplete="nope"
                                                        onChange={(event) =>
                                                            setSignupInputData({
                                                                ...signupInputData,
                                                                password: event.currentTarget.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} className="text-right">
                                                <Button
                                                    id="signup"
                                                    type="submit"
                                                    onClick={handleSignup}
                                                    className="default-btn btn-block"
                                                    disabled={props.signup.buttonDisable}
                                                    >
                                                    {props.signup.loadingButtonContent !== null
                                                        ? props.signup.loadingButtonContent
                                                        : "Create Account"}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Tab.Pane>
                            </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </Container>
        </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    signup: state.employers.registerInputData,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const connector = connect(mapStateToPros, mapDispatchToProps)(translate(SignUpIndex));

export default GoogleApiWrapper({
    apiKey: apiConstants.google_api_key
})(connector);