import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";

const Step4 = (props) => {


  return (
    <>
      <div className="pay-post-sec">
        <p className="separator">Job Posted Successfully!!!</p> 
      </div> 
    </>
  );
};

const mapStateToPros = (state) => ({
  
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Step4));
