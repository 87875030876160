import {
    FETCH_EMPLOYER_JOB_TYPE_START,
    FETCH_EMPLOYER_JOB_TYPE_SUCCESS,
    FETCH_EMPLOYER_JOB_TYPE_FAILURE,
    FETCH_EMPLOYER_JOB_CATEGORY_START,
    FETCH_EMPLOYER_JOB_CATEGORY_SUCCESS,
    FETCH_EMPLOYER_JOB_CATEGORY_FAILURE,
    FETCH_EMPLOYER_SERVICE_LOCATION_START,
    FETCH_EMPLOYER_SERVICE_LOCATION_SUCCESS,
    FETCH_EMPLOYER_SERVICE_LOCATION_FAILURE,
    FETCH_EMPLOYER_CITY_START,
    FETCH_EMPLOYER_CITY_SUCCESS,
    FETCH_EMPLOYER_CITY_FAILURE,
    FETCH_EMPLOYER_REGION_START,
    FETCH_EMPLOYER_REGION_SUCCESS,
    FETCH_EMPLOYER_REGION_FAILURE
} from "../actions/ActionConstant";
  
const initialState = {
    jobCategories: {
        data: {},
        inputData: {},
        loading: true,
        error: false,
    },
    jobtypes: {
        data: {},
        inputData: {},
        loading: true,
        error: false,
    },
    locations: {
        data: {},
        inputData: {},
        loading: true,
        error: false,
    },
    cities: {
        data: {},
        inputData: {},
        loading: true,
        error: false,
    },
    regions: {
        data: {},
        inputData: {},
        loading: true,
        error: false,
    },
};
  
const LookupReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case FETCH_EMPLOYER_JOB_CATEGORY_START:
            return {
                ...state,
                jobCategories: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                    data: {},
                },
            };
        case FETCH_EMPLOYER_JOB_CATEGORY_SUCCESS:
            return {
                    ...state,
                    jobCategories: {
                        inputData: action.data,
                        data: action.data,
                        loading: false,
                        error: false,
                    },
            };
        case FETCH_EMPLOYER_JOB_CATEGORY_FAILURE:
            return {
                ...state,
                jobCategories: {
                    inputData: action.data,
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_EMPLOYER_JOB_TYPE_START:
            return {
                ...state,
                jobtypes: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_EMPLOYER_JOB_TYPE_SUCCESS:
            return {
                ...state,
                jobtypes: {
                    inputData: action.data,
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_EMPLOYER_JOB_TYPE_FAILURE:
            return {
                ...state,
                jobtypes: {
                    inputData: action.data,
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };

        case FETCH_EMPLOYER_SERVICE_LOCATION_START:
            return {
                ...state,
                locations: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_EMPLOYER_SERVICE_LOCATION_SUCCESS:
            return {
                ...state,
                locations: {
                    inputData: action.data,
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_EMPLOYER_SERVICE_LOCATION_FAILURE:
            return {
                ...state,
                locations: {
                    inputData: action.data,
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };

        case FETCH_EMPLOYER_CITY_START:
            return {
                ...state,
                cities: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                    data: {},
                },
            };
        case FETCH_EMPLOYER_CITY_SUCCESS:
            return {
                ...state,
                cities: {
                    inputData: action.data,
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_EMPLOYER_CITY_FAILURE:
            return {
                ...state,
                cities: {
                    inputData: action.data,
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_EMPLOYER_REGION_START:
            return {
                ...state,
                regions: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                    data: {},
                },
            };
        case FETCH_EMPLOYER_REGION_SUCCESS:
            return {
                ...state,
                regions: {
                    inputData: action.data,
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_EMPLOYER_REGION_FAILURE:
            return {
                ...state,
                regions: {
                    inputData: action.data,
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };

        default:
            return state;
    }
};
  
export default LookupReducer;
  