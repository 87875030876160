import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Nav, Tab, Form, Media, Button } from "react-bootstrap";
import BrowseJobsCompanies from "./BrowseJobsCompanies";
import FeaturedBrowseCompanies from "./FeaturedBrowseCompanies";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchSubscribedCompanyStart } from "../../store/actions/UserJobAction";
import useInfiniteScroll from "../helper/useInfiniteScroll";
const queryString = require("query-string");

const CompaniesIndex = (props) => {
  const [searchKey, setSearchkey] = useState("");

  useEffect(() => {
    props.dispatch(
      fetchSubscribedCompanyStart({
        loadMore: "",
      })
    );
  }, []);

  const handleSearch = (event, search_key) => {
    console.log(search_key);
    event.preventDefault();
    props.dispatch(fetchSubscribedCompanyStart({ search_key: search_key }));
  };
  const fetchCompanyData = () => {
    setTimeout(() => {
      if (props.companies.length !== 0) {
        props.dispatch(
          fetchSubscribedCompanyStart({
            skip: props.companies.skip,
            loadMore: "loadMore",
            search_key: searchKey,
          })
        );
        setIsFetching(false);
      } else {
        setNoMoreData(true);
      }
    }, 3000);
  };

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchCompanyData);

  const [noMoreData, setNoMoreData] = useState(false);

  var location = {}
  
  const handleCompanyRedirect = (company) => {
    const query = queryString.stringify(
      {company : [company.company_unique_id]},
      {arrayFormatSeparator: "|" }
    );
    location = {
      pathname : `/jobs-listing`,
      search : query
    }
    return location;
  }
  return (
    <>
      <div className="companies-sec">
        <Container>
          <Row>
              <Col md={12}>
                  <div className="companies-banner-sec">
                      <h4>Find jobs at inspiring companies</h4>
                      <p>{props.companies.loading
                      ? "Loading"
                      : props.companies.data.companies.length} Registered Companies</p>
                  </div>
              </Col>
          </Row>
        </Container>
      </div>
      <BrowseJobsCompanies 
        handleSearch={handleSearch}
      />
      <FeaturedBrowseCompanies 
        companies={props.companies}
        noMoreData={noMoreData}
        isFetching={isFetching}
        handleCompanyRedirect={handleCompanyRedirect}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  companies: state.userjobs.subscribedCompanies,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(CompaniesIndex));
