import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media, Form} from "react-bootstrap";
import StepZilla from "react-stepzilla";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import "./CreatePost.css";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import {
  fetchEmployerDetailsStart,
} from "../../store/actions/EmployerAction";

const CreateJobIndex = (props) => {

  useEffect(() => {
    props.dispatch(fetchEmployerDetailsStart());
  }, []);

  const [stepsNavigation, setStepsNavigation] = useState(true);

  const [initalStep, setInitalStep] = useState(0);

  useEffect(() => {
    if (props.profile.data.account_type === 0) {
      setInitalStep(0);
    } else if (props.profile.data.account_type == 1) {
      setInitalStep(1);
    } else {
      setInitalStep("");
    }

  }, [props.profile.data]);


  const steps =
    [
      {name: 'Subscription', component: <Step1 employer={props.profile.data} />},
      {name: 'Create listing', component: <Step2 />},
      {name: 'Preview listing', component: <Step3 />},
      // {name: 'Save', component: <Step4 />},
    ]

  return (
    <>
      <div className="create-post-sec">
        <Container>
           <Row>
              <Col md={12}>
                <div className="home-banner-info">
                    <h3>Build amazing Fintech teams with Fintexer.{props.profile.data.account_type}</h3>
                    <p>Reach thousands of Fintech job seekers and hire the very best talent.</p>
                </div>
              </Col>
           </Row>
           {/* <Row>
             <Col md={12}>
                <div className="create-post-banner-sec">
                  <Image
                      src={window.location.origin + `/assets/img/create-post-banner.png`}
                      alt="Image"
                      className="create-post-banner"
                  />
                </div>
             </Col>
           </Row> */}
            {initalStep === "" ? null : (
              <Row>
                <Col md={12}>
                  <div className='step-progress mt-3'>
                    <StepZilla 
                      steps={steps}
                      showSteps={true}
                      stepsNavigation={true} 
                      prevBtnOnLastStep={false} 
                      preventEnterSubmission={true}
                      nextTextOnFinalActionStep={"Save & Preview"}
                      startAtStep={initalStep}
                    />
                  </div>
                </Col>
              </Row>
            )}
           
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.employers.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(CreateJobIndex));