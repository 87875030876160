import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Nav, Tab, Form, Media, Button } from "react-bootstrap";
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";

import JobDisplayCard from "../helper/JobDisplayCard";
import EmailAlertModal from "../Modals/EmailAlertModal";
import {
    fetchJobsStart,
    fetchJobCategoryListStart,
    fetchJobRegionStart,
    fetchCompanyViewStart
} from "../../store/actions/UserJobAction";
import { GoogleApiWrapper, InfoWindow, Marker, Map } from "google-maps-react";
import { apiConstants } from "../Constant/constants";
const queryString = require("query-string");

const mapStyles = {
    width: "100%",
    height: "300px"
};
const SingleCompanyIndex = (props) => {

    const [emailAlert, setEmailAlert] = useState(false);

    const [state, setState] = useState({});

    const closeEmailAlertModal = () => {
      setEmailAlert(false);
    };

    useEffect(() => {
        props.dispatch(fetchJobRegionStart());
        props.dispatch(fetchJobCategoryListStart({loadMore: ""}));
        props.dispatch(fetchJobsStart({company:props.match.params.company_unique_id}));
        props.dispatch(fetchCompanyViewStart({company_unique_id:props.match.params.company_unique_id}));
    }, []);

    var location = {};

    const onMarkerClick = (props, marker, e) =>
        setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            loading: false
        });

    const handleCompanyRedirect = (company) => {
        const query = queryString.stringify(
          {company : [company.company_unique_id]},
          {arrayFormatSeparator: "|" }
        );
        location = {
          pathname : `/jobs-listing`,
          search : query
        }
        return location;
    }

    return (
        <>
            <div className="single-companies-sec">
                <Container>
                    {props.singleCompany.loading
                        ? "Loading..."
                    :
                    <>
                    <Row>
                        <Col md={12}>
                            <div className="single-companies-header-sec">
                                <Image
                                    src={props.singleCompany.data.company.cover}
                                    alt="Image"
                                    className="single-companies-header-img"
                                />
                                <div className="single-companies-header-info">
                                    <Image
                                        src={props.singleCompany.data.company.company_logo}
                                        alt="Image"
                                        className="single-companies-logo"
                                    />
                                    <h4>{props.singleCompany.data.company.company_name}</h4>
                                    <p>{props.singleCompany.data.company.company_tagline}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={12}>
                            <div className="single-companies-thumbnails-img-sec">
                                <ReactFancyBox
                                    thumbnail="/assets/img/company/single-companies-img-1.png"
                                    image="/assets/img/company/single-companies-img-1.png"
                                />
                                <ReactFancyBox
                                    thumbnail="/assets/img/company/single-companies-img-2.png"
                                    image="/assets/img/company/single-companies-img-2.png"
                                />
                                <ReactFancyBox
                                    thumbnail="/assets/img/company/single-companies-img-3.png"
                                    image="/assets/img/company/single-companies-img-3.png"
                                />
                                <ReactFancyBox
                                    thumbnail="/assets/img/company/single-companies-img-4.png"
                                    image="/assets/img/company/single-companies-img-4.png"
                                />
                                <ReactFancyBox
                                    thumbnail="/assets/img/company/single-companies-img-1.png"
                                    image="/assets/img/company/single-companies-img-1.png"
                                />
                            </div>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md={12}>
                            <div className="single-companies-about-sec">
                                <h4>About {props.singleCompany.data.company.company_name}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: props.singleCompany.data.company.about,
                                    }}
                                ></p>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={12}>
                            <div className="single-companies-our-amenities-sec">
                                <div className="single-companies-our-amenities-card">
                                    <div className="single-companies-our-amenities-icon-sec">
                                        <Image
                                            src={window.location.origin + `/assets/img/icon/amenities.svg`}
                                            alt="Image"
                                            className="single-companies-our-amenities-icon"
                                        />
                                        <h5>Industry</h5>
                                        <p>Security</p>
                                    </div>
                                </div>
                                <div className="single-companies-our-amenities-card">
                                    <div className="single-companies-our-amenities-icon-sec">
                                        <Image
                                            src={window.location.origin + `/assets/img/icon/amenities.svg`}
                                            alt="Image"
                                            className="single-companies-our-amenities-icon"
                                        />
                                        <h5>Type</h5>
                                        <p>Corporate</p>
                                    </div>
                                </div>
                                <div className="single-companies-our-amenities-card">
                                    <div className="single-companies-our-amenities-icon-sec">
                                        <Image
                                            src={window.location.origin + `/assets/img/icon/amenities.svg`}
                                            alt="Image"
                                            className="single-companies-our-amenities-icon"
                                        />
                                        <h5>Reach</h5>
                                        <p>International</p>
                                    </div>
                                </div>
                                <div className="single-companies-our-amenities-card">
                                    <div className="single-companies-our-amenities-icon-sec">
                                        <Image
                                            src={window.location.origin + `/assets/img/icon/amenities.svg`}
                                            alt="Image"
                                            className="single-companies-our-amenities-icon"
                                        />
                                        <h5>Employees</h5>
                                        <p>10,000 or more</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md={12}>
                            <div className="live-jobs-group-sec">
                                <div className="live-job-group-header-sec">
                                    <h5>Live jobs at {props.singleCompany.data.company.company_name}</h5>
                                    <Link to={handleCompanyRedirect(props.singleCompany.data.company)}>View All ({props.singleCompany.data.company.total_jobs})</Link>
                                </div>
                                <div className="live-job-list-group-box">
                                    {props.jobs.loading ? (
                                        "Loading"
                                    ) : props.jobs.data.jobs_list.length > 0 ? (
                                        <>
                                            {props.jobs.data.jobs_list.map((job) => (
                                                <JobDisplayCard job={job} key={job.company_job_id} />
                                            ))}
                                        </>
                                    ) : (
                                        "No Data found"
                                    )}
                                </div>
                                <div className="live-job-create-alert-btn-sec">
                                    <Link to="#" className="apply-btn" onClick={() => setEmailAlert(true)}>CREATE AN EMAIL ALERT</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="single-companies-find-us-sec">
                        <Row>
                            <Col md={6}>
                                <h4>Find Us</h4> 
                                <div className="single-company-find-us-card">
                                    <div className="single-company-find-us-icon-sec">
                                        <Image
                                            src={window.location.origin + `/assets/img/icon/marker.svg`}
                                            alt="Image"
                                            className="single-company-find-us-icon"
                                        />
                                    </div>
                                    <div className="single-company-find-us-info">
                                        <h6>Address</h6>
                                        <p>{props.singleCompany.data.company.service_location ? props.singleCompany.data.company.service_location.name : ''}</p>
                                    </div>
                                </div> 
                                <div className="single-company-find-us-card">
                                    <div className="single-company-find-us-icon-sec">
                                        <Image
                                            src={window.location.origin + `/assets/img/icon/email.svg`}
                                            alt="Image"
                                            className="single-company-find-us-icon"
                                        />
                                    </div>
                                    <div className="single-company-find-us-info">
                                        <h6>Mail</h6>
                                        <p>{props.singleCompany.data.company.email}</p>
                                    </div>
                                </div>
                                <div className="single-companies-find-us-socail">
                                    <ul className="list-unstyled single-companies-social-links">
                                        {props.singleCompany.data.company.facebook ? 
                                            <Media as="li">
                                                <a href={props.singleCompany.data.company.facebook}>
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </Media>
                                        : ''}
                                        {props.singleCompany.data.company.twitter ? 
                                            <Media as="li">
                                                <a href={props.singleCompany.data.company.twitter}>
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </Media>
                                        : ''}
                                        {props.singleCompany.data.company.linkedin ? 
                                            <Media as="li">
                                                <a href={props.singleCompany.data.company.linkedin}>
                                                    <i className="fab fa-linkedin-in"></i>
                                                </a>
                                            </Media>
                                        : ''}
                                    </ul>
                                </div>     
                            </Col>
                            {props.singleCompany.data.company ? 
                            <Col md={6}>
                                <Map
                                    google={props.google}
                                    zoom={14}
                                    style={mapStyles}
                                    initialCenter={{
                                    lat: props.singleCompany.data.company.latitude,
                                    lng: props.singleCompany.data.company.longitude
                                    }}
                                >
                                    <Marker
                                        onClick={onMarkerClick}
                                        name={"Spome"}
                                        title={"titile"}
                                        position={{ lat: props.singleCompany.data.company.latitude, lng: props.singleCompany.data.company.longitude }}
                                    />
                                </Map>
                            </Col>
                            : ''}
                        </Row>
                    </div>
                    </>}       
                </Container>
            </div>
            <EmailAlertModal 
                emailAlert={emailAlert} 
                categories={props.categories}
                regions={props.regions}
                closeEmailAlertModal={closeEmailAlertModal} 
            />
        </>
    );
};

const mapStateToPros = (state) => ({
    jobs: state.userjobs.jobs,
    categories: state.userjobs.jobCategories,
    regions: state.userjobs.regions,
    singleCompany: state.userjobs.singleCompany,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const connector = connect(mapStateToPros, mapDispatchToProps)(translate(SingleCompanyIndex));

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key
})(connector);

  