import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { 
    FETCH_WISHLIST_START,
    SAVE_WISHLIST_START,
    CLEAR_WISHLIST_START,
    FETCH_JOBS_START,
    FETCH_SINGLE_JOB_START,
    FETCH_JOB_TYPE_LIST_START,
    FETCH_JOB_CATEGORY_LIST_START,
    FETCH_JOB_COMPANY_LIST_START,
    FETCH_SERVICE_LOCATION_START,
    USER_JOB_APPLY_START,
    LOAD_MORE_JOBS_START,
    FETCH_JOB_APPLIED_LIST_START,
    FETCH_SALARY_FILTER_START,
    FETCH_JOB_REGION_START,
    FETCH_JOB_CITY_START,
    USER_JOB_ALERT_SAVE_START,
    FETCH_COMPANY_VIEW_START,
    FETCH_SUBSCRIBED_COMPANY_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";
import {
    fetchWishlistSuccess,
    fetchWishlistFailure,
    saveWishlistSuccess,
    saveWishlistFailure,
    clearWishlistSuccess,
    clearWishlistFailure,
    fetchJobsSuccess,
    fetchJobsFailure,
    fetchSingleJobSuccess,
    fetchSingleJobFailure,
    fetchJobTypeListSuccess,
    fetchJobTypeListFailure,
    fetchJobCategoryListSuccess,
    fetchJobCategoryListFailure,
    userJobApplySuccess,
    userJobApplyFailure,
    fetchCompanyListSuccess,
    fetchCompanyListFailure,
    fetchServiceLocationSuccess,
    fetchServiceLocationFailure,
    loadMoreJobsSuccess,
    loadMoreJobsFailure,
    fetchJobAppliedListSuccess,
    fetchJobAppliedListFailure,
    fetchSalaryFilterSuccess,
    fetchSalaryFilterFailure,
    fetchJobCityFailure,
    fetchJobCitySuccess,
    fetchJobRegionSuccess,
    fetchJobRegionFailure,
    userJobAlertSaveFailure,
    userJobAlertSaveSuccess,
    fetchCompanyViewSuccess,
    fetchCompanyViewFailure,
    fetchSubscribedCompanySuccess,
    fetchSubscribedCompanyFailure
} from "../actions/UserJobAction";

function* fetchWishlistAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "wishlist", action.data
        );
        if (response.data.success) {
            yield put(fetchWishlistSuccess(response.data.data));
        } else {
            yield put(fetchWishlistFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchWishlistFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* saveWishlistAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "wishlist_operations", action.data
        );
        if (response.data.success) {
            yield put(saveWishlistSuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
        } else {
            yield put(saveWishlistFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(saveWishlistFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* clearWishlistAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "wishlist_clear", action.data
        );
        if (response.data.success) {
            yield put(clearWishlistSuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
        } else {
            yield put(clearWishlistFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(clearWishlistFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
function* fetchJobsAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "jobs_list", action.data
        );
        if (response.data.success) {
            yield put(fetchJobsSuccess(response.data.data));
        } else {
            yield put(fetchJobsFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchJobsFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
function* fetchSingleJobAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "company_job_single_view", action.data
        );
        if (response.data.success) {
            yield put(fetchSingleJobSuccess(response.data.data));
        } else {
            yield put(fetchSingleJobFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchSingleJobFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchJobTypeAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "job_type_list", action.data
        );
        if (response.data.success) {
            yield put(fetchJobTypeListSuccess(response.data.data));
        } else {
            yield put(fetchJobTypeListFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchJobTypeListFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
function* fetchCategoryAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "categories_list", action.data
        );
        if (response.data.success) {
            yield put(fetchJobCategoryListSuccess(response.data.data));
        } else {
            yield put(fetchJobCategoryListFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchJobCategoryListFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
function* fetchCompanyAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "company_list", action.data
        );
        if (response.data.success) {
            yield put(fetchCompanyListSuccess(response.data.data));
        } else {
            yield put(fetchCompanyListFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchCompanyListFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
function* fetchSubscribedCompanyAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "subscribed_company_list", action.data
        );
        if (response.data.success) {
            yield put(fetchSubscribedCompanySuccess(response.data.data));
        } else {
            yield put(fetchSubscribedCompanyFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchSubscribedCompanyFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
function* fetchLocationAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "service_location_list", action.data
        );
        if (response.data.success) {
            yield put(fetchServiceLocationSuccess(response.data.data));
        } else {
            yield put(fetchServiceLocationFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchServiceLocationFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
function* applyJobAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "job_apply", action.data
        );
        if (response.data.success) {
            yield put(userJobApplySuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
        } else {
            yield put(userJobApplyFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(userJobApplyFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* loadMoreJobsAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "jobs_list", action.data
        );
        if (response.data.success) {
            yield put(loadMoreJobsSuccess(response.data.data));
        } else {
            yield put(loadMoreJobsFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(loadMoreJobsFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchAppliedJobsAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "job_applied_list", action.data
        );
        if (response.data.success) {
            yield put(fetchJobAppliedListSuccess(response.data.data));
        } else {
            yield put(fetchJobAppliedListFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchJobAppliedListFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
function* fetchSalaryFilterAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "salary_filter", action.data
        );
        if (response.data.success) {
            yield put(fetchSalaryFilterSuccess(response.data.data));
        } else {
            yield put(fetchSalaryFilterFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchSalaryFilterFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
function* fetchRegionFilterAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "regions_list", action.data
        );
        if (response.data.success) {
            yield put(fetchJobRegionSuccess(response.data.data));
        } else {
            yield put(fetchJobRegionFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchJobRegionFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
function* fetchCityFilterAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "cities_list", action.data
        );
        if (response.data.success) {
            yield put(fetchJobCitySuccess(response.data.data));
        } else {
            yield put(fetchJobCityFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchJobCityFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchSingleCompanyAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "company_single_view", action.data
        );
        if (response.data.success) {
            yield put(fetchCompanyViewSuccess(response.data.data));
        } else {
            yield put(fetchCompanyViewFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchCompanyViewFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* userJobAlertSaveAPI(action) {
    try {
        const response = yield api.userPostMethod(
            "jobs_alert_save", action.data
        );
        if (response.data.success) {
            yield put(userJobAlertSaveSuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
        } else {
            yield put(userJobAlertSaveFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(userJobAlertSaveFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_WISHLIST_START, fetchWishlistAPI)]);
  yield all([yield takeLatest(SAVE_WISHLIST_START, saveWishlistAPI)]);
  yield all([yield takeLatest(CLEAR_WISHLIST_START, clearWishlistAPI)]);
  yield all([yield takeLatest(FETCH_JOBS_START, fetchJobsAPI)]);
  yield all([yield takeLatest(FETCH_SINGLE_JOB_START, fetchSingleJobAPI)]);
  yield all([yield takeLatest(FETCH_JOB_TYPE_LIST_START, fetchJobTypeAPI)]);
  yield all([yield takeLatest(FETCH_JOB_CATEGORY_LIST_START, fetchCategoryAPI)]);
  yield all([yield takeLatest(FETCH_JOB_COMPANY_LIST_START, fetchCompanyAPI)]);
  yield all([yield takeLatest(USER_JOB_APPLY_START, applyJobAPI)]);
  yield all([yield takeLatest(FETCH_SERVICE_LOCATION_START, fetchLocationAPI)]);
  yield all([yield takeLatest(LOAD_MORE_JOBS_START, loadMoreJobsAPI)]);
  yield all([yield takeLatest(FETCH_JOB_APPLIED_LIST_START, fetchAppliedJobsAPI)]);
  yield all([yield takeLatest(FETCH_SALARY_FILTER_START, fetchSalaryFilterAPI)]);
  yield all([yield takeLatest(FETCH_JOB_CITY_START, fetchCityFilterAPI)]);
  yield all([yield takeLatest(FETCH_JOB_REGION_START, fetchRegionFilterAPI)]);
  yield all([yield takeLatest(USER_JOB_ALERT_SAVE_START, userJobAlertSaveAPI)]);
  yield all([yield takeLatest(FETCH_COMPANY_VIEW_START, fetchSingleCompanyAPI)]);
  yield all([yield takeLatest(FETCH_SUBSCRIBED_COMPANY_START, fetchSubscribedCompanyAPI)]);
}
