import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, Tab, Nav, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { companySubscriptionPaymentStart } from "../../store/actions/CompanySubscriptionAction";
import {
  fetchCardDetailsStart,
} from "../../store/actions/CardsAction";
import { Link } from "react-router-dom";
const $ = window.$;

const PaymentModal = (props) => {

    const [paymentType, setPaymentType] = useState("CARD");


    useEffect(() => {
        if (props.paymentModal === true) {
            props.dispatch(fetchCardDetailsStart());
        }
    }, [props.paymentModal]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (paymentType === "CARD")
            props.dispatch(
                companySubscriptionPaymentStart({
                    company_subscription_id:props.subscription.company_subscription_id,
                })
            );
        props.closePaymentModal();
    };

    return (
        <Modal centered
            size="lg"
            className="modal-dialog-center sent-tip-modal"
            show={props.paymentModal}
            onHide={props.closePaymentModal}
        >
            {props.paymentModal === true ? 
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Subscription Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body className="subscription-tip-ppv-tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey={paymentType}>
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        {configuration.get("configData.stripe_publishable_key") !== "" && configuration.get("configData.stripe_secret_key") !== "" ? (
                                            <Nav.Item>
                                                <Nav.Link onClick={() => setPaymentType("CARD")} eventKey="CARD">Card (Stripe)</Nav.Link>
                                            </Nav.Item>
                                        ) : null}
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <div className="card-stripe-box">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="text" 
                                                placeholder="Amount" 
                                                value={props.subscription.amount_formatted}
                                                disabled/>
                                        </Form.Group>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="CARD">
                                                <div className="card-stripe-sec">
                                                    {props.cards.loading ? "" : props.cards.data.cards.length > 0 ?
                                                        props.cards.data.cards.map((card) => ( (
                                                        card.is_default == 1 && (
                                                            <div className="card-stripe-list-box">
                                                                <h5 className="mb-3">XXXX XXXX XXXX {card.last_four}</h5>
                                                                <h5 className="text-muted">{card.card_type}</h5>
                                                                <div className="card-stripe-bottom">
                                                                    <div className="card-stripe-action-btn">
                                                                        <p className="card-link-text text-success">Default card</p>
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                    ))): (
                                                        <div className="card-stripe-item">
                                                            <Link to="cards">
                                                                <div className="add-account-item">
                                                                    <Image
                                                                        className="add-account-icon"
                                                                        src={
                                                                            window.location.origin + "/assets/img/icon/add-card.svg"
                                                                        }
                                                                    />
                                                                    <p className="text-muted">Add Card </p>
                                                                </div>
                                                            </Link>
                                                        </div>                                                        
                                                    )}
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                        onClick={props.closePaymentModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-success"
                        data-dismiss="modal"
                        onClick={handleSubmit}
                        disabled={props.cardPayment.buttonDisable}
                    >
                        {props.cardPayment.loadingButtonContent !== null
                                ? props.cardPayment.loadingButtonContent
                                : "Pay"}
                    </Button>
                </Modal.Footer>
            </>
        : null}
        </Modal>
    );
};


const mapStateToPros = (state) => ({
    cards: state.cards.cardDetails,
    cardPayment: state.subscriptions.paymentByCard,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
  )(translate(PaymentModal));