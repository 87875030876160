import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Base from "./components/Base";
import store from "./store";
import "react-redux-notify/dist/ReactReduxNotify.css";
import './scss/style.scss'
import './scss/responsive.scss';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "../src/components/helper/ScrollToTop";
import 'react-loading-skeleton/dist/skeleton.css'
import configuration from "react-global-configuration";
import { apiConstants } from "./components/Constant/constants";
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {

  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    fetchConfig();
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();
      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  return (
    <Provider store={store}>
      <Router>
        {!configLoading && (
          <>
            <ScrollToTop />
            <Base />
          </>
        )}
      </Router>
    </Provider>
  );
}

export default App;
