import {
    FETCH_EMPLOYER_JOB_TYPE_START,
    FETCH_EMPLOYER_JOB_TYPE_SUCCESS,
    FETCH_EMPLOYER_JOB_TYPE_FAILURE,
    FETCH_EMPLOYER_JOB_CATEGORY_START,
    FETCH_EMPLOYER_JOB_CATEGORY_SUCCESS,
    FETCH_EMPLOYER_JOB_CATEGORY_FAILURE,
    FETCH_EMPLOYER_SERVICE_LOCATION_START,
    FETCH_EMPLOYER_SERVICE_LOCATION_SUCCESS,
    FETCH_EMPLOYER_SERVICE_LOCATION_FAILURE,
    FETCH_EMPLOYER_CITY_START,
    FETCH_EMPLOYER_CITY_SUCCESS,
    FETCH_EMPLOYER_CITY_FAILURE,
    FETCH_EMPLOYER_REGION_START,
    FETCH_EMPLOYER_REGION_SUCCESS,
    FETCH_EMPLOYER_REGION_FAILURE
} from "./ActionConstant";
  
export function fetchEmployerJobTypeStart(data) {
    return {
        type: FETCH_EMPLOYER_JOB_TYPE_START,
        data,
    };
}
  
export function fetchEmployerJobTypeSuccess(data) {
    return {
        type: FETCH_EMPLOYER_JOB_TYPE_SUCCESS,
        data,
    };
}
  
export function fetchEmployerJobTypeFailure(error) {
    return {
        type: FETCH_EMPLOYER_JOB_TYPE_FAILURE,
        error,
    };
}

export function fetchEmployerJobCategoryStart(data) {
    return {
        type: FETCH_EMPLOYER_JOB_CATEGORY_START,
        data,
    };
}
  
export function fetchEmployerJobCategorySuccess(data) {
    return {
        type: FETCH_EMPLOYER_JOB_CATEGORY_SUCCESS,
        data,
    };
}
  
export function fetchEmployerJobCategoryFailure(error) {
    return {
        type: FETCH_EMPLOYER_JOB_CATEGORY_FAILURE,
        error,
    };
}

export function fetchEmployerServiceLocationStart(data) {
    return {
        type: FETCH_EMPLOYER_SERVICE_LOCATION_START,
        data,
    };
}
  
export function fetchEmployerServiceLocationSuccess(data) {
    return {
        type: FETCH_EMPLOYER_SERVICE_LOCATION_SUCCESS,
        data,
    };
}
  
export function fetchEmployerServiceLocationFailure(error) {
    return {
        type: FETCH_EMPLOYER_SERVICE_LOCATION_FAILURE,
        error,
    };
}

export function fetchEmployerCityStart(data) {
    return {
        type: FETCH_EMPLOYER_CITY_START,
        data,
    };
}
  
export function fetchEmployerCitySuccess(data) {
    return {
        type: FETCH_EMPLOYER_CITY_SUCCESS,
        data,
    };
}
  
export function fetchEmployerCityFailure(error) {
    return {
        type: FETCH_EMPLOYER_CITY_FAILURE,
        error,
    };
}

export function fetchEmployerRegionStart(data) {
    return {
        type: FETCH_EMPLOYER_REGION_START,
        data,
    };
}
  
export function fetchEmployerRegionSuccess(data) {
    return {
        type: FETCH_EMPLOYER_REGION_SUCCESS,
        data,
    };
}
  
export function fetchEmployerRegionFailure(error) {
    return {
        type: FETCH_EMPLOYER_REGION_FAILURE,
        error,
    };
}
