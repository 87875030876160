import {
    FETCH_EMPLOYER_DASHBOARD_START,
    FETCH_EMPLOYER_DASHBOARD_SUCCESS,
    FETCH_EMPLOYER_DASHBOARD_FAILURE,
} from "../actions/ActionConstant";
  
const initialState = {
    dashboard: {
        data: {},
        loading: true,
        error: false,
    },
};
  
const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EMPLOYER_DASHBOARD_START:
            return {
                ...state,
                dashboard: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_EMPLOYER_DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboard: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_EMPLOYER_DASHBOARD_FAILURE:
            return {
                ...state,
                dashboard: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
    
        default:
            return state;
    }
};
  
export default DashboardReducer;
  