import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Nav, Tab, Form, Media, Button } from "react-bootstrap";

const EmployersProfile = (props) => {
  return (
    <>
      <div className="profile-sec profile-company-sec emp-profile-sec">
        <Container>
          <div className="profile-header-sec">
            <h3>Employer Dashboard</h3>
            <p>Manage your job seeker profile.</p>
          </div>
          <div className="profile-tab-sec">
            <Tab.Container id="left-tabs-example" defaultActiveKey="status">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="status">Status</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="profile">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="jobs">Jobs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="applicants">Applicants</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="billing">Billing</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="change-password">Change Password</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="delete-account">Delete Account</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="notification-prefeneces">Notification Prefeneces</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="status">
                      <h3>Last 30 days</h3>
                      <div className="emp-profile-dashboard-box">
                        <div className="emp-profile-dashboard-card">
                          <h5>Jobs posted</h5>
                          <p>2</p>
                        </div>
                        <div className="emp-profile-dashboard-card">
                          <h5>Applicants (by form)</h5>
                          <p>5</p>
                        </div>
                        <div className="emp-profile-dashboard-card">
                          <h5>Applications with link (clicks)</h5>
                          <p>10</p>
                        </div>
                        <div className="emp-profile-dashboard-card">
                          <h5>Applications with email (clicks)</h5>
                          <p>12</p>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="profile">
                      <h3>Company Profile</h3>
                      <p className="separator">About your company</p>
                      <Form className="profile-form-sec auth-form">
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>
                                Company name
                              </Form.Label>
                              <Form.Control placeholder="" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>
                                Company tagline
                              </Form.Label>
                              <Form.Control placeholder="" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Form.Group className="mb-2">
                              <Form.Label>
                                Company logo
                              </Form.Label>
                            </Form.Group>
                            <div className="avatar-preview-sec">
                              <div className="avatar-img-preview">
                                <Image
                                  src={window.location.origin + `/assets/img/user.png`}
                                  alt="Image"
                                  className="user-img"
                                />
                              </div>
                              <input type="file" id="upload" accept="image/*" />
                              <div>
                                <label for="upload" id="upload-label"><span>Browse...</span></label>
                                <p>We recommend using your company's LinkedIn logo.</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>
                                Company description
                                <span className="optional-badge">Optional</span>
                              </Form.Label>
                              <Form.Control as="textarea" rows={3} />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <h5>Hide all company information and post anonymously</h5>
                            <div className="inputGroup">
                              <input id="option1" name="option1" type="checkbox" />
                              <label for="option1" className="ml-2">
                                <span>Enable anonymous profile</span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <p className="separator">External links</p>
                        <Row>
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>
                                Company website
                              </Form.Label>
                              <Form.Control placeholder="" />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>
                                Company Linkedin
                                <span className="optional-badge">Optional</span>
                              </Form.Label>
                              <Form.Control placeholder="https://www.linkedin.com/company/xyz" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>
                                Company twitter handle
                                <span className="optional-badge">Optional</span>
                              </Form.Label>
                              <Form.Control placeholder="@acme" />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>
                                Company Facebook
                                <span className="optional-badge">Optional</span>
                              </Form.Label>
                              <Form.Control placeholder="https://www.facebook.com/xyz" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <p className="separator">Your employer account</p>
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>
                                Company email
                              </Form.Label>
                              <Form.Control placeholder="@acme" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="emp-profile-action-sec">
                              <Link to="#" className="default-btn">
                                Save
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="jobs">
                      <h3>Jobs</h3>
                      <div className="my-job-application-sec">
                        <div className="my-job-application-header">
                          <div className="my-job-application-select">
                            <Row className="align-items-center">
                              <Col md={3}>
                                <p className="total-count-jobs">2 jobs</p>
                              </Col>
                              <Col md={9}>
                                <Form.Group className="mb-0">
                                  <Form.Control type="text" placeholder="Search" />
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                          <div className="page-controls">
                            <p className="page-info">Page 1 / 1</p>
                          </div>
                        </div>
                        <div className="my-job-application-body">
                          <div className="my-job-application-card">
                            <div className="my-job-application-info">
                              <h4 className="name">Senior UI/UX Designer</h4>
                              <p className="site-url">True Layer</p>
                              <div className="meta">
                                <p className="applications">Sydney, New South Wales, Australia</p>
                              </div>
                              <div className="my-jobs-buttons">
                                <Link to="/employers-job-view" target="_blank" className="view-job-btn">View job</Link>
                              </div>
                            </div>
                            <div className="my-job-application-applied-date">
                              <p className="created-at">Posted on 26/01</p>
                            </div>
                          </div>
                          <div className="my-job-application-card">
                            <div className="my-job-application-info">
                              <h4 className="name">Risk Specialist</h4>
                              <p className="site-url">Wise</p>
                              <div className="meta">
                                <p className="applications">Singapore</p>
                              </div>
                              <div className="my-jobs-buttons">
                                <Link to="/employers-job-view" target="_blank" className="view-job-btn">View job</Link>
                              </div>
                            </div>
                            <div className="my-job-application-applied-date">
                              <p className="created-at">Posted on 26/01</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="applicants">
                      <h3>Applicants</h3>
                      <div className="my-job-application-sec">
                        <div className="my-job-application-header">
                          <div className="my-job-application-select">
                            <Row className="align-items-center">
                              <Col md={3}>
                                <p className="total-count-jobs">2 jobs</p>
                              </Col>
                              <Col md={9}>
                                <Form.Group className="mb-0">
                                  <Form.Control type="text" placeholder="Search" />
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                          <div className="page-controls">
                            <p className="page-info">Page 1 / 1</p>
                          </div>
                        </div>
                        <div className="my-job-application-body">
                          <div className="employer-applicants-sec">
                            <ul className="list-unstyled">
                              <Media as="li">
                                <Link to="#">
                                  <div className="employers-applicants-card">
                                    <div className="employers-applicants-img-sec">
                                      <Image
                                        src={window.location.origin + `/assets/img/user.png`}
                                        alt="Image"
                                        className="employers-applicants-img"
                                      />
                                    </div>
                                    <div className="employers-applicants-info job-list-info">
                                      <h4 className="position">Product Designer</h4>
                                      <h6 className="company">Kraken</h6>
                                      <div className="meta">
                                        <p className="location">
                                          <span className="city">South Korea</span>
                                          <span className="remote">Remote</span>
                                        </p>
                                        <p className="type">Full-time</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="job-list-action-sec">
                                    <p className="time-ago">1d</p>
                                    <Button className="save-btn"></Button>
                                  </div>
                                </Link>
                              </Media>
                              <Media as="li">
                                <Link to="#">
                                  <div className="employers-applicants-card">
                                    <div className="employers-applicants-img-sec">
                                      <Image
                                        src={window.location.origin + `/assets/img/user.png`}
                                        alt="Image"
                                        className="employers-applicants-img"
                                      />
                                    </div>
                                    <div className="employers-applicants-info job-list-info">
                                      <h4 className="position">Product Designer</h4>
                                      <h6 className="company">Kraken</h6>
                                      <div className="meta">
                                        <p className="location">
                                          <span className="city">South Korea</span>
                                          <span className="remote">Remote</span>
                                        </p>
                                        <p className="type">Full-time</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="job-list-action-sec">
                                    <p className="time-ago">1d</p>
                                    <Button className="save-btn"></Button>
                                  </div>
                                </Link>
                              </Media>
                              <Media as="li">
                                <Link to="#">
                                  <div className="employers-applicants-card">
                                    <div className="employers-applicants-img-sec">
                                      <Image
                                        src={window.location.origin + `/assets/img/user.png`}
                                        alt="Image"
                                        className="employers-applicants-img"
                                      />
                                    </div>
                                    <div className="employers-applicants-info job-list-info">
                                      <h4 className="position">Product Designer</h4>
                                      <h6 className="company">Kraken</h6>
                                      <div className="meta">
                                        <p className="location">
                                          <span className="city">South Korea</span>
                                          <span className="remote">Remote</span>
                                        </p>
                                        <p className="type">Full-time</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="job-list-action-sec">
                                    <p className="time-ago">1d</p>
                                    <Button className="save-btn"></Button>
                                  </div>
                                </Link>
                              </Media>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="billing">
                      <h3>Billing</h3>
                      <p className="separator">Billing details</p>
                      <Form className="profile-form-sec auth-form">
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>
                                Address
                              </Form.Label>
                              <Form.Control placeholder="" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>
                                City
                              </Form.Label>
                              <Form.Control placeholder="" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>
                                State
                              </Form.Label>
                              <Form.Control placeholder="" />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>
                                Zip
                              </Form.Label>
                              <Form.Control placeholder="" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <h5 className="label-about-your-job">Country</h5>
                            <Form.Group className="mb-0 location-form-select">
                              <select className="form-select form-control" aria-label="Default select example">
                                <option selected>India</option>
                                <option value="1">USA</option>
                                <option value="2">UAE</option>
                              </select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <p className="separator">Payment method</p>
                        <div className="no-payment-method">
                          No payment method on file.
                        </div>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="change-password">
                      <p className="separator">Change Password</p>
                      <Form className="profile-form-sec auth-form">
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>
                                Old Password
                              </Form.Label>
                              <Form.Control placeholder="" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>
                                New Password
                              </Form.Label>
                              <Form.Control placeholder="" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>
                                Confirm Password
                              </Form.Label>
                              <Form.Control placeholder="" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="emp-profile-action-sec">
                              <Link to="#" className="default-btn">
                                Reset Password
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="delete-account">
                      <p className="separator">Delete Account</p>
                      <Form className="profile-form-sec auth-form">
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>
                                Enter the Password
                              </Form.Label>
                              <Form.Control placeholder="" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <p>Once your account is deleted, you will lose your account and review details.</p>
                        <Row>
                          <Col md={12}>
                            <div className="emp-profile-action-sec">
                              <Link to="#" className="default-btn">
                                Submit
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="notification-prefeneces">
                      <p className="separator">Notifications Prefeneces</p>
                      <Form className="profile-form-sec auth-form">
                        <Row>
                          <Col md={12}>
                            <div className="inputGroup">
                              <input id="option2" name="option2" type="checkbox" />
                              <label for="option2 " className="ml-2">
                                <span>Email</span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="inputGroup">
                              <input id="option3" name="option3" type="checkbox" />
                              <label for="option3 " className="ml-2">
                                <span>Notification</span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </div>
    </>
  );
};

export default (EmployersProfile);
