import {
    FETCH_CLOSED_APPLICATIONS_START,
    FETCH_CLOSED_APPLICATIONS_SUCCESS,
    FETCH_CLOSED_APPLICATIONS_FAILURE,
    FETCH_NEW_APPLICATIONS_START,
    FETCH_NEW_APPLICATIONS_SUCCESS,
    FETCH_NEW_APPLICATIONS_FAILURE,
} from "./ActionConstant";
  
export function fetchClosedApplicationsStart(data) {
    return {
        type: FETCH_CLOSED_APPLICATIONS_START,
        data,
    };
}
  
export function fetchClosedApplicationsSuccess(data) {
    return {
        type: FETCH_CLOSED_APPLICATIONS_SUCCESS,
        data,
    };
}
  
export function fetchClosedApplicationsFailure(error) {
    return {
        type: FETCH_CLOSED_APPLICATIONS_FAILURE,
        error,
    };
}

export function fetchNewApplicationsStart(data) {
    return {
        type: FETCH_NEW_APPLICATIONS_START,
        data,
    };
}

export function fetchNewApplicationsSuccess(data) {
    return {
        type: FETCH_NEW_APPLICATIONS_SUCCESS,
        data,
    };
}
  
export function fetchNewApplicationsFailure(error) {
    return {
        type: FETCH_NEW_APPLICATIONS_FAILURE,
        error,
    };
}
  