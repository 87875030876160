import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { 
    FETCH_EMPLOYER_SERVICE_LOCATION_START,
    FETCH_EMPLOYER_JOB_CATEGORY_START,
    FETCH_EMPLOYER_JOB_TYPE_START,
    FETCH_EMPLOYER_CITY_START,
    FETCH_EMPLOYER_REGION_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";
import {
    fetchEmployerJobTypeSuccess,
    fetchEmployerJobTypeFailure,
    fetchEmployerJobCategorySuccess,
    fetchEmployerJobCategoryFailure,
    fetchEmployerServiceLocationSuccess,
    fetchEmployerServiceLocationFailure,
    fetchEmployerRegionSuccess,
    fetchEmployerRegionFailure,
    fetchEmployerCityFailure,
    fetchEmployerCitySuccess,
} from "../actions/LookupAction";

function* fetchServiceLocationAPI(action) {
    try {
        const response = yield api.empPostMethod(
            "service_location_list", action.data
        );
        if (response.data.success) {
            yield put(fetchEmployerServiceLocationSuccess(response.data.data));
        } else {
            yield put(fetchEmployerServiceLocationFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchEmployerServiceLocationFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchCategoryAPI(action) {
    try {
        const response = yield api.empPostMethod(
            "categories_list", action.data
        );
        if (response.data.success) {
            yield put(fetchEmployerJobCategorySuccess(response.data.data));
        } else {
            yield put(fetchEmployerJobCategoryFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchEmployerJobCategoryFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchJobTypeAPI(action) {
    try {
        const response = yield api.empPostMethod(
            "job_type_list", action.data
        );
        if (response.data.success) {
            yield put(fetchEmployerJobTypeSuccess(response.data.data));
        } else {
            yield put(fetchEmployerJobTypeFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchEmployerJobTypeFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchCityAPI(action) {
    try {
        const response = yield api.empPostMethod(
            "cities_list", action.data
        );
        if (response.data.success) {
            yield put(fetchEmployerCitySuccess(response.data.data));
        } else {
            yield put(fetchEmployerCityFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchEmployerCityFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
function* fetchRegionAPI(action) {
    try {
        const response = yield api.empPostMethod(
            "regions_list", action.data
        );
        if (response.data.success) {
            yield put(fetchEmployerRegionSuccess(response.data.data));
        } else {
            yield put(fetchEmployerRegionFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchEmployerRegionFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}
export default function* pageSaga() {
    yield all([yield takeLatest(FETCH_EMPLOYER_SERVICE_LOCATION_START, fetchServiceLocationAPI)]);
    yield all([yield takeLatest(FETCH_EMPLOYER_JOB_CATEGORY_START, fetchCategoryAPI)]);
    yield all([yield takeLatest(FETCH_EMPLOYER_JOB_TYPE_START, fetchJobTypeAPI)]);
    yield all([yield takeLatest(FETCH_EMPLOYER_REGION_START, fetchRegionAPI)]);
    yield all([yield takeLatest(FETCH_EMPLOYER_CITY_START, fetchCityAPI)]);
}
