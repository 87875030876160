import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media, Form } from "react-bootstrap";
import {
    fetchCardDetailsStart,
    selectDefaultCardStart,
    deleteCardStart,
} from "../../store/actions/CardsAction";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import PaymentAddCardModal from "./PaymentAddCardModal";

const CardsIndex = (props) => {

    useEffect(() => {
        props.dispatch(fetchCardDetailsStart());
    }, []);
    
    const stripePromise = loadStripe(
        configuration.get("configData.stripe_publishable_key")
    );
    
    const [addCard, setAddCard] = useState(false);
    
    const closeAddCardModal = () => {
        setAddCard(false);
    };
    
    const [paymentAddCard, setPaymentAddCard] = useState(false);
    
    const closePaymentAddCardModal = () => {
        setPaymentAddCard(false);
    };
    
    const { cards } = props;

    return (
        <>
            <div className="card-mangement-sec">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="home-banner-info">
                                <h3>Card Management</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="card-details-box">
                                <Row>
                                    <Col md={12}>
                                        <Row>
                                            {cards.loading ? (
                                                "Loading"
                                            ) : cards.data.cards.length > 0 ? (
                                                <>
                                            {cards.data.cards.map((card) => (
                                                
                                                <Col md={4} key={card.company_card_id}>
                                                    <div className="cd-box">
                                                        <div className="card-details-top">
                                                            <div className="card-info">
                                                                <h5 className="title">XXXX XXXX XXXX {card.last_four}</h5>
                                                            </div>
                                                            <div className="card-icons">
                                                                <Image
                                                                    src={window.location.origin + `/assets/img/icon/wallet.svg`}
                                                                    alt="Image"
                                                                    className="card-img"
                                                                />
                                                            </div>
                                                        </div>
                                                        <h5 className="sub-title">{card.card_type}</h5>
                                                        <div className="card-details-bottom">
                                                            <div className="card-info">
                                                                {card.is_default == 1 ? (
                                                                    <p className="card-link-1 text-success">
                                                                        Default Card
                                                                    </p>
                                                                ) : (
                                                                    <Link
                                                                        className="card-link-1 text-info"
                                                                        onClick={() =>
                                                                            props.dispatch(
                                                                                selectDefaultCardStart({
                                                                                    company_card_id: card.company_card_id,
                                                                                })
                                                                            )
                                                                        }
                                                                    >
                                                                        Set as Default
                                                                    </Link>
                                                                )}

                                                            </div>
                                                            <div className="card-icons">
                                                                <Link to="#"
                                                                    onClick={() => {if(window.confirm("Are you sure to delete the card details?")){ 
                                                                        props.dispatch(
                                                                            deleteCardStart({
                                                                                company_card_id: card.company_card_id,
                                                                            })
                                                                        )};}
                                                                    }
                                                                >
                                                                    <Image
                                                                        src={window.location.origin + `/assets/img/icon/delete-icon.svg`}
                                                                        alt="Image"
                                                                        className="card-img-delete"
                                                                    />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                            </>
                                        ) : null}
                                        </Row>
                                        <div className="space-mg"></div>
                                        <Row>
                                            <Col md={4}>
                                                <div className="dash-box flex-center text-center" onClick={() => setPaymentAddCard(true)}>
                                                    <Image
                                                        src={window.location.origin + `/assets/img/icon/add-card.svg`}
                                                        alt="Image"
                                                        className="add-card-img"
                                                    />
                                                    <p className="desc">Add Card</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Elements stripe={stripePromise}>
                <PaymentAddCardModal
                    paymentAddCard={paymentAddCard}
                    closePaymentAddCardModal={closePaymentAddCardModal}
                />
            </Elements>
        </>
    );
};


const mapStateToPros = (state) => ({
    cards: state.cards.cardDetails,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(mapStateToPros, mapDispatchToProps)(translate(CardsIndex));