import React, { useContext, useEffect, useRef, useState } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { setTranslations } from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
// Layouts
import EmptyLayout from "../../components/layouts/EmptyLayout";
import AuthLayout from "../layouts/AuthLayout";
import HomeLayout from "../layouts/HomeLayout";

import CommonLayout from "../../components/layouts/CommonLayout";
import Profile from "../../components/profile/Profile";
import LandingPage from "../landingPage/LandingPage";
import PageNotFound from "../../components/404/PageNotFound";
// Home
import HomeIndex from "../landingPage/HomeIndex";
import CompanyListingIndex from "../landingPage/CompanyListingIndex";
import CategoryListingIndex from "../landingPage/CategoryListinIndex";
import LocationsIndex from "../landingPage/LocationsIndex";

// Users
import UserLoginIndex from "../User/Auth/LoginIndex";
import UserSignUpIndex from "../User/Auth/SignUp";
import UserSignUp from "../User/Signup/UserSignUp";
import UserLogout from "../User/Account/Logout";
import ProfileIndex from "../User/Account/ProfileIndex";
import ForgotPasswordIndex from "../User/Auth/ForgotPasswordIndex";
import UserResetPassword from "../User/Auth/ResetPassword";
import JobView from "../User/Account/JobView";
import AppliedJobs from "../User/Account/AppliedJobs";

// Employer
import LoginIndex from "../Auth/LoginIndex";
import SignUpIndex from "../Auth/SignUpIndex";
import Logout from "../Account/Logout";
import EmployerProfileIndex from "../Account/ProfileIndex";
import EmployerForgotPasswordIndex from "../Auth/ForgotPasswordIndex";
import ResetPassword from "../Auth/ResetPassword";

import AddBillingAddress from "../Auth/AddBillingAddress";
import AddPaymentMethod from "../Auth/AddPaymentMethod";

import CategoryListingSingleIndex from "../landingPage/CategoryListingSingleIndex";
import JobsListingIndex from "../landingPage/JobsListingIndex";
import CreateJobIndex from "../Post/CreateJobIndex";
import StaticPage from "../StaticPage/StaticPage";
import ChatIndex from "../Chat/ChatIndex";
import CardsIndex from "../CardManagement/CardsIndex";
import EmployersProfile from "../Employers/EmployersProfile";
import EmployerJobView from "../Employers/JobView";
import EmployerJobEdit from "../Employers/JobEdit";
import EmployersListJobs from "../Employers/EmployersListJobs";
import SubscriptionList from "../Subscriptions/SubscriptionList";
import SubscriptionHistory from "../Subscriptions/SubscriptionHistory";
import EmployersApplicants from "../Employers/EmployersApplicants";
import MobileChatRoom from "../Chat/MobileChatRoom";
import CompaniesIndex from "../Companies/CompaniesIndex";
import SingleCompanyIndex from "../Companies/SingleCompanyIndex";
import CompanySubscriptionList from "../CompanySubscriptions/SubscriptionList";
import CompanySubscriptionHistory from "../CompanySubscriptions/SubscriptionHistory";

setTranslations({ en, es });

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props} {...rest}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps} {...props} {...rest}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const App = () => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");

  let employerId = localStorage.getItem("employerId");
  let employerAccessToken = localStorage.getItem("employerAccessToken");
  let employerLoginStatus = localStorage.getItem("employerLoginStatus");

  const [loading, setLoading] = useState(true);

  const [configLoading, setConfigLoading] = useState(true);

  const [authentication, setAuthntication] = useState(
    userId && accessToken && userLoginStatus == "true" ? true : false
  );

  const [empAuthentication, setEmpAuthentication] = useState(
    employerId && employerAccessToken && employerLoginStatus == "true" ? true : false
  );
  
  // const { auth } = useContext(authContext);

  history.listen((location, action) => {
    userId = localStorage.getItem("userId");
    accessToken = localStorage.getItem("accessToken");
    userLoginStatus = localStorage.getItem("userLoginStatus");
    setLoading(true);
    // setAuthntication(
    //   userId && accessToken && userLoginStatus == "true" ? true : false
    // );
    document.body.scrollTop = 0;
  });

  // useEffect(() => {
  //   setAuthntication(auth.authStatus);
  // }, [auth.authStatus]);

  return (
    <>
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
        />
      </Helmet>
      <Switch>
        {/* <AppRoute path={"/profile"} component={Profile} layout={CommonLayout} /> */}
        {/* <AppRoute
          path={"/"}
          component={LandingPage}
          exact
          layout={EmptyLayout}
        /> */}

        <AppRoute
          path={"/"}
          component={HomeIndex}
          exact
          layout={HomeLayout}
        />

        <AppRoute
          path={"/user/login"}
          component={UserLoginIndex}
          layout={AuthLayout}
        />

        <AppRoute
          path={"/user/signup"}
          component={UserSignUp}
          layout={AuthLayout}
        />

        <AppRoute
          path={"/user/reset-password/:token"}
          component={UserResetPassword}
          layout={AuthLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/user/logout"}
          component={UserLogout}
          layout={HomeLayout}
        />
        
        <PrivateRoute
          authentication={authentication}
          path={"/user/applied-jobs"}
          component={AppliedJobs}
          layout={HomeLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/user/profile"}
          component={ProfileIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/add-billing-address"}
          component={AddBillingAddress}
          layout={AuthLayout}
        />

        <AppRoute
          path={"/add-payment-method"}
          component={AddPaymentMethod}
          layout={AuthLayout}
        />

        <AppRoute
          path={"/forgot-password"}
          component={ForgotPasswordIndex}
          layout={AuthLayout}
        />

        <AppRoute
          path={"/profile"}
          component={ProfileIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/job/:company_job_unique_id"}
          component={JobView}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/companies-old"}
          component={CompanyListingIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/companies"}
          component={CompaniesIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/company-view/:company_unique_id"}
          component={SingleCompanyIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/jobs-listing"}
          component={JobsListingIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/sectors"}
          component={CategoryListingIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/locations"}
          component={LocationsIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/category-listing-single"}
          component={CategoryListingSingleIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/employer/login"}
          component={LoginIndex}
          layout={AuthLayout}
        />

        <AppRoute
          path={"/employer/signup"}
          component={SignUpIndex}
          layout={AuthLayout}
        />

        <AppRoute
          path={"/employer/forgot-password"}
          component={EmployerForgotPasswordIndex}
          layout={AuthLayout}
        />

        <AppRoute
          path={"/employer/reset-password/:token"}
          component={ResetPassword}
          layout={AuthLayout}
        />
        
        <PrivateRoute
          authentication={empAuthentication}
          path={"/employer/logout"}
          component={Logout}
          layout={HomeLayout}
        />
        
        <PrivateRoute
          authentication={empAuthentication}
          path={"/employer/profile"}
          component={EmployerProfileIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/create-post"}
          component={CreateJobIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/static-page"}
          component={StaticPage}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/chat"}
          component={ChatIndex}
          layout={HomeLayout}
        />

        <PrivateRoute
          authentication={empAuthentication}
          path={"/cards"}
          component={CardsIndex}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/employers-profile"}
          component={EmployersProfile}
          layout={HomeLayout}
        />

        <PrivateRoute
          authentication={empAuthentication}
          path={"/employers-job-view/:company_job_unique_id"}
          component={EmployerJobView}
          layout={HomeLayout}
        />

        <PrivateRoute
          authentication={empAuthentication}
          path={"/job-edit/:company_job_unique_id"}
          component={EmployerJobEdit}
          layout={HomeLayout}
        />

        <PrivateRoute
          authentication={empAuthentication}
          path={"/employers/jobs"}
          component={EmployersListJobs}
          layout={HomeLayout}
        />

        <PrivateRoute
          authentication={empAuthentication}
          path={"/subscriptions"}
          component={SubscriptionList}
          layout={HomeLayout}
        />

        <PrivateRoute
          authentication={empAuthentication}
          path={"/company-subscriptions"}
          component={CompanySubscriptionList}
          layout={HomeLayout}
        />

        <PrivateRoute
          authentication={empAuthentication}
          path={"/subscription-history"}
          component={SubscriptionHistory}
          layout={HomeLayout}
        />

        <PrivateRoute
          authentication={empAuthentication}
          path={"/company-subscription-history"}
          component={CompanySubscriptionHistory}
          layout={HomeLayout}
        />
        
        <AppRoute
          path={"/employers-applicants"}
          component={EmployersApplicants}
          layout={HomeLayout}
        />

        <AppRoute
          path={"/mobile-chat-room"}
          component={MobileChatRoom}
          layout={HomeLayout}
        />

        <AppRoute path={"*"} component={PageNotFound} layout={EmptyLayout} />
      </Switch>
    </>
  );
};

export default App;
