import {
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_FORGOT_PASSWORD_START,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAILURE,
  USER_DELETE_ACCOUNT_START,
  USER_DELETE_ACCOUNT_SUCCESS,
  USER_DELETE_ACCOUNT_FAILURE,
  USER_CHANGE_PASSWORD_START,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_FAILURE,
  USER_RESET_PASSWORD_START,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAILURE,
  USER_REGISTER_START,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_START,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
} from "./ActionConstant";

// User login actions.
export function userLoginStart(data) {
  return {
    type: USER_LOGIN_START,
    data,
  };
}

export function userLoginSuccess(data) {
  return {
    type: USER_LOGIN_SUCCESS,
    data,
  };
}

export function userLoginFailure(error) {
  return {
    type: USER_LOGIN_FAILURE,
    error,
  };
}

// User Register actions.
export function userRegisterStart(data) {
  return {
    type: USER_REGISTER_START,
    data,
  };
}

export function userRegisterSuccess(data) {
  return {
    type: USER_REGISTER_SUCCESS,
    data,
  };
}

export function userRegisterFailure(error) {
  return {
    type: USER_REGISTER_FAILURE,
    error,
  };
}

// User FORGOT_PASSWORD actions.
export function userForgotPasswordStart(data) {
  return {
    type: USER_FORGOT_PASSWORD_START,
    data,
  };
}

export function userForgotPasswordSuccess(data) {
  return {
    type: USER_FORGOT_PASSWORD_SUCCESS,
    data,
  };
}

export function userForgotPasswordFailure(error) {
  return {
    type: USER_FORGOT_PASSWORD_FAILURE,
    error,
  };
}

// User Delete Account actions.
export function userDeleteAccountStart(data) {
  return {
    type: USER_DELETE_ACCOUNT_START,
    data,
  };
}

export function userDeleteAccountSuccess(data) {
  return {
    type: USER_DELETE_ACCOUNT_SUCCESS,
    data,
  };
}

export function userDeleteAccountFailure(error) {
  return {
    type: USER_DELETE_ACCOUNT_FAILURE,
    error,
  };
}

// Change password actions.
export function userChangePasswordStart(data) {
  return {
    type: USER_CHANGE_PASSWORD_START,
    data,
  };
}

export function userChangePasswordSuccess(data) {
  return {
    type: USER_CHANGE_PASSWORD_SUCCESS,
    data,
  };
}

export function userChangePasswordFailure(error) {
  return {
    type: USER_CHANGE_PASSWORD_FAILURE,
    error,
  };
}

// Get user details actions.
export function fetchUserDetailsStart(data) {
  return {
    type: FETCH_USER_DETAILS_START,
    data,
  };
}

export function fetchUserDetailsSuccess(data) {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    data,
  };
}

export function fetchUserDetailsFailure(error) {
  return {
    type: FETCH_USER_DETAILS_FAILURE,
    error,
  };
}

export function updateUserDetailsStart(data) {
  return {
    type: UPDATE_USER_DETAILS_START,
    data,
  };
}

export function updateUserDetailsSuccess(data) {
  return {
    type: UPDATE_USER_DETAILS_SUCCESS,
    data,
  };
}

export function updateUserDetailsFailure(error) {
  return {
    type: UPDATE_USER_DETAILS_FAILURE,
    error,
  };
}

export function userResetPasswordStart(data) {
  return {
    type: USER_RESET_PASSWORD_START,
    data,
  };
}

export function userResetPasswordSuccess(data) {
  return {
    type: USER_RESET_PASSWORD_SUCCESS,
    data,
  };
}

export function userResetPasswordFailure(error) {
  return {
    type: USER_RESET_PASSWORD_FAILURE,
    error,
  };
}
