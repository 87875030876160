import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Nav, Tab, Form, Media, Button } from "react-bootstrap";

const BrowseJobsCompanies = (props) => {

    const [filterValues, setFilterValues] = useState(['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']);

    return (
        <>
            <div className="browse-jobs-sec">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="browse-jobs-card">
                                <h6>Browse Jobs By Companies</h6>
                                <Form className="search-form-companies">
                                    <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            type="text"
                                            name="search_key"
                                            placeholder="Search companies.."
                                            onChange={(event) =>
                                                event.currentTarget.value &&
                                                event.currentTarget.value.length > 3
                                                    ? props.handleSearch(event, event.currentTarget.value)
                                                    : ""
                                            }
                                        />
                                    </Form.Group>
                                    {/* <div className="search-btn-sec">
                                        <Button onClick={(event)=>props.handleSearch(event,searchKey)} className="default-btn">
                                            Search
                                        </Button>
                                    </div> */}
                                </Form>
                                <div className="browse-jobs-list-sec">
                                    <ul className="list-unstyled browse-jobs-list-card">
                                        {filterValues.map((value) => (
                                            <Media as="li" key={value}>
                                                <Link to="#" onClick={(event) =>props.handleSearch(event, value)}>
                                                    {value}
                                                </Link>
                                            </Media>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default (BrowseJobsCompanies);