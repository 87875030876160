import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchSingleJobStart,saveWishlistStart,userJobApplyStart } from "../../../store/actions/UserJobAction";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../../components/helper/ToastNotification";
import { createNotification } from "react-redux-notify";

const JobView = (props) => {

    const [likeStatus, setLikeStatus] = useState("");

    useEffect(() => {
        if (props.singleJob.loading)
        props.dispatch(
            fetchSingleJobStart({
                company_job_unique_id: props.match.params.company_job_unique_id,
            })
        );
    }, []);

    const handleWishlist = (event, status,company_job_id) => {
        event.preventDefault();
        if(localStorage.getItem("userLoginStatus")) {
            setLikeStatus(status);
            props.dispatch(saveWishlistStart({ company_job_id: company_job_id}));
        } else {
            const notificationMessage = getErrorNotificationMessage('Login to continue');
            props.dispatch(createNotification(notificationMessage));
        }
    };

    const handleApply = (event, company_job_id) => {
        event.preventDefault();
        if(localStorage.getItem("userLoginStatus")) {
            props.dispatch(userJobApplyStart({ company_job_id: company_job_id }));
        } else {
            const notificationMessage = getErrorNotificationMessage('Login to continue');
            props.dispatch(createNotification(notificationMessage));
        }
    };

    return (
    <>
      <div className="single-view-job-sec">
        <Container>
            {props.singleJob.loading ? (
              ""
            ) : (
                <Row>
                    <Col md={9}>
                            <div className="single-view-job-card">
                                <div className="single-view-job-header">
                                    <div className="single-view-job-title-sec">
                                        <h3>{props.singleJob.data.company_job.name}</h3>
                                        <p className="posted-at">{props.singleJob.data.company_job.created}</p>
                                    </div>
                                    <div className="single-view-btn-sec">
                                        <Link to="#" onClick={(event) => handleApply(event,props.singleJob.data.company_job.company_job_id)} className="apply-btn">
                                            Apply
                                        </Link>
                                        {likeStatus !== "" ? (
                                            <>
                                                {likeStatus === "added" ? (
                                                    <Button 
                                                        className="save-btn active" 
                                                        onClick={(event) => handleWishlist(event,'removed',props.singleJob.data.company_job.company_job_id)}
                                                    >
                                                        Saved
                                                    </Button>
                                                ) : null}
                                                {likeStatus === "removed" ? (
                                                    <Button 
                                                        className="save-btn" 
                                                        onClick={(event) => handleWishlist(event,'added',props.singleJob.data.company_job.company_job_id)}
                                                    >
                                                        Save
                                                    </Button>
                                                ) : null}
                                            </>
                                        ) : props.singleJob.data.company_job.is_user_liked == 1 ? (
                                            <Button 
                                                className="save-btn active" 
                                                onClick={(event) => handleWishlist(event,'removed',props.singleJob.data.company_job.company_job_id)}
                                            >
                                                Saved
                                            </Button>
                                        ) : (
                                            <Button 
                                                className="save-btn" 
                                                onClick={(event) => handleWishlist(event,'added',props.singleJob.data.company_job.company_job_id)}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                <div className="meta">
                                    <p className="location">
                                        <span className="city">{props.singleJob.data.company_job.city_name},{props.singleJob.data.company_job.region_name}</span>
                                        <span className="remote">{props.singleJob.data.company_job.job_type_name}</span>
                                    </p>
                                    <p className="type">
                                        Full-time
                                    </p>
                                </div>
                                <div className="description-wrap">
                                    <div className="description">
                                        <p
                                        dangerouslySetInnerHTML={{
                                            __html: props.singleJob.data.company_job.description != undefined ? props.singleJob.data.company_job.description : "",
                                        }}
                                        ></p>
                                    </div>
                                    <div className="single-view-btn-sec">
                                        <Link to="#" onClick={(event) => handleApply(event,props.singleJob.data.company_job.company_job_id)} className="apply-btn">
                                            Apply
                                        </Link>
                                        {likeStatus !== "" ? (
                                            <>
                                                {likeStatus === "added" ? (
                                                    <Button 
                                                        className="save-btn active" 
                                                        onClick={(event) => handleWishlist(event,'removed',props.singleJob.data.company_job.company_job_id)}
                                                    >
                                                        Saved
                                                    </Button>
                                                ) : null}
                                                {likeStatus === "removed" ? (
                                                    <Button 
                                                        className="save-btn" 
                                                        onClick={(event) => handleWishlist(event,'added',props.singleJob.data.company_job.company_job_id)}
                                                    >
                                                        Save
                                                    </Button>
                                                ) : null}
                                            </>
                                        ) : props.singleJob.data.company_job.is_user_liked == 1 ? (
                                            <Button 
                                                className="save-btn active" 
                                                onClick={(event) => handleWishlist(event,'removed',props.singleJob.data.company_job.company_job_id)}
                                            >
                                                Saved
                                            </Button>
                                        ) : (
                                            <Button 
                                                className="save-btn" 
                                                onClick={(event) => handleWishlist(event,'added',props.singleJob.data.company_job.company_job_id)}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                    </Col>
                    <Col md={3}>
                        <div className="sticky-box">
                            <div className="single-view-company-box">
                                <a href={props.singleJob.data.company_job.company.website} target="_blank">
                                    <Image
                                        src={props.singleJob.data.company_job.company.picture}
                                        alt={props.singleJob.data.company_job.company.name}
                                        className="company-logo"
                                    />
                                </a>
                                <h4>{props.singleJob.data.company_job.company.name}</h4>
                                <p>{props.singleJob.data.company_job.company.company_tagline}</p>
                                <a href={props.singleJob.data.company_job.company.website} target="_blank" className="company-link">
                                    Website
                                </a>
                            </div>
                            <div className="single-view-share-box">
                                <h5>Share this job</h5>
                                <ul className="single-view-share-link list-unstyled">
                                    <Media as="li" className="bg-twiter">
                                        <Link to="#" target="_blank">
                                            <i className="fab fa-twitter"></i>
                                        </Link>
                                    </Media>
                                    <Media as="li" className="bg-facebook">
                                        <Link to="#">
                                            <i className="fab fa-facebook-f"></i>
                                        </Link>
                                    </Media>
                                    <Media as="li" className="bg-linkedin">
                                        <Link to="#">
                                            <i className="fab fa-linkedin-in"></i>
                                        </Link>
                                    </Media>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
      </div>
    </>
  );
};


const mapStateToPros = (state) => ({
    singleJob: state.userjobs.singleJob,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(mapStateToPros, mapDispatchToProps)(JobView);
  