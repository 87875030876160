import React, { useState , useEffect } from "react";
import { ToastContainer } from "react-toastify";
import HomeHeader from "./Header/HomeHeader";

const AuthLayout = (props) => {

    useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <HomeHeader/>
      <ToastContainer />
      <div className="main-warapper">
        {props.children}
      </div>
    </>
  );
};

export default AuthLayout;
