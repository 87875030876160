import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col } from "react-bootstrap";
import { userDeleteAccountStart } from "../../../store/actions/UserAction";
import { translate, t } from "react-multi-lang";

const DeleteAccount = (props) => {
    const [password, setPassword] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(userDeleteAccountStart({ password: password }));
    };

    return (
        <>
            <h3>Delete Account</h3>
            <Form 
                className="profile-form-sec auth-form"
                onSubmit={handleSubmit}
                id="form"
                method="post"
                name="form"
                autoComplete="off"
            >   
                <p className="">HOPE, SEE YOU SOON</p>
                <p className="">Note: Once you deleted account, you will lose your history and wishlist details</p>
                <Row>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                placeholder="Enter your password"
                                id="password"
                                type="password"
                                controlId="old_password"
                                name="password"
                                value={password}
                                onChange={(event) =>
                                    setPassword(event.currentTarget.value)
                                }
                            />
                        </Form.Group>
                    </Col>                    
                </Row>
                <Row>
                    <Col md={12} className="text-right">
                        <Button
                            className="default-btn"
                            onClick={handleSubmit}
                            disabled={props.deleteAcc.buttonDisable}
                            >
                            {props.deleteAcc.loadingButtonContent !== null
                                ? props.deleteAcc.loadingButtonContent
                                : 'Delete Account' }
                        </Button>
                    </Col>
                </Row>
            </Form>           
        </>
    );
};

const mapStateToPros = (state) => ({
  deleteAcc: state.users.deleteAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(DeleteAccount));
