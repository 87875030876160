import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Media, Form } from "react-bootstrap";

const AddPaymentMethod = (props) => {
  return (
    <>
      <div className="login-sec signup-sec">
        <Container>
            <Row className="justify-content-md-center">
                <Col md={12} xl={6} lg={6}>
                    <div className="signup-info-sec">
                        <h3>Start a profitable job board</h3>
                        <p>Try our modern, easy-to-use job board software and unlock the value in your audience.</p>
                        <ul className="list-unstyled features-list-sec">
                            <Media as="li">
                                100% free for 7 days with no job listing limits
                            </Media>
                            <Media as="li">
                                Get your board online in minutes
                            </Media>
                            <Media as="li">
                                Custom theme, your own domain, no code required
                            </Media>
                        </ul>
                    </div>
                    <div className="banner-rating-sec">
                    <div className="banner-rating-card">
                      <div className="banner-rating-img-sec">
                        <Image
                          src={window.location.origin + `/assets/img/capterra-logo.svg`}
                          alt="Image"
                          className="rating-logo"
                        />
                      </div>
                      <div className="rating-count-sec">
                        <p>4.9<span>/5</span></p>
                        <ul className="rating-start-sec list-unstyled">
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                        </ul>
                      </div>
                    </div>
                    <div className="banner-rating-card">
                      <div className="banner-rating-img-sec">
                        <Image
                          src={window.location.origin + `/assets/img/sourceforge-logo.png`}
                          alt="Image"
                          className="rating-logo"
                        />
                      </div>
                      <div className="rating-count-sec">
                        <p>4.7<span>/5</span></p>
                        <ul className="rating-start-sec list-unstyled">
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                        </ul>
                      </div>
                    </div>
                    <div className="banner-rating-card">
                      <div className="banner-rating-img-sec">
                        <Image
                          src={window.location.origin + `/assets/img/getapp-logo.svg`}
                          alt="Image"
                          className="rating-logo width-5"
                        />
                      </div>
                      <div className="rating-count-sec">
                        <p>4.9<span>/5</span></p>
                        <ul className="rating-start-sec list-unstyled">
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                        </ul>
                      </div>
                    </div>
                    <div className="banner-rating-card">
                      <div className="banner-rating-img-sec">
                        <Image
                          src={window.location.origin + `/assets/img/software-advice-logo.svg`}
                          alt="Image"
                          className="rating-logo"
                        />
                      </div>
                      <div className="rating-count-sec">
                        <p>4.9<span>/5</span></p>
                        <ul className="rating-start-sec list-unstyled">
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                          <Media as="li">
                            <i className="fas fa-star"></i>
                          </Media>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={12} xl={6} lg={6}>
                    <div className="login-card">
                        <div className="login-box signup-box">
                            <h4>Add a payment method</h4>
                            <Form className="auth-form">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Card number</Form.Label>
                                    <Form.Control type="number" placeholder="1234 1234 1234 1234" />
                                </Form.Group>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Expiration</Form.Label>
                                            <Form.Control type="text" placeholder="MM/YY" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>CVC</Form.Label>
                                            <Form.Control type="number" placeholder="CVC" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className="total-due-today-sec">
                                            <p>Total due today</p>
                                            <p><strong>$0</strong></p>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="btn-sec">
                                    <Link to="#" className="default-btn btn-block">
                                        Create Account
                                    </Link>
                                </div>
                                <div className="auth-footer-sec">
                                    <p>
                                        Why do I need to provide a credit card?
                                    </p>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  );
};

export default (AddPaymentMethod);
