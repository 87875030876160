import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import {
    employerResetPasswordStart,
} from "../../store/actions/EmployerAction";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";

const ResetPassword = (props) => {

    const [resetPasswordInputData, setResetPasswordInputData] = useState({});

    useEffect(() => {
        setResetPasswordInputData({
            ...resetPasswordInputData,
            reset_token: props.match.params.token,
        })
    }, []);

    const handleResetPassword = (event) => {
        event.preventDefault();
        props.dispatch(employerResetPasswordStart(resetPasswordInputData));
    };

    return (
    <>
        <div className="login-sec">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={10} xl={6} lg={6}>
                        <div className="login-card">
                            <div className="login-box">
                                <h4>Forgot password?</h4>
                                <Form className="auth-form" onSubmit={handleResetPassword} method="post">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control 
                                            type="password" 
                                            placeholder="Enter your new password"
                                            required
                                            value={resetPasswordInputData.email}
                                            name="password"
                                            onChange={(event) =>
                                                setResetPasswordInputData({
                                                    ...resetPasswordInputData,
                                                    password: event.currentTarget.value,
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control 
                                            type="password" 
                                            placeholder="Confirm Password"
                                            required
                                            value={resetPasswordInputData.email}
                                            name="password_confirmation"
                                            id="password_confirmation"
                                            onChange={(event) =>
                                                setResetPasswordInputData({
                                                    ...resetPasswordInputData,
                                                    password_confirmation: event.currentTarget.value,
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    <div className="btn-sec"> 
                                        <Button
                                            id="resetPassword"
                                            type="submit"
                                            onClick={handleResetPassword}
                                            className="default-btn btn-block"
                                            disabled={props.resetPassword.buttonDisable}
                                        >
                                            {props.resetPassword.loadingButtonContent !== null
                                            ? props.resetPassword.loadingButtonContent
                                            : "Reset Password"}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
    );
};

const mapStateToPros = (state) => ({
    resetPassword: state.employers.resetPasswordInputData,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(ResetPassword));
  
  