import {
    FETCH_COMPANY_SUBSCRIPTION_START,
    FETCH_COMPANY_SUBSCRIPTION_SUCCESS,
    FETCH_COMPANY_SUBSCRIPTION_FAILURE,
    FETCH_MY_COMPANY_SUBSCRIPTION_START,
    FETCH_MY_COMPANY_SUBSCRIPTION_SUCCESS,
    FETCH_MY_COMPANY_SUBSCRIPTION_FAILURE,
    COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_START,
    COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_SUCCESS,
    COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_FAILURE,
} from "../actions/ActionConstant";
  
const initialState = {
    subscription: {
        data: {
            subscriptions: [],
        },
        inputData: {},
        loading: true,
        error: false,
        skip: 0,
        length: 0,
    },
    mySubscription: {
        data: {
            subscriptions: [],
        },
        inputData: {},
        loading: true,
        error: false,
        skip: 0,
        length: 0,
    },
    paymentByCard: {
        inputData: {},
        loading: true,
        error: false,
        success: {},
        buttonDisable: false,
        loadingButtonContent: null,
    },
    buttonDisable: false,
    loadingButtonContent: null,
};
  
const CompanySubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANY_SUBSCRIPTION_START:
            return {
                ...state,
                subscription: {
                    inputData: action.data,
                    data: {
                        subscriptions: [...state.subscription.data.subscriptions],
                    },
                    loading: true,
                    error: false,
                    skip: state.subscription.skip,
                    length: state.subscription.length,
                },
            };
        case FETCH_COMPANY_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscription: {
                    inputData: action.data,
                    data: {
                        subscriptions: [
                            ...state.subscription.data.subscriptions,
                            ...action.data.company_subscriptions,
                        ],
                    },
                    loading: false,
                    error: false,
                    skip: action.data.company_subscriptions.length + state.subscription.skip,
                    length: action.data.company_subscriptions.length,
                },
            };
        case FETCH_COMPANY_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                subscription: {
                    inputData: action.data,
                    data: {
                        subscriptions: [],
                    },
                    loading: false,
                    error: action.error,
                    skip: state.subscription.skip,
                    length: state.subscription.length,
                },
            };
        case FETCH_MY_COMPANY_SUBSCRIPTION_START:
            return {
                ...state,
                mySubscription: {
                    inputData: action.data,
                    data: {
                        subscriptions: [...state.mySubscription.data.subscriptions],
                    },
                    loading: true,
                    error: false,
                    skip: state.mySubscription.skip,
                    length: state.mySubscription.length,
                },
            };
        case FETCH_MY_COMPANY_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                mySubscription: {
                    inputData: action.data,
                    data: {
                        subscriptions: [
                            ...state.mySubscription.data.subscriptions,
                            ...action.data.history,
                        ],
                    },
                    loading: false,
                    error: false,
                    skip: action.data.history.length + state.mySubscription.skip,
                    length: action.data.history.length,
                },
            };
        case FETCH_MY_COMPANY_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                mySubscription: {
                    inputData: action.data,
                    data: {
                        subscriptions: [],
                    },
                    loading: false,
                    error: action.error,
                    skip: state.mySubscription.skip,
                    length: state.mySubscription.length,
                },
            };
        case COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_START:
            return {
                ...state,
                paymentByCard: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                    success: {},
                    buttonDisable: true,
                    loadingButtonContent: "Processing.. Please wait...",
                },
            };
        case COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_SUCCESS:
            return {
                ...state,
                paymentByCard: {
                    loading: false,
                    error: false,
                    success: action.data,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_FAILURE:
            return {
                ...state,
                paymentByCard: {
                    loading: false,
                    error: action.error,
                    success: {},
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        default:
            return state;
    }
};
  
export default CompanySubscriptionReducer;
  