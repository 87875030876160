import {
    FETCH_WISHLIST_START,
    FETCH_WISHLIST_SUCCESS,
    FETCH_WISHLIST_FAILURE,
    SAVE_WISHLIST_START,
    SAVE_WISHLIST_SUCCESS,
    SAVE_WISHLIST_FAILURE,
    CLEAR_WISHLIST_START,
    CLEAR_WISHLIST_SUCCESS,
    CLEAR_WISHLIST_FAILURE,
    FETCH_JOBS_START,
    FETCH_JOBS_SUCCESS,
    FETCH_JOBS_FAILURE,
    FETCH_SINGLE_JOB_START,
    FETCH_SINGLE_JOB_SUCCESS,
    FETCH_SINGLE_JOB_FAILURE,
    FETCH_JOB_TYPE_LIST_START,
    FETCH_JOB_TYPE_LIST_SUCCESS,
    FETCH_JOB_TYPE_LIST_FAILURE,
    FETCH_JOB_CATEGORY_LIST_START,
    FETCH_JOB_CATEGORY_LIST_SUCCESS,
    FETCH_JOB_CATEGORY_LIST_FAILURE,
    FETCH_JOB_COMPANY_LIST_START,
    FETCH_JOB_COMPANY_LIST_SUCCESS,
    FETCH_JOB_COMPANY_LIST_FAILURE,
    USER_JOB_APPLY_START,
    USER_JOB_APPLY_SUCCESS,
    USER_JOB_APPLY_FAILURE,
    FETCH_SERVICE_LOCATION_START,
    FETCH_SERVICE_LOCATION_SUCCESS,
    FETCH_SERVICE_LOCATION_FAILURE,
    LOAD_MORE_JOBS_START,
    LOAD_MORE_JOBS_SUCCESS,
    LOAD_MORE_JOBS_FAILURE,
    FETCH_JOB_APPLIED_LIST_START,
    FETCH_JOB_APPLIED_LIST_SUCCESS,
    FETCH_JOB_APPLIED_LIST_FAILURE,
    FETCH_SALARY_FILTER_START,
    FETCH_SALARY_FILTER_SUCCESS,
    FETCH_SALARY_FILTER_FAILURE,
    FETCH_JOB_CITY_START,
    FETCH_JOB_CITY_SUCCESS,
    FETCH_JOB_CITY_FAILURE,
    FETCH_JOB_REGION_START,
    FETCH_JOB_REGION_SUCCESS,
    FETCH_JOB_REGION_FAILURE,
    USER_JOB_ALERT_SAVE_START,
    USER_JOB_ALERT_SAVE_SUCCESS,
    USER_JOB_ALERT_SAVE_FAILURE,
    FETCH_COMPANY_VIEW_START,
    FETCH_COMPANY_VIEW_SUCCESS,
    FETCH_COMPANY_VIEW_FAILURE,
    FETCH_SUBSCRIBED_COMPANY_START,
    FETCH_SUBSCRIBED_COMPANY_SUCCESS,
    FETCH_SUBSCRIBED_COMPANY_FAILURE,
} from "./ActionConstant";
  
export function fetchWishlistStart(data) {
    return {
        type: FETCH_WISHLIST_START,
        data,
    };
}
  
export function fetchWishlistSuccess(data) {
    return {
        type: FETCH_WISHLIST_SUCCESS,
        data,
    };
}
  
export function fetchWishlistFailure(error) {
    return {
        type: FETCH_WISHLIST_FAILURE,
        error,
    };
}

export function saveWishlistStart(data) {
    return {
        type: SAVE_WISHLIST_START,
        data,
    };
}
  
export function saveWishlistSuccess(data) {
    return {
        type: SAVE_WISHLIST_SUCCESS,
        data,
    };
}
  
export function saveWishlistFailure(error) {
    return {
        type: SAVE_WISHLIST_FAILURE,
        error,
    };
}

export function clearWishlistStart(data) {
    return {
        type: CLEAR_WISHLIST_START,
        data,
    };
}
  
export function clearWishlistSuccess(data) {
    return {
        type: CLEAR_WISHLIST_SUCCESS,
        data,
    };
}
  
export function clearWishlistFailure(error) {
    return {
        type: CLEAR_WISHLIST_FAILURE,
        error,
    };
}

export function fetchJobsStart(data) {
    return {
        type: FETCH_JOBS_START,
        data,
    };
}
  
export function fetchJobsSuccess(data) {
    return {
        type: FETCH_JOBS_SUCCESS,
        data,
    };
}
  
export function fetchJobsFailure(error) {
    return {
        type: FETCH_JOBS_FAILURE,
        error,
    };
}

export function fetchSingleJobStart(data) {
    return {
        type: FETCH_SINGLE_JOB_START,
        data,
    };
}
  
export function fetchSingleJobSuccess(data) {
    return {
        type: FETCH_SINGLE_JOB_SUCCESS,
        data,
    };
}
  
export function fetchSingleJobFailure(error) {
    return {
        type: FETCH_SINGLE_JOB_FAILURE,
        error,
    };
}

export function fetchJobTypeListStart(data) {
    return {
        type: FETCH_JOB_TYPE_LIST_START,
        data,
    };
}
  
export function fetchJobTypeListSuccess(data) {
    return {
        type: FETCH_JOB_TYPE_LIST_SUCCESS,
        data,
    };
}
  
export function fetchJobTypeListFailure(error) {
    return {
        type: FETCH_JOB_TYPE_LIST_FAILURE,
        error,
    };
}

export function fetchJobCategoryListStart(data) {
    return {
        type: FETCH_JOB_CATEGORY_LIST_START,
        data,
    };
}
  
export function fetchJobCategoryListSuccess(data) {
    return {
        type: FETCH_JOB_CATEGORY_LIST_SUCCESS,
        data,
    };
}
  
export function fetchJobCategoryListFailure(error) {
    return {
        type: FETCH_JOB_CATEGORY_LIST_FAILURE,
        error,
    };
}

export function fetchCompanyListStart(data) {
    return {
        type: FETCH_JOB_COMPANY_LIST_START,
        data,
    };
}
  
export function fetchCompanyListSuccess(data) {
    return {
        type: FETCH_JOB_COMPANY_LIST_SUCCESS,
        data,
    };
}
  
export function fetchCompanyListFailure(error) {
    return {
        type: FETCH_JOB_COMPANY_LIST_FAILURE,
        error,
    };
}

export function userJobApplyStart(data) {
    return {
        type: USER_JOB_APPLY_START,
        data,
    };
}
  
export function userJobApplySuccess(data) {
    return {
        type: USER_JOB_APPLY_SUCCESS,
        data,
    };
}
  
export function userJobApplyFailure(error) {
    return {
        type: USER_JOB_APPLY_FAILURE,
        error,
    };
}

export function fetchServiceLocationStart(data) {
    return {
        type: FETCH_SERVICE_LOCATION_START,
        data,
    };
}
  
export function fetchServiceLocationSuccess(data) {
    return {
        type: FETCH_SERVICE_LOCATION_SUCCESS,
        data,
    };
}
  
export function fetchServiceLocationFailure(error) {
    return {
        type: FETCH_SERVICE_LOCATION_FAILURE,
        error,
    };
}

export function loadMoreJobsStart(data) {
    return {
        type: LOAD_MORE_JOBS_START,
        data,
    };
}
  
export function loadMoreJobsSuccess(data) {
    return {
        type: LOAD_MORE_JOBS_SUCCESS,
        data,
    };
}
  
export function loadMoreJobsFailure(error) {
    return {
        type: LOAD_MORE_JOBS_FAILURE,
        error,
    };
}

export function fetchJobAppliedListStart(data) {
    return {
        type: FETCH_JOB_APPLIED_LIST_START,
        data,
    };
}
  
export function fetchJobAppliedListSuccess(data) {
    return {
        type: FETCH_JOB_APPLIED_LIST_SUCCESS,
        data,
    };
}
  
export function fetchJobAppliedListFailure(error) {
    return {
        type: FETCH_JOB_APPLIED_LIST_FAILURE,
        error,
    };
}

export function fetchSalaryFilterStart(data) {
    return {
        type: FETCH_SALARY_FILTER_START,
        data,
    };
}
  
export function fetchSalaryFilterSuccess(data) {
    return {
        type: FETCH_SALARY_FILTER_SUCCESS,
        data,
    };
}
  
export function fetchSalaryFilterFailure(error) {
    return {
        type: FETCH_SALARY_FILTER_FAILURE,
        error,
    };
}

export function fetchJobCityStart(data) {
    return {
        type: FETCH_JOB_CITY_START,
        data,
    };
}
  
export function fetchJobCitySuccess(data) {
    return {
        type: FETCH_JOB_CITY_SUCCESS,
        data,
    };
}
  
export function fetchJobCityFailure(error) {
    return {
        type: FETCH_JOB_CITY_FAILURE,
        error,
    };
}

export function fetchJobRegionStart(data) {
    return {
        type: FETCH_JOB_REGION_START,
        data,
    };
}
  
export function fetchJobRegionSuccess(data) {
    return {
        type: FETCH_JOB_REGION_SUCCESS,
        data,
    };
}
  
export function fetchJobRegionFailure(error) {
    return {
        type: FETCH_JOB_REGION_FAILURE,
        error,
    };
}

export function userJobAlertSaveStart(data) {
    return {
        type: USER_JOB_ALERT_SAVE_START,
        data,
    };
}
  
export function userJobAlertSaveSuccess(data) {
    return {
        type: USER_JOB_ALERT_SAVE_SUCCESS,
        data,
    };
}
  
export function userJobAlertSaveFailure(error) {
    return {
        type: USER_JOB_ALERT_SAVE_FAILURE,
        error,
    };
}

export function fetchCompanyViewStart(data) {
    return {
        type: FETCH_COMPANY_VIEW_START,
        data,
    };
}
  
export function fetchCompanyViewSuccess(data) {
    return {
        type: FETCH_COMPANY_VIEW_SUCCESS,
        data,
    };
}
  
export function fetchCompanyViewFailure(error) {
    return {
        type: FETCH_COMPANY_VIEW_FAILURE,
        error,
    };
}

export function fetchSubscribedCompanyStart(data) {
    return {
        type: FETCH_SUBSCRIBED_COMPANY_START,
        data,
    };
}
  
export function fetchSubscribedCompanySuccess(data) {
    return {
        type: FETCH_SUBSCRIBED_COMPANY_SUCCESS,
        data,
    };
}
  
export function fetchSubscribedCompanyFailure(error) {
    return {
        type: FETCH_SUBSCRIBED_COMPANY_FAILURE,
        error,
    };
}