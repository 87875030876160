import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { 
    FETCH_CLOSED_APPLICATIONS_START,
    FETCH_NEW_APPLICATIONS_START 
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";
import {
    fetchClosedApplicationsSuccess,
    fetchClosedApplicationsFailure,
    fetchNewApplicationsSuccess,
    fetchNewApplicationsFailure,
} from "../actions/ApplicationAction";

function* fetchClosedApplicationsAPI(action) {
  try {
    const response = yield api.empPostMethod(
      "job_applicants_list", action.data
    );
    if (response.data.success) {
      yield put(fetchClosedApplicationsSuccess(response.data.data));
    } else {
      yield put(fetchClosedApplicationsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchClosedApplicationsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchNewApplicationsAPI(action) {
    try {
      const response = yield api.empPostMethod(
        "job_applicants_list", action.data
      );
      if (response.data.success) {
        yield put(fetchNewApplicationsSuccess(response.data.data));
      } else {
        yield put(fetchNewApplicationsFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(createNotification(notificationMessage));
      }
    } catch (error) {
      yield put(fetchNewApplicationsFailure(error));
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
    }
  }

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_CLOSED_APPLICATIONS_START, fetchClosedApplicationsAPI)]);
  yield all([yield takeLatest(FETCH_NEW_APPLICATIONS_START, fetchNewApplicationsAPI)]);
}
