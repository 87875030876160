import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Media,
  Form,
} from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchJobCategoryListStart } from "../../store/actions/UserJobAction";
import useInfiniteScroll from "../helper/useInfiniteScroll";
const queryString = require("query-string");

const CategoryListingIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchJobCategoryListStart({loadMore: ""}));
  }, []);

  const fetchCategoryData = () => {
    setTimeout(() => {
      if (props.categories.length !== 0) {
        props.dispatch(
          fetchJobCategoryListStart({
            skip: props.categories.skip,
            loadMore: "loadMore"
          })
        );
        setIsFetching(false);
      } else {
        setNoMoreData(true);
      }
    }, 3000);
  };
  var location = {}

  const handleCategoryRedirect = (category) => {
    console.log(category);
    const query = queryString.stringify(
      {sector : [category.category_unique_id]},
      {arrayFormatSeparator: "|" }
    );
    location = {
      pathname : `/jobs-listing`,
      search : query
    }
    return location;
  }

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchCategoryData);

  const [noMoreData, setNoMoreData] = useState(false);

  return (
    <>
      <div className="category-listing-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="home-banner-info">
                <h3>Target your job search.</h3>
                <p>
                  Whether you are looking for your next role in an established
                  bank or joining the next Buy Now Pay Later success story,
                  Fintexer has simplified the Fintech space into 18 categories
                  for a more targeted search.
                </p>
              </div>
            </Col>
          </Row>
          {props.categories.loading ? (
            "Loading"
          ) : props.categories.data.categories.length > 0 ? (
            <>
              <Row className="category-title-sec">
                <Col md={12}>
                  <h5>
                    Browse {props.categories.data.categories.length} sectors
                  </h5>
                </Col>
              </Row>
              <Row>
                {props.categories.data.categories.map((category) => (
                  <Col md={4}>
                    <Link
                      to={handleCategoryRedirect(category)}
                    >
                      <div className="job-category-listing-card">
                        <div className="job-category-listing-logo-bg"></div>
                        <div className="job-category-icon-sec"></div>
                        <div className="job-category-listing-info">
                          <h4>{category.name}</h4>
                        </div>
                        <div className="job-category-listing-counting">
                          <p className="category-jobs">
                            <span>{category.total_jobs} jobs</span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            ""
          )}
          {noMoreData !== true ? (
            <>{isFetching && "Fetching more items..."}</>
          ) : (
            "No More Data"
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  categories: state.userjobs.jobCategories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(CategoryListingIndex));
