import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media, Form} from "react-bootstrap";
import StepZilla from "react-stepzilla";
import Step1 from "./Step1";
import Step2 from "./Step2";
import "./wizard.css";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import {
  fetchEmployerDetailsStart,
} from "../../../store/actions/EmployerAction";

const UserSignUp = (props) => {

  const [stepsNavigation, setStepsNavigation] = useState(true);

  const [initalStep, setInitalStep] = useState(0);

  const [signupInputData, setSignupInputData] = useState({});

  const steps =
    [
      {name: 'Basic Details', component: <Step1 setSignupInputData={setSignupInputData} signupInputData={signupInputData}/>},
      {name: 'Address', component: <Step2 setSignupInputData={setSignupInputData} signupInputData={signupInputData}/>}
    ]

  return (
    <>
      <div className="create-post-sec">
        <Container>
           <Row>
              <Col md={12}>
                <div className="home-banner-info">
                    <h3>Create your job seeker profile.</h3>
                    <p>All fields are optional. Your information will not be shared with third parties.</p>
                </div>
              </Col>
           </Row>
           {/* <Row>
             <Col md={12}>
                <div className="create-post-banner-sec">
                  <Image
                      src={window.location.origin + `/assets/img/create-post-banner.png`}
                      alt="Image"
                      className="create-post-banner"
                  />
                </div>
             </Col>
           </Row> */}
            {initalStep === "" ? null : (
              <Row>
                <Col md={12}>
                  <div className='step-progress mt-3'>
                    <StepZilla 
                      steps={steps}
                      showSteps={true}
                      stepsNavigation={true} 
                      prevBtnOnLastStep={true} 
                      preventEnterSubmission={true}
                      nextTextOnFinalActionStep={"Next"}
                      startAtStep={initalStep}
                    />
                  </div>
                </Col>
              </Row>
            )}
           
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.employers.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(UserSignUp));