import { all, fork } from "redux-saga/effects";
import UserSaga from "./UserSaga";
import PageSaga from "./PageSaga";
import EmployerSaga from "./EmployerSaga";
import UserJobSaga from "./UserJobSaga";
import DashboardSaga from "./DashboardSaga";
import JobSaga from "./JobSaga";
import ApplicationSaga from "./ApplicationSaga";
import SubscriptionSaga from "./SubscriptionSaga";
import CardsSaga from "./CardsSaga";
import LookupSaga from "./LookupSaga";
import CompanySubscriptionSaga from "./CompanySubscriptionSaga";

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(PageSaga)]);
  yield all([fork(UserJobSaga)]);
  yield all([fork(EmployerSaga)]);
  yield all([fork(DashboardSaga)]);
  yield all([fork(JobSaga)]);
  yield all([fork(ApplicationSaga)]);
  yield all([fork(SubscriptionSaga)]);
  yield all([fork(CardsSaga)]);
  yield all([fork(LookupSaga)]);
  yield all([fork(CompanySubscriptionSaga)]);
}
