import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { 
    EMPLOYER_FETCH_JOB_START,
    ADD_JOB_START,
    UPDATE_JOB_START,
    DELETE_JOB_START,
    PUBLISH_JOB_START,
    EMPLOYER_FETCH_SINGLE_JOB_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";
import {
    fetchJobSuccess,
    fetchJobFailure,
    addJobSuccess,
    addJobFailure,
    updateJobSuccess,
    updateJobFailure,
    publishJobSuccess,
    publishJobFailure,
    deleteJobSuccess,
    deleteJobFailure,
    fetchSingleJobSuccess,
    fetchSingleJobFailure,
    fetchJobStart,
} from "../actions/JobAction";

function* fetchJobAPI(action) {
    try {
        const response = yield api.empPostMethod("company_job_list", action.data);
        if (response.data.success) {
            yield put(fetchJobSuccess(response.data.data));
        } else {
            yield put(fetchJobFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchJobFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* addJobAPI(action) {
    try {
        const response = yield api.empPostMethod("company_job_save", action.data);
        if (response.data.success) {
            yield put(addJobSuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
        } else {
            yield put(addJobFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(addJobFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* updateJobAPI(action) {
    try {
        const response = yield api.empPostMethod("static_pages_view", action.data);
        if (response.data.success) {
            yield put(updateJobSuccess(response.data.data));
        } else {
            yield put(updateJobFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(updateJobFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* deleteJobAPI(action) {
    try {
        const response = yield api.empPostMethod("company_job_delete", action.data);
        if (response.data.success) {
            yield put(deleteJobSuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
            yield put(fetchJobStart({loadMore:''}));
        } else {
            yield put(deleteJobFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(deleteJobFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* publishJobAPI(action) {
    try {
        const response = yield api.empPostMethod("company_job_publish", action.data);
        if (response.data.success) {
            yield put(publishJobSuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
            yield put(fetchJobStart({loadMore:''}));
        } else {
            yield put(publishJobFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(publishJobFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchSingleJobAPI(action) {
    try {
        const response = yield api.empPostMethod("company_job_view", action.data);
        if (response.data.success) {
            yield put(fetchSingleJobSuccess(response.data.data));
        } else {
            yield put(fetchSingleJobFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchSingleJobFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

export default function* pageSaga() {
    yield all([yield takeLatest(EMPLOYER_FETCH_JOB_START, fetchJobAPI)]);
    yield all([yield takeLatest(ADD_JOB_START, addJobAPI)]);
    yield all([yield takeLatest(UPDATE_JOB_START, updateJobAPI)]);
    yield all([yield takeLatest(DELETE_JOB_START, deleteJobAPI)]);
    yield all([yield takeLatest(PUBLISH_JOB_START, publishJobAPI)]);
    yield all([yield takeLatest(EMPLOYER_FETCH_SINGLE_JOB_START, fetchSingleJobAPI)]);
}
