export const USER_CHANGE_PASSWORD_START = "USER_CHANGE_PASSWORD_START";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_FAILURE = "USER_CHANGE_PASSWORD_FAILURE";

export const USER_DELETE_ACCOUNT_START = "USER_DELETE_ACCOUNT_START";
export const USER_DELETE_ACCOUNT_SUCCESS = "USER_DELETE_ACCOUNT_SUCCESS";
export const USER_DELETE_ACCOUNT_FAILURE = "USER_DELETE_ACCOUNT_FAILURE";

export const USER_LOGIN_START = "USER_LOGIN_START";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_REGISTER_START = "USER_REGISTER_START";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";

export const USER_FORGOT_PASSWORD_START = "USER_FORGOT_PASSWORD_START";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_FAILURE = "USER_FORGOT_PASSWORD_FAILURE";

export const USER_RESET_PASSWORD_START = "USER_RESET_PASSWORD_START";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAILURE = "USER_RESET_PASSWORD_FAILURE";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

export const NOTIFICATION_STATUS_UPDATE_START =
  "NOTIFICATION_STATUS_UPDATE_START";
export const NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_STATUS_UPDATE_FAILURE =
  "NOTIFICATION_STATUS_UPDATE_FAILURE";

// Wishlist Management constant
export const FETCH_WISHLIST_START = "FETCH_WISHLIST_START";
export const FETCH_WISHLIST_SUCCESS = "FETCH_WISHLIST_SUCCESS";
export const FETCH_WISHLIST_FAILURE = "FETCH_WISHLIST_FAILURE";

export const SAVE_WISHLIST_START = "SAVE_WISHLIST_START";
export const SAVE_WISHLIST_SUCCESS = "SAVE_WISHLISTSUCCESS";
export const SAVE_WISHLIST_FAILURE = "SAVE_WISHLIST_FAILURE";

export const CLEAR_WISHLIST_START = "CLEAR_WISHLIST_START";
export const CLEAR_WISHLIST_SUCCESS = "CLEAR_WISHLIST_SUCCESS";
export const CLEAR_WISHLIST_FAILURE = "CLEAR_WISHLIST_FAILURE";

export const FETCH_JOBS_START = "FETCH_JOBS_START";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE";

export const FETCH_SINGLE_JOB_START = "FETCH_SINGLE_JOB_START";
export const FETCH_SINGLE_JOB_SUCCESS = "FETCH_SINGLE_JOB_SUCCESS";
export const FETCH_SINGLE_JOB_FAILURE = "FETCH_SINGLE_JOB_FAILURE";

export const FETCH_JOB_TYPE_LIST_START = "FETCH_JOB_TYPE_LIST_START";
export const FETCH_JOB_TYPE_LIST_SUCCESS = "FETCH_JOB_TYPE_LIST_SUCCESS";
export const FETCH_JOB_TYPE_LIST_FAILURE = "FETCH_JOB_TYPE_LIST_FAILURE";

export const FETCH_JOB_CATEGORY_LIST_START = "FETCH_JOB_CATEGORY_LIST_START";
export const FETCH_JOB_CATEGORY_LIST_SUCCESS = "FETCH_JOB_CATEGORY_LIST_SUCCESS";
export const FETCH_JOB_CATEGORY_LIST_FAILURE = "FETCH_JOB_CATEGORY_LIST_FAILURE";

export const FETCH_JOB_COMPANY_LIST_START = "FETCH_JOB_COMPANY_LIST_START";
export const FETCH_JOB_COMPANY_LIST_SUCCESS = "FETCH_JOB_COMPANY_LIST_SUCCESS";
export const FETCH_JOB_COMPANY_LIST_FAILURE = "FETCH_JOB_COMPANY_LIST_FAILURE";

export const FETCH_SERVICE_LOCATION_START = "FETCH_SERVICE_LOCATION_START";
export const FETCH_SERVICE_LOCATION_SUCCESS = "FETCH_SERVICE_LOCATION_SUCCESS";
export const FETCH_SERVICE_LOCATION_FAILURE = "FETCH_SERVICE_LOCATION_FAILURE";

export const USER_JOB_APPLY_START = "USER_JOB_APPLY_START";
export const USER_JOB_APPLY_SUCCESS = "USER_JOB_APPLY_SUCCESS";
export const USER_JOB_APPLY_FAILURE = "USER_JOB_APPLY_FAILURE";

export const EMPLOYER_CHANGE_PASSWORD_START = "EMPLOYER_CHANGE_PASSWORD_START";
export const EMPLOYER_CHANGE_PASSWORD_SUCCESS = "EMPLOYER_CHANGE_PASSWORD_SUCCESS";
export const EMPLOYER_CHANGE_PASSWORD_FAILURE = "EMPLOYER_CHANGE_PASSWORD_FAILURE";

export const EMPLOYER_DELETE_ACCOUNT_START = "EMPLOYER_DELETE_ACCOUNT_START";
export const EMPLOYER_DELETE_ACCOUNT_SUCCESS = "EMPLOYER_DELETE_ACCOUNT_SUCCESS";
export const EMPLOYER_DELETE_ACCOUNT_FAILURE = "EMPLOYER_DELETE_ACCOUNT_FAILURE";

export const EMPLOYER_LOGIN_START = "EMPLOYER_LOGIN_START";
export const EMPLOYER_LOGIN_SUCCESS = "EMPLOYER_LOGIN_SUCCESS";
export const EMPLOYER_LOGIN_FAILURE = "EMPLOYER_LOGIN_FAILURE";

export const EMPLOYER_REGISTER_START = "EMPLOYER_REGISTER_START";
export const EMPLOYER_REGISTER_SUCCESS = "EMPLOYER_REGISTER_SUCCESS";
export const EMPLOYER_REGISTER_FAILURE = "EMPLOYER_REGISTER_FAILURE";

export const EMPLOYER_FORGOT_PASSWORD_START = "EMPLOYER_FORGOT_PASSWORD_START";
export const EMPLOYER_FORGOT_PASSWORD_SUCCESS = "EMPLOYER_FORGOT_PASSWORD_SUCCESS";
export const EMPLOYER_FORGOT_PASSWORD_FAILURE = "EMPLOYER_FORGOT_PASSWORD_FAILURE";

export const EMPLOYER_RESET_PASSWORD_START = "EMPLOYER_RESET_PASSWORD_START";
export const EMPLOYER_RESET_PASSWORD_SUCCESS = "EMPLOYER_RESET_PASSWORD_SUCCESS";
export const EMPLOYER_RESET_PASSWORD_FAILURE = "EMPLOYER_RESET_PASSWORD_FAILURE";

export const UPDATE_EMPLOYER_DETAILS_START = "UPDATE_EMPLOYER_DETAILS_START";
export const UPDATE_EMPLOYER_DETAILS_SUCCESS = "UPDATE_EMPLOYER_DETAILS_SUCCESS";
export const UPDATE_EMPLOYER_DETAILS_FAILURE = "UPDATE_EMPLOYER_DETAILS_FAILURE";

export const FETCH_EMPLOYER_DETAILS_START = "FETCH_EMPLOYER_DETAILS_START";
export const FETCH_EMPLOYER_DETAILS_SUCCESS = "FETCH_EMPLOYER_DETAILS_SUCCESS";
export const FETCH_EMPLOYER_DETAILS_FAILURE = "FETCH_EMPLOYER_DETAILS_FAILURE";

export const EMPLOYER_NOTIFICATION_STATUS_UPDATE_START =
  "EMPLOYER_NOTIFICATION_STATUS_UPDATE_START";
export const EMPLOYER_NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "EMPLOYER_NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const EMPLOYER_NOTIFICATION_STATUS_UPDATE_FAILURE =
  "EMPLOYER_NOTIFICATION_STATUS_UPDATE_FAILURE";

export const FETCH_SUBSCRIPTION_START = "FETCH_SUBSCRIPTION_START";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";

export const FETCH_MY_SUBSCRIPTION_START = "FETCH_MY_SUBSCRIPTION_START";
export const FETCH_MY_SUBSCRIPTION_SUCCESS = "FETCH_MY_SUBSCRIPTION_SUCCESS";
export const FETCH_MY_SUBSCRIPTION_FAILURE = "FETCH_MY_SUBSCRIPTION_FAILURE";

export const FETCH_SINGLE_SUBSCRIPTION_START =
  "FETCH_SINGLE_SUBSCRIPTION_START";
export const FETCH_SINGLE_SUBSCRIPTION_SUCCESS =
  "FETCH_SINGLE_SUBSCRIPTION_SUCCESS";
export const FETCH_SINGLE_SUBSCRIPTION_FAILURE =
  "FETCH_SINGLE_SUBSCRIPTION_FAILURE";

export const SUBSCRIPTION_PAYMENT_BY_CARD_START = "SUBSCRIPTION_PAYMENT_BY_CARD_START";
export const SUBSCRIPTION_PAYMENT_BY_CARD_SUCCESS = "SUBSCRIPTION_PAYMENT_BY_CARD_SUCCESS";
export const SUBSCRIPTION_PAYMENT_BY_CARD_FAILURE = "SUBSCRIPTION_PAYMENT_BY_CARD_FAILURE";

// JObs
export const EMPLOYER_FETCH_JOB_START = "EMPLOYER_FETCH_JOB_START";
export const EMPLOYER_FETCH_JOB_SUCCESS = "EMPLOYER_FETCH_JOB_SUCCESS";
export const EMPLOYER_FETCH_JOB_FAILURE = "EMPLOYER_FETCH_JOB_FAILURE";

export const ADD_JOB_START = "ADD_JOB_START";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
export const ADD_JOB_FAILURE = "ADD_JOB_FAILURE";

export const EDIT_JOB = "EDIT_JOB";

export const UPDATE_JOB_START = "UPDATE_JOB_START";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_FAILURE = "UPDATE_JOB_FAILURE";

export const DELETE_JOB_START = "DELETE_JOB_START";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAILURE = "DELETE_JOB_FAILURE";

export const PUBLISH_JOB_START = "PUBLISH_JOB_START";
export const PUBLISH_JOB_SUCCESS = "PUBLISH_JOB_SUCCESS";
export const PUBLISH_JOB_FAILURE = "PUBLISH_JOB_FAILURE";

export const EMPLOYER_FETCH_SINGLE_JOB_START = "EMPLOYER_FETCH_SINGLE_JOB_START";
export const EMPLOYER_FETCH_SINGLE_JOB_SUCCESS = "EMPLOYER_FETCH_SINGLE_JOB_SUCCESS";
export const EMPLOYER_FETCH_SINGLE_JOB_FAILURE = "EMPLOYER_FETCH_SINGLE_JOB_FAILURE";

export const FETCH_EMPLOYER_DASHBOARD_START = "FETCH_EMPLOYER_DASHBOARD_START";
export const FETCH_EMPLOYER_DASHBOARD_SUCCESS = "FETCH_EMPLOYER_DASHBOARD_SUCCESS";
export const FETCH_EMPLOYER_DASHBOARD_FAILURE = "FETCH_EMPLOYER_DASHBOARD_FAILURE";

export const FETCH_CLOSED_APPLICATIONS_START = "FETCH_CLOSED_APPLICATIONS_START";
export const FETCH_CLOSED_APPLICATIONS_SUCCESS = "FETCH_CLOSED_APPLICATIONS_SUCCESS";
export const FETCH_CLOSED_APPLICATIONS_FAILURE = "FETCH_CLOSED_APPLICATIONS_FAILURE";

export const FETCH_NEW_APPLICATIONS_START = "FETCH_NEW_APPLICATIONS_START";
export const FETCH_NEW_APPLICATIONS_SUCCESS = "FETCH_NEW_APPLICATIONS_SUCCESS";
export const FETCH_NEW_APPLICATIONS_FAILURE = "FETCH_NEW_APPLICATIONS_FAILURE";

export const LOAD_MORE_JOBS_START = "LOAD_MORE_JOBS_START";
export const LOAD_MORE_JOBS_SUCCESS = "LOAD_MORE_JOBS_SUCCESS";
export const LOAD_MORE_JOBS_FAILURE = "LOAD_MORE_JOBS_FAILURE";

// Cards constants
export const FETCH_CARD_DETAILS_START = "FETCH_CARD_DETAILS_START";
export const FETCH_CARD_DETAILS_SUCCESS = "FETCH_CARD_DETAILS_SUCCESS";
export const FETCH_CARD_DETAILS_FAILURE = "FETCH_CARD_DETAILS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

export const FETCH_EMPLOYER_JOB_TYPE_START = "FETCH_EMPLOYER_JOB_TYPE_START";
export const FETCH_EMPLOYER_JOB_TYPE_SUCCESS = "FETCH_EMPLOYER_JOB_TYPE_SUCCESS";
export const FETCH_EMPLOYER_JOB_TYPE_FAILURE = "FETCH_EMPLOYER_JOB_TYPE_FAILURE";

export const FETCH_EMPLOYER_JOB_CATEGORY_START = "FETCH_EMPLOYER_JOB_CATEGORY_START";
export const FETCH_EMPLOYER_JOB_CATEGORY_SUCCESS = "FETCH_EMPLOYER_JOB_CATEGORY_SUCCESS";
export const FETCH_EMPLOYER_JOB_CATEGORY_FAILURE = "FETCH_EMPLOYER_JOB_CATEGORY_FAILURE";

export const FETCH_EMPLOYER_SERVICE_LOCATION_START = "FETCH_EMPLOYER_SERVICE_LOCATION_START";
export const FETCH_EMPLOYER_SERVICE_LOCATION_SUCCESS = "FETCH_EMPLOYER_SERVICE_LOCATION_SUCCESS";
export const FETCH_EMPLOYER_SERVICE_LOCATION_FAILURE = "FETCH_EMPLOYER_SERVICE_LOCATION_FAILURE";

export const FETCH_JOB_APPLIED_LIST_START = "FETCH_JOB_APPLIED_LIST_START";
export const FETCH_JOB_APPLIED_LIST_SUCCESS = "FETCH_JOB_APPLIED_LIST_SUCCESS";
export const FETCH_JOB_APPLIED_LIST_FAILURE = "FETCH_JOB_APPLIED_LIST_FAILURE";

export const FETCH_SALARY_FILTER_START = "FETCH_SALARY_FILTER_START";
export const FETCH_SALARY_FILTER_SUCCESS = "FETCH_SALARY_FILTER_SUCCESS";
export const FETCH_SALARY_FILTER_FAILURE = "FETCH_SALARY_FILTER_FAILURE";

export const FETCH_EMPLOYER_CITY_START = "FETCH_EMPLOYER_CITY_START";
export const FETCH_EMPLOYER_CITY_SUCCESS = "FETCH_EMPLOYER_CITY_SUCCESS";
export const FETCH_EMPLOYER_CITY_FAILURE = "FETCH_EMPLOYER_CITY_FAILURE";

export const FETCH_EMPLOYER_REGION_START = "FETCH_EMPLOYER_REGION_START";
export const FETCH_EMPLOYER_REGION_SUCCESS = "FETCH_EMPLOYER_REGION_SUCCESS";
export const FETCH_EMPLOYER_REGION_FAILURE = "FETCH_EMPLOYER_REGION_FAILURE";

export const FETCH_JOB_CITY_START = "FETCH_JOB_CITY_START";
export const FETCH_JOB_CITY_SUCCESS = "FETCH_JOB_CITY_SUCCESS";
export const FETCH_JOB_CITY_FAILURE = "FETCH_JOB_CITY_FAILURE";

export const FETCH_JOB_REGION_START = "FETCH_JOB_REGION_START";
export const FETCH_JOB_REGION_SUCCESS = "FETCH_JOB_REGION_SUCCESS";
export const FETCH_JOB_REGION_FAILURE = "FETCH_JOB_REGION_FAILURE";

export const USER_JOB_ALERT_SAVE_START= "USER_JOB_ALERT_SAVE_START";
export const USER_JOB_ALERT_SAVE_SUCCESS = "USER_JOB_ALERT_SAVE_SUCCESS";
export const USER_JOB_ALERT_SAVE_FAILURE = "USER_JOB_ALERT_SAVE_FAILURE";


export const FETCH_COMPANY_SUBSCRIPTION_START= "FETCH_COMPANY_SUBSCRIPTION_START";
export const FETCH_COMPANY_SUBSCRIPTION_SUCCESS= "FETCH_COMPANY_SUBSCRIPTION_SUCCESS";
export const FETCH_COMPANY_SUBSCRIPTION_FAILURE= "FETCH_COMPANY_SUBSCRIPTION_FAILURE";

export const FETCH_MY_COMPANY_SUBSCRIPTION_START= "FETCH_MY_COMPANY_SUBSCRIPTION_START";
export const FETCH_MY_COMPANY_SUBSCRIPTION_SUCCESS= "FETCH_MY_COMPANY_SUBSCRIPTION_SUCCESS";
export const FETCH_MY_COMPANY_SUBSCRIPTION_FAILURE= "FETCH_MY_COMPANY_SUBSCRIPTION_FAILURE";

export const COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_START= "COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_START";
export const COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_SUCCESS= "COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_SUCCESS";
export const COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_FAILURE= "COMPANY_SUBSCRIPTION_PAYMENT_BY_CARD_FAILURE";

export const FETCH_COMPANY_VIEW_START = "FETCH_COMPANY_VIEW_START";
export const FETCH_COMPANY_VIEW_SUCCESS = "FETCH_COMPANY_VIEW_SUCCESS";
export const FETCH_COMPANY_VIEW_FAILURE = "FETCH_COMPANY_VIEW_FAILURE";

export const FETCH_SUBSCRIBED_COMPANY_START = "FETCH_SUBSCRIBED_COMPANY_START";
export const FETCH_SUBSCRIBED_COMPANY_SUCCESS = "FETCH_SUBSCRIBED_COMPANY_SUCCESS";
export const FETCH_SUBSCRIBED_COMPANY_FAILURE = "FETCH_SUBSCRIBED_COMPANY_FAILURE";
