import {
    FETCH_EMPLOYER_DASHBOARD_START,
    FETCH_EMPLOYER_DASHBOARD_SUCCESS,
    FETCH_EMPLOYER_DASHBOARD_FAILURE,
} from "./ActionConstant";
  
export function fetchEmployerDashboardStart(data) {
    return {
        type: FETCH_EMPLOYER_DASHBOARD_START,
        data,
    };
}
  
export function fetchEmployerDashboardSuccess(data) {
    return {
        type: FETCH_EMPLOYER_DASHBOARD_SUCCESS,
        data,
    };
}
  
export function fetchEmployerDashboardFailure(error) {
    return {
        type: FETCH_EMPLOYER_DASHBOARD_FAILURE,
        error,
    };
}
  