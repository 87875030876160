import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    Image,
    Media,
    Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import { fetchMyCompanySubscriptionStart } from "../../store/actions/CompanySubscriptionAction";
import useInfiniteScroll from "../helper/useInfiniteScroll";

const SubscriptionHistory = (props) => {

    useEffect(() => {
        props.dispatch(fetchMyCompanySubscriptionStart());
    }, []);

    const fetchSubscriptionData = () => {
        setTimeout(() => {
            if (props.history.length !== 0) {
                props.dispatch(fetchMyCompanySubscriptionStart({
                    skip:props.history.skip,
                }));
                setIsFetching(false);
            } else {
                setNoMoreData(true);
            }
        }, 3000);
    };

    const [isFetching, setIsFetching] = useInfiniteScroll(fetchSubscriptionData);

    const [noMoreData, setNoMoreData] = useState(false);

    return (
        <>
            <div className="home-sec">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="home-banner-card">
                                <div className="home-banner-info">
                                    <h3>Subscription History</h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="margin-top-lg">
                        <Col md={12}>
                        {props.history.loading ? (
                            "Loading"
                        ) : props.history.data.subscriptions.length > 0 ? (
                            <>
                                {props.history.data.subscriptions.map((subscription,index) => (

                                    <div className="select-plan-sec-single mb-3" key={subscription.unique_id}>
                                        <div className="history-plan-box">
                                            <div className="select-plan-card">
                                                <div className="select-plan-header">
                                                    <div className="select-plan-info">
                                                        <h4 className="name">{subscription.company_subscription.name}</h4>
                                                        <h6 className="price">{subscription.amount_formatted}</h6>
                                                        <ul className="list-unstyled select-plan-action">
                                                           
                                                            <Media as="li" className="day-count">
                                                                {subscription.plan_formatted}
                                                            </Media>
                                                        </ul>
                                                    </div>
                                                    {index == 0 ?
                                                        <div className="select-plan-btn-action">
                                                            <Link to={"/company-subscriptions"} className="default-btn-outline">
                                                                Change Plan
                                                            </Link>
                                                        </div>
                                                    : ''}
                                                </div>
                                                <div className="select-plan-body">
                                                    <div className="description">                                                        
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: subscription.company_subscription.description != undefined ? subscription.company_subscription.description : "",
                                                            }}
                                                        ></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                ))}
                            </>
                        ) : null}
                        </Col>
                    </Row>
                    {noMoreData !== true ? (
                        <>{isFetching && "Fetching more items..."}</>
                    ) : (
                        "No More Data"
                    )}
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    history: state.companySubscription.mySubscription,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(mapStateToPros, mapDispatchToProps)(SubscriptionHistory);
  