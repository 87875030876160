import {
    EMPLOYER_LOGIN_START,
    EMPLOYER_LOGIN_SUCCESS,
    EMPLOYER_LOGIN_FAILURE,
    EMPLOYER_FORGOT_PASSWORD_START,
    EMPLOYER_FORGOT_PASSWORD_SUCCESS,
    EMPLOYER_FORGOT_PASSWORD_FAILURE,
    EMPLOYER_DELETE_ACCOUNT_START,
    EMPLOYER_DELETE_ACCOUNT_SUCCESS,
    EMPLOYER_DELETE_ACCOUNT_FAILURE,
    EMPLOYER_CHANGE_PASSWORD_START,
    EMPLOYER_CHANGE_PASSWORD_SUCCESS,
    EMPLOYER_CHANGE_PASSWORD_FAILURE,
    EMPLOYER_RESET_PASSWORD_START,
    EMPLOYER_RESET_PASSWORD_SUCCESS,
    EMPLOYER_RESET_PASSWORD_FAILURE,
    EMPLOYER_REGISTER_START,
    EMPLOYER_REGISTER_SUCCESS,
    EMPLOYER_REGISTER_FAILURE,
    FETCH_EMPLOYER_DETAILS_START,
    FETCH_EMPLOYER_DETAILS_SUCCESS,
    FETCH_EMPLOYER_DETAILS_FAILURE,
    UPDATE_EMPLOYER_DETAILS_START,
    UPDATE_EMPLOYER_DETAILS_SUCCESS,
    UPDATE_EMPLOYER_DETAILS_FAILURE,
  } from "./ActionConstant";
  
  // employer login actions.
  export function employerLoginStart(data) {
    return {
      type: EMPLOYER_LOGIN_START,
      data,
    };
  }
  
  export function employerLoginSuccess(data) {
    return {
      type: EMPLOYER_LOGIN_SUCCESS,
      data,
    };
  }
  
  export function employerLoginFailure(error) {
    return {
      type: EMPLOYER_LOGIN_FAILURE,
      error,
    };
  }
  
  // employer Register actions.
  export function employerRegisterStart(data) {
    return {
      type: EMPLOYER_REGISTER_START,
      data,
    };
  }
  
  export function employerRegisterSuccess(data) {
    return {
      type: EMPLOYER_REGISTER_SUCCESS,
      data,
    };
  }
  
  export function employerRegisterFailure(error) {
    return {
      type: EMPLOYER_REGISTER_FAILURE,
      error,
    };
  }
  
  // employer FORGOT_PASSWORD actions.
  export function employerForgotPasswordStart(data) {
    return {
      type: EMPLOYER_FORGOT_PASSWORD_START,
      data,
    };
  }
  
  export function employerForgotPasswordSuccess(data) {
    return {
      type: EMPLOYER_FORGOT_PASSWORD_SUCCESS,
      data,
    };
  }
  
  export function employerForgotPasswordFailure(error) {
    return {
      type: EMPLOYER_FORGOT_PASSWORD_FAILURE,
      error,
    };
  }
  
  // employer Delete Account actions.
  export function employerDeleteAccountStart(data) {
    return {
      type: EMPLOYER_DELETE_ACCOUNT_START,
      data,
    };
  }
  
  export function employerDeleteAccountSuccess(data) {
    return {
      type: EMPLOYER_DELETE_ACCOUNT_SUCCESS,
      data,
    };
  }
  
  export function employerDeleteAccountFailure(error) {
    return {
      type: EMPLOYER_DELETE_ACCOUNT_FAILURE,
      error,
    };
  }
  
  // Change password actions.
  export function employerChangePasswordStart(data) {
    return {
      type: EMPLOYER_CHANGE_PASSWORD_START,
      data,
    };
  }
  
  export function employerChangePasswordSuccess(data) {
    return {
      type: EMPLOYER_CHANGE_PASSWORD_SUCCESS,
      data,
    };
  }
  
  export function employerChangePasswordFailure(error) {
    return {
      type: EMPLOYER_CHANGE_PASSWORD_FAILURE,
      error,
    };
  }
  
  // Get employer details actions.
  export function fetchEmployerDetailsStart(data) {
    return {
      type: FETCH_EMPLOYER_DETAILS_START,
      data,
    };
  }
  
  export function fetchEmployerDetailsSuccess(data) {
    return {
      type: FETCH_EMPLOYER_DETAILS_SUCCESS,
      data,
    };
  }
  
  export function fetchEmployerDetailsFailure(error) {
    return {
      type: FETCH_EMPLOYER_DETAILS_FAILURE,
      error,
    };
  }
  
  export function updateEmployerDetailsStart(data) {
    return {
      type: UPDATE_EMPLOYER_DETAILS_START,
      data,
    };
  }
  
  export function updateEmployerDetailsSuccess(data) {
    return {
      type: UPDATE_EMPLOYER_DETAILS_SUCCESS,
      data,
    };
  }
  
  export function updateEmployerDetailsFailure(error) {
    return {
      type: UPDATE_EMPLOYER_DETAILS_FAILURE,
      error,
    };
  }
  
  export function employerResetPasswordStart(data) {
    return {
      type: EMPLOYER_RESET_PASSWORD_START,
      data,
    };
  }
  
  export function employerResetPasswordSuccess(data) {
    return {
      type: EMPLOYER_RESET_PASSWORD_SUCCESS,
      data,
    };
  }
  
  export function employerResetPasswordFailure(error) {
    return {
      type: EMPLOYER_RESET_PASSWORD_FAILURE,
      error,
    };
  }
  