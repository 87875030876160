import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";

const Step1 = (props) => {

  return (
    <>
      <div className="select-plan-sec">
        {props.employer.account_type == 1 ? 
          <>
          <div className="select-plan-box-1">
            <div className="select-plan-card">
              <div className="select-plan-header">
                <div className="select-plan-info">
                    <h6 className="name">Thanks for  Subscription.Enjoy posting unlimited Jobs.</h6>
                </div>
                <div className="select-plan-btn-action">
                  <Link to="/subscriptions" className="default-btn-outline">
                      Change Plan
                  </Link>
                </div>
              </div>
            </div>
          </div>
          </>
        : 
          <>
          <p className="separator">Select a plan & Subscribe</p>
          <div className="select-plan-box-1">
            <div className="select-plan-card">
              <div className="select-plan-header">
                <div className="select-plan-info">
                    <h6 className="name">You are creating your first job. Subscribe and Enjoy posting unlimited Jobs.</h6>
                </div>
                <div className="select-plan-btn-action">
                  <Link to="/subscriptions" className="default-btn-outline">
                      Select Plan
                  </Link>
                </div>
              </div>
            </div>
          </div>
          </>
        }
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Step1));
